import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Tabs, ThemeAdmin, Loader, Message, Button } from 'oxyrion-ui/lib';
import { __, getIdFromProps, normalizeCodelist, translate } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import BaseInfo from './baseInfo';
import Documentation from './documentation';
import RelatedProducts from './relatedProducts';
import Toning from './toning';
import Publication from './publication';
import Variants from './variants';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
  StyledInput,
  InputWrapper,
  StyledLabel,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import API from '../../API';
import ConfirmDialog from '../../Components/ConfirmDialog';
import PopUpWrapper from '../../Components/PopUpWrapper';
import Sales from './Sales';
import FinalSale from './FinalSales';
import API2 from '../../API2';
import SearchableSelect from '../../Components/SearchableSelect';
import Reviews from './reviews';
import ProductDescription from './productDescription';

const ContentWrapper = styled.div`
  padding: ${rem(20)};
  margin-bottom: ${rem(100)};
`;

const HrefButton = styled.a`
  color: #ee6501;
  text-decoration: none;
  border: solid 1px #ee6501;
  height: 28px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
`;

const PopUpInsideWrapper = styled.div`
  padding: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

const ButtonWrapper = styled.div`
  padding-top: ${rem(12)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

const fileBasePath = `${process.env.REACT_APP_DOMAIN}/public/file`;
const REVIEWS_LANGS = ['SK', 'CZ', 'AT'];

class ProductCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'tab1',
      loading: false,
      categoriesLoading: false,
      showConfirmDialog: false,
      showReviewDialog: false,
      showReviewWarning: false,
      reviewGoogleUrl: '',
      reviewGoogleLimit: 10,
      reviewTranslations: REVIEWS_LANGS.map((lang) => ({
        label: lang,
        value: lang,
      })),
      reviewsLoading: false,
      variantActiveLanguage: 'SK',
      publicationActiveLanguage: 'SK',
      baseInfoActiveLanguage: 'SK',
      baseInfoActiveLanguageShortDescription: 'SK',
      activeLanguageMetaTitle: 'SK',
      activeLanguageMetaDescription: 'SK',
      selectedCanal: 'CRM',
      typesForTinting: [
        'FARBA',
        'SPREJ',
        'LAK',
        'glazura',
        'omietka',
        'moridlo',
        'olej',
        'vosk',
        'lazúra',
        'fasada',
        'tmel',
        'lak',
        'opravné_pero',
      ],
      baseInfo: {
        id: '',
        name: {
          error: false,
          value: '',
        },
        url: {
          error: false,
          value: '',
        },
        url_by_lang: {},
        url_history: {},
        type: 'FARBA',
        brand: '',
        subbrand: '',
        productTypes: [],
        brands: [],
        subBrandsCodelist: [],
        multimedia_images: [],
        description: '',
        extended_description: null,
        translations: [
          {
            lang: 'SK',
            description: '',
          },
          {
            lang: 'CZ',
            description: '',
          },
          {
            lang: 'AT',
            description: '',
          },
          {
            lang: 'EN',
            description: '',
          },
        ],
        shortDescription: '',
        shortDescriptionTranslations: [
          {
            lang: 'SK',
            description: '',
          },
          {
            lang: 'CZ',
            description: '',
          },
          {
            lang: 'AT',
            description: '',
          },
          {
            lang: 'EN',
            description: '',
          },
        ],
        colorcards: [],
      },
      notGrouped: [],
      groups: [],
      files: [],
      selectedCategories: [
        {
          chanel: 'CRM',
          values: [
            {
              lang: 'SK',
              selected: [],
            },
            {
              lang: 'CZ',
              selected: [],
            },
            {
              lang: 'EN',
              selected: [],
            },
            {
              lang: 'AT',
              selected: [],
            },
          ],
        },
        {
          chanel: 'B2B',
          values: [
            {
              lang: 'SK',
              selected: [],
            },
            {
              lang: 'CZ',
              selected: [],
            },
            {
              lang: 'EN',
              selected: [],
            },
            {
              lang: 'AT',
              selected: [],
            },
          ],
        },
        {
          chanel: 'xFarby',
          values: [
            {
              lang: 'SK',
              selected: [],
            },
            {
              lang: 'CZ',
              selected: [],
            },
            {
              lang: 'EN',
              selected: [],
            },
            {
              lang: 'AT',
              selected: [],
            },
          ],
        },
        {
          chanel: 'xBarvy',
          values: [
            {
              lang: 'SK',
              selected: [],
            },
            {
              lang: 'CZ',
              selected: [],
            },
            {
              lang: 'EN',
              selected: [],
            },
            {
              lang: 'AT',
              selected: [],
            },
          ],
        },
        {
          chanel: 'xFarben',
          values: [
            {
              lang: 'SK',
              selected: [],
            },
            {
              lang: 'CZ',
              selected: [],
            },
            {
              lang: 'EN',
              selected: [],
            },
            {
              lang: 'AT',
              selected: [],
            },
          ],
        },
        {
          chanel: 'xFestek',
          values: [
            {
              lang: 'SK',
              selected: [],
            },
            {
              lang: 'CZ',
              selected: [],
            },
            {
              lang: 'EN',
              selected: [],
            },
            {
              lang: 'AT',
              selected: [],
            },
          ],
        },
      ],

      publications: [],
      variants: [],
      variantDetail: 0,
      photos: [],
      variantParams: [],
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      await this.fetchData(id);
      await this.fetchCategories(
        this.state.publicationActiveLanguage,
        this.state.publicationChanel,
      );
    } else {
      await this.fetchParams('FARBA');
    }
    await this.fetchCodelists();
  }

  async fetchCodelists() {
    try {
      await Promise.all([
        AdminAPI.getProductTypesAction(),
        API.getBrandsAction(this.props.firm),
        API.getSubBrandsAction(this.props.firm),
        AdminAPI.getTintingTypesAction(),
      ]).then(
        async ([
          productTypes,
          productBrand,
          productSubBrand,
          typesForTinting,
        ]) => {
          const normalizedTypes = this.normalizeProductTypes(productTypes);
          const normalizedBrands = normalizeCodelist(
            productBrand.fullBrands.map((p) => ({
              value: p,
              label: p.brand,
            })),
            this.props.firm,
          );
          const normalizedSubBrands = normalizeCodelist(
            productSubBrand.brands.map((p) => ({
              value: p,
              label: p,
            })),
            this.props.firm,
          );

          this.setState({
            productTypes: normalizedTypes,
            brands: normalizedBrands,
            subBrandsCodelist: normalizedSubBrands,
            typesForTinting: typesForTinting.types || [],
          });
        },
      );
    } catch (e) {
      this.setState({
        error: __('Pri načítaní dát nastala chyba'),
      });
    }
  }

  normalizeProductTypes(productTypes) {
    const result = productTypes.product_types.map((type, index) => {
      return {
        key: index,
        value: type._id,
        display_name: translate(
          this.props.firm,
          type.translations,
          type.name || type._id,
        ),
      };
    });

    return result;
  }

  async fetchParams(typeId) {
    this.setState({
      loading: true,
    });
    try {
      const params = await AdminAPI.getProductTypeParamsAction(typeId);

      const variantParams =
        await AdminAPI.getProductTypeVariantParamsAction(typeId);

      const normalizedParams = {
        notGrouped: params.filter((p) => !p.group),
        groups: params.filter((p) => p.group),
      };

      this.setState({
        groups: normalizedParams.groups,
        notGrouped: normalizedParams.notGrouped,
        variantParams,
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
      console.log(e);
    }
  }

  async fetchCategories(lang, chanelId = 'CRM') {
    this.setState({
      categoriesLoading: true,
    });

    try {
      const result = await AdminAPI.getCategoriesTreeAction({
        chanelId,
        lang,
        // chanelId: productCanals,
      });

      this.setState({
        publications: result.categories,
      });
    } catch (e) {
      this.setState({
        error: __('Nepodarilo sa načítať kategórie'),
      });
    } finally {
      this.setState({
        categoriesLoading: false,
      });
    }
  }

  async fetchData(id) {
    try {
      await Promise.all([
        AdminAPI.getProductAction(id),
        AdminAPI.getProductDocumentationAction(id),
        AdminAPI.getProductImagesAction(id),
        AdminAPI.getCatalogProductSalesAction(id),
      ]).then(async ([baseInfo, files, photos, salesResult]) => {
        this.normalizeData(
          baseInfo,
          files.documentation,
          photos.images,
          salesResult.variants,
          salesResult.variants_clearances,
        );
      });
    } catch (e) {
      console.log(e);

      this.setState({
        error: __('Nepodarilo sa načítať produkt'),
      });
    }
  }

  handleNoTintingValueChange(data) {
    const { noTintinChoosesParam, noTintigParam } = this.state;

    if (data.fieldIndex === 0) {
      noTintigParam.value = data.value;
      noTintinChoosesParam.disabled = !data.value;
      if (data.value) {
        noTintinChoosesParam.value = [];
      }
    }

    if (data.fieldIndex === 1) {
      noTintinChoosesParam.value = data.value;
    }

    this.setState({
      noTintigParam,
      noTintinChoosesParam,
    });
  }

  handleCoeficientChange(value, no) {
    const { variants } = this.state;

    variants
      .find((v) => v.No === no)
      .variantParams.find((vp) => vp.system_name === 'coeficient').value =
      value;

    this.setState({
      variants,
    });
  }

  handleFileDateChange(value, index) {
    const { files, baseInfo } = this.state;
    files[index].file_updated_date = value;
    this.setState(
      {
        files,
      },
      async () => {
        try {
          const params = {};
          params.body = files[index];
          await AdminAPI.putProductDocumentationAction(
            baseInfo.id,
            files[index]._id,
            params,
          );
        } catch (e) {
          console.log(e);
        }
      },
    );
  }

  async normalizeData(data, files, photos, sales, clearances) {
    const { baseInfo } = this.state;
    baseInfo.id = data._id;
    baseInfo.name.value = data.name;
    baseInfo.nameTranslations = data.nameTranslations;
    // baseInfo.url = data.url;
    baseInfo.url_by_lang = data.url_by_lang;
    baseInfo.url_history = data.url_history;
    baseInfo.brand = data.brand;
    baseInfo.show_all_bases = data.show_all_bases;
    baseInfo.subbrand = data.subbrand;
    baseInfo.type = data.type;
    baseInfo.colorcards = data.colorcards;
    baseInfo.translations = data.translations;
    baseInfo.titleImage = data.multimedium_title_image_id || '';
    baseInfo.altImage = data.alt_image_full_path || '';
    baseInfo.altText = data.alt_image_text || '';
    baseInfo.altTextTranslations = data.alt_image_text_translations || [];
    baseInfo.metaTitleTranslations = data.metaTitleTranslations;
    baseInfo.metaDescriptionTranslations = data.metaDescriptionTranslations;
    baseInfo.external_product = data.external_product;
    baseInfo.exportable = data.exportable;
    baseInfo.obnova_exportable = data.obnova_exportable;
    baseInfo.exportable_for_seko = data.exportable_for_seko;
    baseInfo.securityAlerts =
      typeof data.security_alerts === 'object' &&
      !Array.isArray(data.security_alerts)
        ? data.security_alerts
        : {};
    baseInfo.toning_system_id = data.toning_system && data.toning_system[0];
    baseInfo.shortDescription = data.shortDescription;
    baseInfo.key_variant_param = data.key_variant_param;
    baseInfo.sort_variant_param = data.sort_variant_param;
    baseInfo.multimedia_images = data.multimedia_images;
    baseInfo.favoritColorcards = data.favorit_colorcards;
    baseInfo.farleskExportColorcards = data.farlesk_export_colorcards;
    baseInfo.relatedProducts = data.related_products;
    baseInfo.colorcardsTexture = data.colorcards_texture;
    baseInfo.delivery_terms = data.delivery_terms;
    baseInfo.extended_description = data.extended_description;

    let basesSettings = [];

    const bases = data.variants
      .map((v) => {
        const param = v.variantParams.find((vp) => vp.system_name === 'base');

        if (param && param.values && param.codelist) {
          const codelistValue = param.values.codelist.find(
            (c) => c._id === param.value,
          );

          if (codelistValue && codelistValue.value) {
            return codelistValue.value;
          }
        }
        return null;
      })
      .filter((b, index, self) => b && self.indexOf(b) === index);

    if (data.bases_settings) {
      basesSettings = data.bases_settings;
    }

    bases.forEach((b) => {
      if (!basesSettings.find((bs) => bs.name === b)) {
        basesSettings.push({
          name: b,
          color: null,
          rgb: null,
          is_default: false,
        });
      }
    });

    if (data.shortDescriptionTranslations) {
      baseInfo.shortDescriptionTranslations = data.shortDescriptionTranslations;
    }

    baseInfo.bases_settings = basesSettings;

    const { selectedCategories } = data || [];

    if (baseInfo.toning_system_id) {
      const externalProductsForToningSystem =
        await API.getExternalProductsAction(this.props.firm, {
          systemId: baseInfo.toning_system_id,
          productsIds: data.external_products,
        });

      baseInfo.external_products = externalProductsForToningSystem.products.map(
        (p) => ({
          value: p.external_product_id,
          label: p.name,
        }),
      );
    }

    const notGrouped = data.params.filter(
      (p) => !p.group || p.group.length === 0,
    );
    const groups = data.params.filter((p) => p.group && p.group.length > 0);
    const groupedGroups = this.groupByGroup(groups);

    const variantParams = await AdminAPI.getProductTypeVariantParamsAction(
      baseInfo.type,
    );

    const notGrouped1 = Array.isArray(notGrouped) ? notGrouped : [notGrouped];

    if (
      notGrouped1.find((np) => np.system_name === 'no_tinting') &&
      notGrouped1.find((np) => np.system_name === 'no_tinting').value !== true
    ) {
      notGrouped1.find(
        (np) => np.system_name === 'no_tinting_chooses',
      ).disabled = true;

      notGrouped1.find((np) => np.system_name === 'no_tinting_chooses').value =
        '';
    }

    const noTintigParam = notGrouped1.find(
      (np) => np.system_name === 'no_tinting',
    );
    const noTintinChoosesParam = notGrouped1.find(
      (np) => np.system_name === 'no_tinting_chooses',
    );

    this.setState({
      baseInfo,
      variants: data.variants,
      files: files || [],
      photos: photos || [],
      selectedCategories,
      loading: false,
      notGrouped: notGrouped1.filter(
        (n) =>
          ['no_tinting', 'no_tinting_chooses'].indexOf(n.system_name) === -1,
      ),
      groups: groupedGroups,
      variantParams,
      noTintigParam,
      noTintinChoosesParam,
      sales,
      clearances,
    });
  }

  groupByGroup(params) {
    const result = [];
    params.forEach((param) => {
      if (result.find((p) => p.group === param.group)) {
        result.find((p) => p.group === param.group).params.push(param);
      } else {
        result.push({
          group: param.group,
          params: [param],
        });
      }
    });
    return result;
  }

  handleChange(value, groupIndex, fieldIndex, rowIndex, columnIndex) {
    const { groups, notGrouped } = this.state;

    if (rowIndex >= 0 && columnIndex >= 0) {
      if (groupIndex === -1) {
        notGrouped[fieldIndex].value[rowIndex].columns[columnIndex].value =
          value;
      } else {
        groups[groupIndex].params[fieldIndex].value[rowIndex].columns[
          columnIndex
        ].value = value;
      }
    } else if (groupIndex === -1) {
      if (
        value instanceof Object &&
        notGrouped[fieldIndex].type === 'codelist'
      ) {
        notGrouped[fieldIndex].value = value.value;
        notGrouped[fieldIndex].label = value.codelist.find(
          (c) => c._id === value.value,
        ).label;
      } else {
        notGrouped[fieldIndex].value = value;
      }

      if (
        notGrouped[fieldIndex].system_name &&
        notGrouped[fieldIndex].system_name === 'no_tinting'
      ) {
        if (notGrouped.find((n) => n.system_name === 'no_tinting_chooses')) {
          if (notGrouped[fieldIndex].value) {
            notGrouped.find(
              (n) => n.system_name === 'no_tinting_chooses',
            ).disabled = false;
          } else {
            notGrouped.find(
              (n) => n.system_name === 'no_tinting_chooses',
            ).disabled = true;
            notGrouped.find(
              (n) => n.system_name === 'no_tinting_chooses',
            ).value = '';
          }
        }
      }
    } else if (
      value instanceof Object &&
      groups[groupIndex].params[fieldIndex].type === 'codelist'
    ) {
      groups[groupIndex].params[fieldIndex].value = value.value;
      groups[groupIndex].params[fieldIndex].label = value.codelist.find(
        (c) => c._id === value.value,
      ).label;
    } else {
      groups[groupIndex].params[fieldIndex].value = value;
    }

    this.setState({
      groups,
      notGrouped,
    });
  }

  handleUnitChange(groupIndex, fieldIndex, rowIndex, columnIndex, value) {
    const { groups, notGrouped } = this.state;
    if (groupIndex === -1) {
      notGrouped[fieldIndex].selectedUnit = value;
    } else {
      groups[groupIndex].params[fieldIndex].selectedUnit = value;
    }

    this.setState({
      groups,
      notGrouped,
    });
  }

  changeVariantAltText(text, lang) {
    const { variants, variantDetail } = this.state;

    if (lang === 'SK') {
      variants[variantDetail].altText = text;
    }

    if (!variants[variantDetail].altTextTranslations) {
      variants[variantDetail].altTextTranslations = [];
    }

    if (
      !variants[variantDetail].altTextTranslations.find((b) => b.lang === lang)
    ) {
      variants[variantDetail].altTextTranslations.push({
        lang,
        value: text,
      });
    } else {
      variants[variantDetail].altTextTranslations.find(
        (b) => b.lang === lang,
      ).value = text;
    }

    this.setState({
      variants,
    });
  }

  handleVariantUnitChange(
    groupIndex,
    fieldIndex,
    rowIndex,
    columnIndex,
    value,
  ) {
    const { variants, variantDetail } = this.state;
    variants[variantDetail].variantParams[fieldIndex].selectedUnit = value;
    this.setState({
      variants,
    });
  }

  handleVariantParamsChange(
    value,
    groupIndex,
    fieldIndex,
    rowIndex,
    columnIndex,
    variantIndex,
  ) {
    const { variants } = this.state;
    if (rowIndex >= 0 && columnIndex >= 0) {
      if (groupIndex === -1) {
        variants[variantIndex].variantParams[fieldIndex].value[
          rowIndex
        ].columns[columnIndex].value = value;
      }
    } else if (groupIndex === -1) {
      if (value instanceof Object) {
        variants[variantIndex].variantParams[fieldIndex].value = value.value;
        if (value.codelist) {
          variants[variantIndex].variantParams[fieldIndex].label =
            value.codelist.find((c) => c._id === value.value) &&
            value.codelist.find((c) => c._id === value.value).label;
        }
      } else {
        variants[variantIndex].variantParams[fieldIndex].value = value;
      }
    }

    this.setState({
      variants,
    });
  }

  async handleBaseInfoChange(e, field, descriptionLanguage) {
    const { baseInfo } = this.state;
    if (field === 'name') {
      if (!baseInfo.nameTranslations) {
        baseInfo.nameTranslations = [
          {
            lang: 'SK',
            name: '',
          },
          {
            lang: 'CZ',
            name: '',
          },
          {
            lang: 'AT',
            name: '',
          },
          {
            lang: 'EN',
            name: '',
          },
        ];
      }

      if (
        baseInfo.nameTranslations.find((t) => t.lang === descriptionLanguage)
      ) {
        baseInfo.nameTranslations.find(
          (t) => t.lang === descriptionLanguage,
        ).name = e.target.value;
      } else {
        baseInfo.nameTranslations.push({
          lang: descriptionLanguage,
          name: e.target.value,
        });
      }

      if (descriptionLanguage === 'SK') {
        baseInfo.name.value = e.target.value;
        baseInfo.name.error = false;
      }
    } else if (field === 'url_by_lang') {
      baseInfo.url_by_lang[descriptionLanguage] = e.target.value;
    } else if (field === 'url_history') {
      baseInfo.url_history[descriptionLanguage].find(
        (f) => f.url === e.target.id,
      ).active = e.target.checked;
    } else if (field === 'exportable') {
      baseInfo.exportable = e;
    } else if (field === 'obnova_exportable') {
      baseInfo.obnova_exportable = e;
    } else if (field === 'exportable_for_seko') {
      baseInfo.exportable_for_seko = e;
    } else if (field === 'type') {
      baseInfo.type = e.target.value;
      await this.fetchParams(e.target.value);
    } else if (field === 'brand') {
      baseInfo.brand = e.target.value;
    } else if (field === 'external_product') {
      baseInfo.external_products = e;
    } else if (field === 'subBrand') {
      baseInfo.subbrand = e.target.value;
    } else if (field === 'description') {
      baseInfo.translations.find(
        (t) => t.lang === descriptionLanguage,
      ).description = e.target.value;
    } else if (field === 'shortDescription') {
      baseInfo.shortDescriptionTranslations.find(
        (t) => t.lang === descriptionLanguage,
      ).description = e.target.value;
    } else if (field === 'key_variant_param') {
      baseInfo.key_variant_param = e.target.value;
    } else if (field === 'sort_variant_param') {
      baseInfo.sort_variant_param = e.target.value;
    } else if (field === 'metatitle') {
      if (!baseInfo.metaTitleTranslations) {
        baseInfo.metaTitleTranslations = [
          {
            lang: 'SK',
            metaTitle: '',
          },
          {
            lang: 'CZ',
            metaTitle: '',
          },
          {
            lang: 'AT',
            metaTitle: '',
          },
          {
            lang: 'EN',
            metaTitle: '',
          },
        ];
      }

      if (
        !baseInfo.metaTitleTranslations.find(
          (t) => t.lang === descriptionLanguage,
        )
      ) {
        baseInfo.metaTitleTranslations.push({
          lang: descriptionLanguage,
          metaTitle: '',
        });
      }

      baseInfo.metaTitleTranslations.find(
        (t) => t.lang === descriptionLanguage,
      ).metaTitle = e.target.value;
    } else if (field === 'metaDescription') {
      if (!baseInfo.metaDescriptionTranslations) {
        baseInfo.metaDescriptionTranslations = [
          {
            lang: 'SK',
            metaDescription: '',
          },
          {
            lang: 'CZ',
            metaDescription: '',
          },
          {
            lang: 'AT',
            metaDescription: '',
          },
          {
            lang: 'EN',
            metaDescription: '',
          },
        ];
      }

      if (
        !baseInfo.metaDescriptionTranslations.find(
          (t) => t.lang === descriptionLanguage,
        )
      ) {
        baseInfo.metaDescriptionTranslations.push({
          lang: descriptionLanguage,
          metaDescription: '',
        });
      }

      baseInfo.metaDescriptionTranslations.find(
        (t) => t.lang === descriptionLanguage,
      ).metaDescription = e.target.value;
    }

    const groupLog = [
      'name',
      'shortDescription',
      'description',
      'metatitle',
      'metaDescription',
    ];

    if (groupLog.includes(field) && getIdFromProps(this.props).length === 24) {
      API.putEntintyTranslationsChangesLogAction('catalog-product', field, {
        data: {
          entity_item_id: getIdFromProps(this.props),
          changes_log: [
            {
              lang: descriptionLanguage,
              changed: true,
            },
          ],
        },
      });
    }

    this.setState({
      baseInfo,
      error: false,
    });
  }

  async addNewFile(file) {
    const { files, baseInfo } = this.state;

    try {
      const params = {};
      params.body = Object.assign(file, {
        file_updated_date: file.file.file_updated_date,
      });
      const newfile = await AdminAPI.postProductDocumentationAction(
        baseInfo.id,
        params,
      );
      files.push(newfile);
      this.setState({ files, success: __('Subor úspešne pridaný') });
    } catch (e) {
      console.log(e);
    }
  }

  async saveFile(file, id) {
    const { baseInfo } = this.state;
    this.setState({
      docSaveLoading: true,
    });

    try {
      const params = {};
      params.body = Object.assign(file, {
        file_updated_date: file.file.file_updated_date,
      });
      await AdminAPI.putProductDocumentationAction(baseInfo.id, id, params);
      const documentation = await AdminAPI.getProductDocumentationAction(
        baseInfo.id,
      );
      this.setState({
        success: __('Subor úspešne zmený'),
        files: documentation.documentation || [],
        docSaveLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Súbor sa nepodarilo upraviť'),
        docSaveLoading: false,
      });
    }
  }

  async addPhoto(photo) {
    const { photos, baseInfo } = this.state;

    try {
      const params = {};
      params.body = photo;
      const newfile = await AdminAPI.postProductImageAction(
        baseInfo.id,
        params,
      );
      photos.push(newfile);
      this.setState({ photos, success: __('Obrázok bol úspešne pridaný') });
    } catch (e) {
      console.log(e);
    }
  }

  async deletePhoto(fullPath) {
    const { baseInfo, photos } = this.state;
    try {
      const index = photos.indexOf(photos.find((f) => f.fullPath === fullPath));
      await AdminAPI.deleteProductImageAction(
        baseInfo.id,
        encodeURIComponent(fullPath),
      );
      if (index > -1) {
        photos.splice(index, 1);

        this.setState({
          photos,
          success: __('Obrázok bol úspešne odstránený'),
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
  hideMultimedia(id, hide) {
    const { variants } = this.state;
    const newVariants = variants.map((v) => {
      if (v._id === id) {
        return Object.assign(v, { hide_multimedia: hide });
      }
      return v;
    });
    this.setState({ variants: newVariants });
  }
  async addVariantPhoto(variantId, photo) {
    const { variants, baseInfo } = this.state;

    try {
      const params = {};
      params.body = photo;
      const newfile = await AdminAPI.postProductVariantImageAction(
        baseInfo.id,
        variantId,
        params,
      );
      const index = variants.indexOf(variants.find((f) => f._id === variantId));
      if (variants[index].images && variants[index].images.length > -1) {
        variants[index].images.push(newfile);
      } else {
        variants[index].images = [newfile];
      }

      this.setState({
        variants,
        success: __('Obrázok bol úspešne pridaný'),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async deleteVariantPhoto(variantId, fullPath) {
    const { variants, baseInfo } = this.state;
    try {
      const i = variants.indexOf(variants.find((f) => f._id === variantId));
      const index = variants[i].images.indexOf(
        variants[i].images.find((f) => f.fullPath === fullPath),
      );
      await AdminAPI.deleteProductVariantImageAction(
        baseInfo.id,
        variantId,
        encodeURIComponent(fullPath),
      );
      if (index > -1) {
        variants[i].images.splice(index, 1);

        this.setState({
          variants,
          success: __('Obrázok bol úspešne odstránený'),
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  switchChanel(e) {
    let { selectedCanal } = this.state;
    selectedCanal = e;
    this.setState({
      selectedCanal,
    });

    this.changeChanelIdForPublications(e);

    return true;
  }

  handlesAllBasesChange(value) {
    const { baseInfo } = this.state;

    console.log('value---', value);

    baseInfo.show_all_bases = value;

    this.setState({
      baseInfo,
    });
  }

  handleColorcardsTexture(value) {
    const { baseInfo } = this.state;

    if (value) {
      baseInfo.colorcardsTexture = {
        label: value.label,
        value: value.value,
        imageFullPath:
          value.raw.files.find((f) => f.type === 'main') &&
          value.raw.files.find((f) => f.type === 'main').value &&
          value.raw.files.find((f) => f.type === 'main').value.fullPath,
      };
    } else {
      delete baseInfo.colorcardsTexture;
    }

    this.setState({
      baseInfo,
    });
  }

  handleFavoritColorcardsChange(value) {
    const { baseInfo } = this.state;

    baseInfo.favoritColorcards = value;

    this.setState({
      baseInfo,
    });
  }

  handleFarleskExportColorcardsChange(value) {
    const { baseInfo } = this.state;

    baseInfo.farleskExportColorcards = value;

    this.setState({
      baseInfo,
    });
  }

  handleSecurityAlertsChange(value) {
    const { baseInfo } = this.state;

    baseInfo.securityAlerts = value;

    this.setState({
      baseInfo,
    });
  }

  handleBaseSettingsChange(baseName, value, field) {
    const { baseInfo } = this.state;

    if (field === 'value') {
      baseInfo.bases_settings.find((b) => b.name === baseName).color = value;
    } else if (field === 'is_default') {
      if (baseInfo.bases_settings.find((b) => b.name === baseName)) {
        baseInfo.bases_settings.find((b) => b.name === baseName).is_default =
          value.target.checked;
      }
    }

    this.setState({
      baseInfo,
    });
  }

  handleExternalProduct(e, importId) {
    const { baseInfo } = this.state;

    if (!e) {
      baseInfo.external_product = null;
    } else {
      if (!baseInfo.external_product) {
        baseInfo.external_product = {};
      }
      baseInfo.external_product[importId] = e;
    }

    this.setState({
      baseInfo,
    });
  }

  async addRelatedProduct(data) {
    const { baseInfo } = this.state;

    if (!baseInfo.relatedProducts) {
      baseInfo.relatedProducts = [];
    }

    baseInfo.relatedProducts.push(data);

    this.setState({ baseInfo });
  }

  async addVariant(variant, isFirst, variantPrice) {
    const { variants } = this.state;

    if (variants.find((v) => v.No === variant.No)) {
      this.setState({
        error: __('Tento varian sa už nachádza v tabuľke'),
      });

      return;
    }

    variants.push(
      Object.assign(variant, {
        publishing: [
          {
            chanel: 'CRM',
            published: this.checkPublishedInType('CRM'),
          },
          {
            chanel: 'B2B',
            published: this.checkPublishedInType('B2B'),
          },
          {
            chanel: 'xFarby',
            published: this.checkPublishedInType('xFarby'),
          },
          {
            chanel: 'xBarvy',
            published: this.checkPublishedInType('xBarvy'),
          },
          {
            chanel: 'xFarben',
            published: this.checkPublishedInType('xFarben'),
          },
          {
            chanel: 'xFestek',
            published: this.checkPublishedInType('xFestek'),
          },
        ],
        name: variant.name,
        complementarsProducts: [],
        translations: [
          {
            lang: 'SK',
            variantName: '',
            variantPrice:
              variantPrice &&
              variantPrice.currency &&
              variantPrice.currency === 'EUR'
                ? variantPrice
                : {
                    amount: 0,
                    currency: '€',
                  },
          },
          {
            lang: 'CZ',
            variantName: '',
            variantPrice:
              variantPrice &&
              variantPrice.currency &&
              variantPrice.currency === 'CZK'
                ? variantPrice
                : {
                    amount: 0,
                    currency: 'Kč',
                  },
          },
          {
            lang: 'EN',
            variantName: '',
            variantPrice:
              variantPrice &&
              variantPrice.currency &&
              variantPrice.currency === 'EUR'
                ? variantPrice
                : {
                    amount: 0,
                    currency: '€',
                  },
          },
          {
            lang: 'AT',
            variantName: '',
            variantPrice:
              variantPrice &&
              variantPrice.currency &&
              variantPrice.currency === 'EUR'
                ? variantPrice
                : {
                    amount: 0,
                    currency: '€',
                  },
          },
        ],

        variantParams: [...this.state.variantParams],
      }),
    );

    this.setState({ variants, error: false }, () => {
      if (isFirst) {
        this.loadVariantDetail(0);
      }
    });
  }

  handleComplemetaryProductCountChange(id, value, primaryHardener = false) {
    const { variantDetail, variants } = this.state;

    if (!primaryHardener) {
      variants[variantDetail].complementarsProducts.find(
        (c) => c.No === id,
      ).count = value;
    } else {
      variants[variantDetail].complementarsProducts = variants[
        variantDetail
      ].complementarsProducts.map((c) =>
        Object.assign(c, {
          primary_hardener: c.No === id ? value : false,
        }),
      );
    }

    this.setState({ variants });
  }

  addComplementar(complementar) {
    const { variantDetail, variants } = this.state;
    if (
      variants[variantDetail].complementarsProducts.find(
        (c) => c.No === complementar.No,
      )
    ) {
      this.setState({
        error: __('Tento produkt sa už nachádza v tabuľke'),
      });

      return;
    }

    variants[variantDetail].complementarsProducts.push(complementar);
    this.setState({ variantDetail, error: false });
  }
  addCover(cover) {
    const { variantDetail, variants } = this.state;
    if (
      variants[variantDetail].covers_products &&
      variants[variantDetail].covers_products.find((c) => c.No === cover.No)
    ) {
      this.setState({
        error: __('Tento produkt sa už nachádza v tabuľke'),
      });

      return;
    }

    if (!variants[variantDetail.covers_products]) {
      variants[variantDetail].covers_products = [];
    }

    variants[variantDetail].covers_products.push(cover);

    this.setState({ variants, variantDetail, error: false });
  }

  async editPublicationFile(index, lang) {
    const { files, baseInfo } = this.state;

    if (files[index].langs.indexOf(lang) > -1) {
      files[index].langs.splice(files[index].langs.indexOf(lang), 1);
    } else {
      files[index].langs.push(lang);
    }
    this.setState(
      {
        files,
      },
      async () => {
        try {
          const params = {};
          params.body = files[index];
          await AdminAPI.putProductDocumentationAction(
            baseInfo.id,
            files[index]._id,
            params,
          );
        } catch (e) {
          console.log(e);
        }
      },
    );
  }

  deleteFile(index) {
    const { files, baseInfo } = this.state;
    try {
      const id = files[index] ? files[index]._id : null;

      if (
        id &&
        window.confirm(__(`Naozaj chcete zmazať súbor: ${files[index].name} ?`))
      ) {
        AdminAPI.deleteProductDocumentationAction(baseInfo.id, id);
        files.splice(index, 1);
        this.setState({ files, success: __('Dokument bol úspešne vymazaný') });
      }
    } catch (e) {
      console.log(e);
    }
  }

  deleteVariant(index) {
    const { variants } = this.state;
    variants.splice(index, 1);
    this.setState({ variants });
  }

  deleteRelatedProduct(id) {
    const { baseInfo } = this.state;

    baseInfo.relatedProducts = baseInfo.relatedProducts.filter(
      (r) => r._id !== id,
    );

    this.setState({
      baseInfo,
    });
  }

  deleteComplementar(index) {
    const { variantDetail, variants } = this.state;

    variants[variantDetail].complementarsProducts.splice(index, 1);
    this.setState({ variantDetail });
  }

  deleteCover(index) {
    const { variantDetail, variants } = this.state;

    variants[variantDetail].covers_products.splice(index, 1);
    this.setState({ variantDetail });
  }
  async downloadPDF(index) {
    const { files } = this.state;
    try {
      window.location = `${fileBasePath}/${files[index].file.fullPath}`;
    } catch (e) {
      console.log(e);
    }
  }

  handlePublicationChange(id, lang, chanel) {
    const { selectedCategories } = this.state;

    if (
      !selectedCategories
        .find((s) => s.chanel === chanel)
        .values.find((v) => v.lang === lang)
    ) {
      selectedCategories
        .find((s) => s.chanel === chanel)
        .values.push({
          lang,
          selected: [],
        });
    }

    const indexOfId = selectedCategories
      .find((s) => s.chanel === chanel)
      .values.find((v) => v.lang === lang)
      .selected.indexOf(id);
    if (indexOfId > -1) {
      selectedCategories
        .find((s) => s.chanel === chanel)
        .values.find((v) => v.lang === lang)
        .selected.splice(indexOfId, 1);
    } else {
      selectedCategories
        .find((s) => s.chanel === chanel)
        .values.find((v) => v.lang === lang)
        .selected.push(id);
    }
    this.setState({
      selectedCategories,
    });
  }

  loadVariantDetail(index) {
    const { variants } = this.state;
    const variant = variants[index];

    if (variant) {
      this.setState({
        variantDetail: index,
      });
    }
  }

  changeLanguageForVariants(id) {
    this.setState({
      variantActiveLanguage: id,
    });

    return true;
  }

  changeLanguageForPublications(id) {
    const { publicationChanel } = this.state;
    this.fetchCategories(id, publicationChanel);
    this.setState({
      publicationActiveLanguage: id,
    });

    return true;
  }

  changeLanguageForBaseInfo(id) {
    this.setState({
      baseInfoActiveLanguage: id,
    });

    return true;
  }

  changeChanelIdForPublications(id) {
    const { publicationActiveLanguage } = this.state;
    this.fetchCategories(publicationActiveLanguage, id);
    this.setState({
      publicationChanel: id,
    });

    return true;
  }

  handleVariantChange(e, type, lang, actualValue) {
    const { variants, variantDetail } = this.state;

    if (!variants[variantDetail]) {
      return;
    }

    const variant = variants[variantDetail];

    if (!variant.translations.find((t) => t.lang === lang)) {
      variant.translations.push({
        lang,
        variantName: '',
        variantPrice: {
          amount: 0,
          currency: '€',
        },
      });
    }

    if (type === 'variantName') {
      variant.translations.find((t) => t.lang === lang).variantName =
        e.target.value;
    } else if (type === 'variantEshopName') {
      variant.translations.find((t) => t.lang === lang).variantEshopName =
        e.target.value;
    } else if (type === 'variantPrice') {
      variant.translations.find((t) => t.lang === lang).variantPrice.amount =
        e.target.value;
    } else if (
      type === 'CRM' ||
      type === 'B2B' ||
      ['xFarby', 'xBarvy', 'xFarben', 'xFestek'].includes(type)
    ) {
      variant.publishing.find((p) => p.chanel === type).published =
        !actualValue;
    } else if (
      type === 'exportable' ||
      type === 'obnova_exportable' ||
      type === 'exportable_for_seko'
    ) {
      variant[type] = e.target.checked;
    }

    this.setState({
      variants,
    });
  }

  checkPublishedInType(chanel) {
    // tiez find
    const { selectedCategories } = this.state;
    let isPublished = false;
    if (
      selectedCategories.find((s) => s.chanel === chanel) &&
      selectedCategories
        .find((s) => s.chanel === chanel)
        .values.find((v) => v.lang === 'SK').selected.length > 0
    ) {
      isPublished = true;
    }

    // else if (
    //   selectedCategories
    //     .find(s => s.chanel === chanel)
    //     .values.find(v => v.lang === 'SK').selected.length > 0
    // ) {
    //   isPublished = true;
    // } else if (
    //   selectedCategories
    //     .find(s => s.chanel === chanel)
    //     .values.find(v => v.lang === 'SK').selected.length > 0
    // ) {
    //   isPublished = true;
    // } else if (
    //   selectedCategories
    //     .find(s => s.chanel === chanel)
    //     .values.find(v => v.lang === 'SK').selected.length > 0
    // ) {
    //   isPublished = true;
    // }

    return isPublished;
  }

  saveCard(from, to) {
    this.setState({
      success: false,
      error: false,
    });
    if (this.checkData()) {
      if (from.activeTab.id === 'tab1' || from.activeTab.id === 'tab3') {
        this.saveBaseInfo('TIKKURILA DECO', null, false, to.id);
        return true;
      }
      return true;
    }
    return false;
  }

  async saveBaseInfo(
    defaultBrand,
    defaultSubBrand,
    showLoading = true,
    toTab = 'tab1',
    relatedProductData = [],
  ) {
    if (showLoading) {
      this.setState({
        loading: true,
        error: false,
      });
    } else {
      this.setState({
        error: false,
      });
    }

    if (this.checkData()) {
      const {
        baseInfo,
        groups,
        notGrouped,
        selectedCategories,
        variants,
        noTintigParam,
        noTintinChoosesParam,
      } = this.state;

      const params = notGrouped.map((param) => {
        return Object.assign(param, {
          group: '',
        });
      });

      const params2 = groups.map((group) => {
        return group.params;
      });

      const mergedParams = params.concat([].concat.apply([], params2));

      mergedParams.push(noTintigParam);
      mergedParams.push(noTintinChoosesParam);

      const normalizedParams = mergedParams
        .filter((m) => m)
        .map((param) => {
          let value = param.value || '';

          if (Array.isArray(param.value) && param.type === 'multiSelect') {
            value = param.value.map((v) => ({
              value: v.value,
              _id: v._id,
            }));
          }

          return {
            value,
            _id: param._id || '',
            selectedUnit: param.selectedUnit || '',
          };
        });

      if (toTab === 'tab6' && relatedProductData.length) {
        baseInfo.relatedProducts = relatedProductData;
      }

      const data = {
        name: baseInfo.name.value,
        nameTranslations: baseInfo.nameTranslations,
        // url: baseInfo.url,
        url_by_lang: baseInfo.url_by_lang,
        url_history: baseInfo.url_history,
        external_product: baseInfo.external_product,
        show_all_bases: baseInfo.show_all_bases || false,
        brand: baseInfo.brand.length > 0 ? baseInfo.brand : defaultBrand,
        alt_image_text: baseInfo.altText,
        alt_image_text_translations: baseInfo.altTextTranslations,
        key_variant_param: baseInfo.key_variant_param,
        sort_variant_param: baseInfo.sort_variant_param,
        related_products: baseInfo.relatedProducts
          ? baseInfo.relatedProducts.map((r) => r._id)
          : [],
        multimedia_images: baseInfo.multimedia_images
          ? baseInfo.multimedia_images.map((m) => m._id)
          : [],
        subbrand:
          baseInfo.subbrand && baseInfo.subbrand.length > 0
            ? baseInfo.subbrand
            : defaultSubBrand,
        type: baseInfo.type,
        // avatint_products:
        //   baseInfo.avatint_products &&
        //   baseInfo.avatint_products.map(a => a.value),
        // temaspeed_products:
        //   baseInfo.temaspeed_products &&
        //   baseInfo.temaspeed_products.map(a => a.value),
        bases_settings: baseInfo.bases_settings,
        farlesk_export_colorcards:
          baseInfo.farleskExportColorcards &&
          baseInfo.farleskExportColorcards.map((f) => f.value),
        favorit_colorcards:
          baseInfo.favoritColorcards &&
          baseInfo.favoritColorcards.map((f) => f.value),
        external_products:
          baseInfo.external_products &&
          baseInfo.external_products.map((a) => a.value),
        colorcards_texture:
          baseInfo.colorcardsTexture && baseInfo.colorcardsTexture.value,
        exportable: baseInfo.exportable,
        obnova_exportable: baseInfo.obnova_exportable,
        exportable_for_seko: baseInfo.exportable_for_seko,
        security_alerts: baseInfo.securityAlerts || {},
        params: normalizedParams || [],
        description: baseInfo.translations.find(
          (t) => t.lang === this.props.firm,
        ).description,
        shortDescription: baseInfo.shortDescriptionTranslations
          ? baseInfo.shortDescriptionTranslations.find(
              (t) => t.lang === this.props.firm,
            ).description
          : '',
        selectedCategories,
        variants: variants.map((v) => {
          return {
            ...v,
            multimedia_images: v.multimedia_images
              ? v.multimedia_images.map((m) => m._id)
              : [],
            variantParams: v.variantParams.map((vp) => {
              return {
                _id: vp._id,
                value: vp.value,
                selectedUnit: vp.selectedUnit || '',
                // name: vp.name,
                label: vp.label,
              };
            }),
          };
        }),
        translations: [
          {
            lang: 'SK',
            description: baseInfo.translations.find((t) => t.lang === 'SK')
              .description,
          },
          {
            lang: 'CZ',
            description: baseInfo.translations.find((t) => t.lang === 'CZ')
              .description,
          },
          {
            lang: 'EN',
            description: baseInfo.translations.find((t) => t.lang === 'EN')
              .description,
          },
          {
            lang: 'AT',
            description: baseInfo.translations.find((t) => t.lang === 'AT')
              ? baseInfo.translations.find((t) => t.lang === 'AT').description
              : '',
          },
        ],
        metaTitleTranslations: baseInfo.metaTitleTranslations,
        metaDescriptionTranslations: baseInfo.metaDescriptionTranslations,
        delivery_terms: baseInfo.delivery_terms && {
          type: baseInfo.delivery_terms.type,
          value: Number(baseInfo.delivery_terms.value),
        },
        shortDescriptionTranslations: [
          {
            lang: 'SK',
            description: baseInfo.shortDescriptionTranslations.find(
              (t) => t.lang === 'SK',
            ).description,
          },
          {
            lang: 'CZ',
            description: baseInfo.shortDescriptionTranslations.find(
              (t) => t.lang === 'CZ',
            ).description,
          },
          {
            lang: 'EN',
            description: baseInfo.shortDescriptionTranslations.find(
              (t) => t.lang === 'EN',
            ).description,
          },
          {
            lang: 'AT',
            description: baseInfo.shortDescriptionTranslations.find(
              (t) => t.lang === 'AT',
            )
              ? baseInfo.shortDescriptionTranslations.find(
                  (t) => t.lang === 'AT',
                ).description
              : '',
          },
        ],
      };

      try {
        if (getIdFromProps(this.props) === 'new') {
          // const params = {};
          // params.body = data;
          const result = await AdminAPI.postProductAction({
            body: data,
          });

          this.props.history.push(`/${this.props.firm}/products/${result._id}`);
          this.setState({
            baseInfo: Object.assign({}, baseInfo, { id: result._id }),
          });
        } else {
          await AdminAPI.putProductAction(getIdFromProps(this.props), {
            body: data,
          });
        }
        if (showLoading) {
          this.setState({
            success: __('Produkt bol úspešne uložený'),
            activeTab: toTab,
          });
        }
      } catch (e) {
        this.setState({
          error: __('Pri ukladaní dát nastala chyba'),
        });
      } finally {
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async deleteProduct() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteProductAction(getIdFromProps(this.props));
      history.push(`/${firm}/products`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  onDeliveryTermsChange(value, field) {
    const { baseInfo } = this.state;

    if (!baseInfo.delivery_terms) {
      baseInfo.delivery_terms = {};
    }

    baseInfo.delivery_terms[field] = value;

    this.setState({ baseInfo });
  }

  checkData(informUser = true) {
    const { baseInfo } = this.state;

    let isValid = true;

    if (baseInfo.name.value.length < 1) {
      isValid = false;
      baseInfo.name.error = true;
      if (informUser) {
        this.setState({
          error: __('Meno produktu je povinný parameter'),
          activeTab: 'tab1',
          baseInfo,
        });
      }
    }

    // if (baseInfo.url.value.length < 1) {
    //   isValid = false;
    //   baseInfo.url.error = true;
    //   if (informUser) {
    //     this.setState({
    //       error: __('Url produktu je povinný parameter'),
    //       activeTab: 'tab1',
    //       baseInfo,
    //     });
    //   }
    // }

    return isValid;
  }

  showConfirmDialog() {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
    });
  }

  changeMainImage(id) {
    const { baseInfo } = this.state;
    baseInfo.titleImage = id;

    const params = {};
    params.body = {
      multimedium_title_image_id: id,
    };

    this.setState(
      {
        baseInfo,
      },
      () => {
        AdminAPI.putCatalogProductImage(getIdFromProps(this.props), params);
      },
    );
  }

  removeMultimedium(id) {
    const { baseInfo } = this.state;

    baseInfo.multimedia_images = baseInfo.multimedia_images.filter(
      (m) => m._id !== id,
    );

    this.setState({
      baseInfo,
    });
  }

  removeMultimediumFromVariant(variantId, multimediumId) {
    const { variants } = this.state;

    variants.find((v) => v._id === variantId).multimedia_images = variants
      .find((v) => v._id === variantId)
      .multimedia_images.filter((m) => m._id !== multimediumId);

    this.setState({
      variants,
    });
  }

  addMultimediumToVariant(variantId, m) {
    const { variants } = this.state;

    const variant = variants.find((v) => v._id === variantId);

    if (!variant.multimedia_images) {
      variants.find((v) => v._id === variantId).multimedia_images = [];
    }

    variants.find((v) => v._id === variantId).multimedia_images.push(m.raw);

    this.setState({
      variants,
    });
  }

  addMultimedium(m) {
    const { baseInfo } = this.state;

    if (!baseInfo.multimedia_images) {
      baseInfo.multimedia_images = [];
    }

    baseInfo.multimedia_images.push(m.raw);

    this.setState({
      baseInfo,
    });
  }

  changeAltImage(fullPath) {
    const { baseInfo } = this.state;

    if (baseInfo.altImage === fullPath) {
      baseInfo.altImage = '';
      this.setState({
        baseInfo,
      });

      return;
    }

    baseInfo.altImage = fullPath;

    const params = {};

    params.body = {
      alt_image_full_path: fullPath,
    };

    this.setState(
      {
        baseInfo,
      },
      () => {
        AdminAPI.putCatalogProductImage(getIdFromProps(this.props), params);
      },
    );
  }

  changeAltText(text, lang) {
    const { baseInfo } = this.state;

    if (lang === 'SK') {
      baseInfo.altText = text;
    }

    if (!baseInfo.altTextTranslations) {
      baseInfo.altTextTranslations = [];
    }

    if (!baseInfo.altTextTranslations.find((b) => b.lang === lang)) {
      baseInfo.altTextTranslations.push({
        lang,
        value: text,
      });
    } else {
      baseInfo.altTextTranslations.find((b) => b.lang === lang).value = text;
    }

    this.setState({
      baseInfo,
    });
  }

  async sychnronizeProduct(id) {
    const { sychnronizeProductLoading } = this.state;

    if (sychnronizeProductLoading) {
      return;
    }

    this.setState({
      sychnronizeProductLoading: true,
    });

    try {
      await API2.postSyncProductAction(id);
      this.setState({
        sychnronizeProductLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Synchronizácia produktu zlyhala'),
        sychnronizeProductLoading: false,
      });
    }
  }

  async getReviewsFromGoogle(ignore = false) {
    const { reviewGoogleUrl, reviewGoogleLimit, reviewTranslations } =
      this.state;

    try {
      this.setState({
        reviewsLoading: true,
      });
      const r = await API2.postGoogleReviewDownload(
        getIdFromProps(this.props),
        {
          limit: reviewGoogleLimit,
          body: {
            langs: reviewTranslations.map((l) => l.value),
            google_url: reviewGoogleUrl,
            ignore_name_not_match: ignore,
          },
        },
      );

      this.setState({
        reviewsLoading: false,
        showReviewDialog: false,
        showReviewWarning: false,
        success: __(
          `Bolo stiahnutych ${r.downloaded}, aktualizovalo sa ${r.saved} recenzii`,
        ),
      });
    } catch (e) {
      console.log(e);
      if (e.response.status === 409) {
        this.setState({
          reviewsLoading: false,
          showReviewWarning: true,
          showReviewDialog: false,
        });
      } else {
        this.setState({
          reviewsLoading: false,
          showReviewDialog: false,
          showReviewWarning: false,
          error: __('Vyskytla sa neočakavana chyba'),
        });
      }
    }
  }

  async createProductCopy(id) {
    this.setState({
      copyLoading: true,
    });

    try {
      const result = await AdminAPI.postProductCopyAction(id);
      this.setState({
        copyLoading: false,
        copySuccessId: result._id,
      });
    } catch (e) {
      this.setState({
        error: __('Kópiu produktu sa nepodarilo vytvoriť'),
      });
    }
  }

  redirectToCopy(id) {
    this.props.history.push(`/${this.props.firm}/products/${id}`);

    this.setState({
      copySuccessId: false,
    });
  }

  raiseErrorMessage(message) {
    this.setState({
      error: message,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { copyLoading, sychnronizeProductLoading } = this.state;

    const id = getIdFromProps(this.props);

    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Karta Produktu')}>
          {id !== 'new' && (
            <ControlBarButton
              small
              primary
              showIcon={false}
              onClick={() =>
                this.setState({
                  showReviewDialog: true,
                })
              }
            >
              {__('Recenzie')}
            </ControlBarButton>
          )}
          {id !== 'new' && (
            <ControlBarButton
              small
              primary
              showIcon={false}
              loading={sychnronizeProductLoading}
              onClick={() => this.sychnronizeProduct(id)}
            >
              {__('Synchronizácia')}
            </ControlBarButton>
          )}
          {id !== 'new' && (
            <ControlBarButton
              small
              primary
              showIcon={false}
              loading={copyLoading}
              onClick={() => this.createProductCopy(id)}
              icon="plus"
            >
              {__('Vytvoriť kópiu')}
            </ControlBarButton>
          )}
          {id !== 'new' && (
            <ControlBarButton
              small
              danger
              onClick={() => this.showConfirmDialog()}
              icon="delete"
            >
              {__('Odstrániť')}
            </ControlBarButton>
          )}
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      notGrouped,
      groups,
      baseInfo,
      files,
      publications,
      selectedCategories,
      variants,
      variantActiveLanguage,
      publicationActiveLanguage,
      baseInfoActiveLanguage,
      loading,
      activeTab,
      error,
      success,
      categoriesLoading,
      productTypes,
      brands,
      subBrandsCodelist,
      copySuccessId,
      typesForTinting,
      showConfirmDialog,
      showReviewDialog,
      deleteError,
      deleteLoading,
      photos,
      selectedCanal,
      docSaveLoading,
      variantParams,
      sales,
      clearances,
      reviewGoogleUrl,
      reviewGoogleLimit,
      reviewTranslations,
      reviewsLoading,
      showReviewWarning,
    } = this.state;

    if (loading) {
      return (
        <React.Fragment>
          {this.renderControlBar()}
          <LoaderWrapper>
            <Loader size="xl" />
          </LoaderWrapper>
        </React.Fragment>
      );
    }

    const tabs = [];

    tabs.push(
      <Tabs.Tab id="tab1" title={__('Základné informácie')}>
        <ContentWrapper>
          <BaseInfo
            saveCard={(defaultBrand, defaultSubBrand) =>
              this.saveBaseInfo(defaultBrand, defaultSubBrand)
            }
            baseInfo={baseInfo}
            onChange={(value, groupIndex, fieldIndex, rowIndex, columnIndex) =>
              this.handleChange(
                value,
                groupIndex,
                fieldIndex,
                rowIndex,
                columnIndex,
              )
            }
            variantParams={variantParams}
            handleUnitChange={(
              groupIndex,
              fieldIndex,
              rowIndex,
              columnIndex,
              value,
            ) =>
              this.handleUnitChange(
                groupIndex,
                fieldIndex,
                rowIndex,
                columnIndex,
                value,
              )
            }
            onDeliveryTermsChange={(value, field) =>
              this.onDeliveryTermsChange(value, field)
            }
            changeLanguageForBaseInfo={(id) =>
              this.changeLanguageForBaseInfo(id)
            }
            activeLanguage={baseInfoActiveLanguage}
            baseOnChange={(e, field, descriptionLanguage) =>
              this.handleBaseInfoChange(e, field, descriptionLanguage)
            }
            notGrouped={notGrouped}
            groups={groups}
            isNew={getIdFromProps(this.props) === 'new'}
            productTypes={productTypes}
            brands={brands}
            subBrands={subBrandsCodelist}
            id={getIdFromProps(this.props)}
          />
        </ContentWrapper>
      </Tabs.Tab>,
    );

    if (typesForTinting.includes(baseInfo.type)) {
      tabs.push(
        <Tabs.Tab id="tab5" title={__('Tónovanie')}>
          <ContentWrapper>
            <Toning
              showAllBases={baseInfo.show_all_bases}
              externalProducts={baseInfo.external_products}
              selectedToningSystemId={baseInfo.toning_system_id}
              noTintigParam={this.state.noTintigParam}
              noTintinChoosesParam={this.state.noTintinChoosesParam}
              favoritColorcards={baseInfo.favoritColorcards || []}
              farleskExportColorcards={baseInfo.farleskExportColorcards || []}
              variants={variants}
              productId={baseInfo.id}
              raiseErrorMessage={(message) => this.raiseErrorMessage(message)}
              saveCard={() => this.saveBaseInfo('', '', false, 'tab5', false)}
              basesSettings={baseInfo.bases_settings || []}
              colorcardsTexture={baseInfo.colorcardsTexture}
              handleNoTintingValueChange={(data) =>
                this.handleNoTintingValueChange(data)
              }
              onCoeficientChange={(number, no) =>
                this.handleCoeficientChange(number, no)
              }
              handleExternalProduct={(e, importId) =>
                this.handleExternalProduct(e, importId)
              }
              handleBaseSettingsChange={(name, value, field) =>
                this.handleBaseSettingsChange(name, value, field)
              }
              externalProduct={baseInfo.external_product}
              handleExternalProductsChange={(value) =>
                this.handleBaseInfoChange(value, 'external_product')
              }
              handleFavoritColorcardsChange={(value) => {
                this.handleFavoritColorcardsChange(value);
              }}
              handleFarleskExportColorcardsChange={(value) => {
                this.handleFarleskExportColorcardsChange(value);
              }}
              handleColorcardsTexture={(value) =>
                this.handleColorcardsTexture(value)
              }
              handlesAllBasesChange={(value) =>
                this.handlesAllBasesChange(value)
              }
            />
          </ContentWrapper>
        </Tabs.Tab>,
      );
    }

    tabs.push(
      <Tabs.Tab id="tab" title={__('Dokumentácia')}>
        <ContentWrapper>
          <Documentation
            changeMainImage={(id) => this.changeMainImage(id)}
            changeAltImage={(fullPath) => this.changeAltImage(fullPath)}
            changeAltText={(text, lang) => this.changeAltText(text, lang)}
            titleImage={baseInfo.titleImage}
            altImage={baseInfo.altImage}
            altText={baseInfo.altText}
            multimedia={baseInfo.multimedia_images}
            altTextTranslations={baseInfo.altTextTranslations}
            saveCard={() => this.saveBaseInfo('', '', true, 'tab2')}
            files={files}
            addNewFile={(file) => this.addNewFile(file)}
            saveFile={(file, id) => this.saveFile(file, id)}
            addPhoto={(photo) => this.addPhoto(photo)}
            deletePhoto={(fullPath) => this.deletePhoto(fullPath)}
            editPublicationFile={(fileIndex, language) =>
              this.editPublicationFile(fileIndex, language)
            }
            addMultimedium={(m) => this.addMultimedium(m)}
            removeMultimedium={(id) => this.removeMultimedium(id)}
            docSaveLoading={docSaveLoading}
            photos={photos}
            downloadPDF={(index) => this.downloadPDF(index)}
            removeFile={(index) => this.deleteFile(index)}
            handleFileDateChange={(value, index) =>
              this.handleFileDateChange(value, index)
            }
            selectedSecurityAlerts={baseInfo.securityAlerts}
            handleSecurityAlertsChange={(value) =>
              this.handleSecurityAlertsChange(value)
            }
          />
        </ContentWrapper>
      </Tabs.Tab>,
    );

    tabs.push(
      <Tabs.Tab id="tab3" title={__('Publikácia')}>
        <ContentWrapper>
          <Publication
            loading={categoriesLoading}
            saveCard={() => this.saveBaseInfo('', '', true, 'tab3')}
            publications={publications}
            selectedCategories={selectedCategories}
            switchChanel={(e) => this.switchChanel(e)}
            selectedCanal={selectedCanal}
            activeLanguage={publicationActiveLanguage}
            changeLanguageForPublications={(id) =>
              this.changeLanguageForPublications(id)
            }
            changeChanelIdForPublications={(id) =>
              this.changeChanelIdForPublications(id)
            }
            handlePublicationChange={(ancestors, id, value, lang, chanel) =>
              this.handlePublicationChange(ancestors, id, value, lang, chanel)
            }
          />
        </ContentWrapper>
      </Tabs.Tab>,
    );

    tabs.push(
      <Tabs.Tab id="tab4" title={__('Varianty')}>
        <ContentWrapper>
          <Variants
            colocardsIds={
              baseInfo.colorcards
                ? baseInfo.colorcards
                    .filter((c) => c.purpose === 'finished')
                    .map((c) => c._id)
                : []
            }
            saveCard={() => this.saveBaseInfo('', '', true, 'tab4')}
            changeVariantAltText={(value, lang, id) =>
              this.changeVariantAltText(value, lang, id)
            }
            addMultimediumToVariant={(variantId, m) =>
              this.addMultimediumToVariant(variantId, m)
            }
            removeMultimediumFromVariant={(variantId, multimediumId) =>
              this.removeMultimediumFromVariant(variantId, multimediumId)
            }
            handleUnitChange={(
              groupIndex,
              fieldIndex,
              rowIndex,
              columnIndex,
              value,
            ) =>
              this.handleVariantUnitChange(
                groupIndex,
                fieldIndex,
                rowIndex,
                columnIndex,
                value,
              )
            }
            variantOnChange={(e, type, lang, actualValue) =>
              this.handleVariantChange(e, type, lang, actualValue)
            }
            variantParamsOnChange={(
              value,
              groupIndex,
              fieldIndex,
              rowIndex,
              columnIndex,
              variantIndex,
            ) =>
              this.handleVariantParamsChange(
                value,
                groupIndex,
                fieldIndex,
                rowIndex,
                columnIndex,
                variantIndex,
              )
            }
            variants={variants}
            activeLanguage={variantActiveLanguage}
            parentPublished={{
              CRM: this.checkPublishedInType('CRM'),
              B2B: this.checkPublishedInType('B2B'),
              xFarby: this.checkPublishedInType('xFarby'),
              xBarvy: this.checkPublishedInType('xBarvy'),
              xFarben: this.checkPublishedInType('xFarben'),
              xFestek: this.checkPublishedInType('xFestek'),
            }}
            deleteVariant={(index) => this.deleteVariant(index)}
            addVariant={(variant, isFirst, price) =>
              this.addVariant(variant, isFirst, price)
            }
            hideMultimedia={(id, hide) => this.hideMultimedia(id, hide)}
            deleteComplementar={(index) => this.deleteComplementar(index)}
            addComplementar={(complementar) =>
              this.addComplementar(complementar)
            }
            handleComplemetaryProductCountChange={(
              id,
              value,
              primaryHardener,
            ) =>
              this.handleComplemetaryProductCountChange(
                id,
                value,
                primaryHardener,
              )
            }
            deleteCover={(index) => this.deleteCover(index)}
            addCover={(cover) => this.addCover(cover)}
            changeSelectedVariant={(index) => this.loadVariantDetail(index)}
            changeLanguageForVariants={(id) =>
              this.changeLanguageForVariants(id)
            }
            addVariantPhoto={(variantId, photo) =>
              this.addVariantPhoto(variantId, photo)
            }
            deleteVariantPhoto={(variantId, fullPath) =>
              this.deleteVariantPhoto(variantId, fullPath)
            }
          />
        </ContentWrapper>
      </Tabs.Tab>,
    );

    tabs.push(
      <Tabs.Tab id="tab7" title={__('Výpredaj WH010')}>
        <ContentWrapper>
          <Sales
            sales={sales}
            variants={variants}
            changeSelectedVariant={() => {}}
            loading={false}
            onSuccesSave={() => {
              this.setState({
                success: __('Výpredajové produkty boli úspešne uložené'),
              });
            }}
            onErrorSave={() => {
              this.setState({
                error: __('Výpredajové produkty sa nepodarilo uložiť'),
              });
            }}
          />
        </ContentWrapper>
      </Tabs.Tab>,
    );

    tabs.push(
      <Tabs.Tab id="tab8" title={__('Dopredaj')}>
        <ContentWrapper>
          <FinalSale
            clearances={clearances}
            variants={variants}
            changeSelectedVariant={() => {}}
            loading={false}
            onSuccesSave={() => {
              this.setState({
                success: __('Dopradajové produkty boli úspešne uložené'),
              });
            }}
            onErrorSave={() => {
              this.setState({
                error: __('Dopradajové produkty sa nepodarilo uložiť'),
              });
            }}
          />
        </ContentWrapper>
      </Tabs.Tab>,
    );

    tabs.push(
      <Tabs.Tab id="tab6" title={__('Súvisiace produkty')}>
        <ContentWrapper>
          <RelatedProducts
            firm={this.props.firm}
            deleteRelatedProduct={(id) => this.deleteRelatedProduct(id)}
            saveCard={(tableData) =>
              this.saveBaseInfo('', '', true, 'tab6', tableData)
            }
            addRelatedProduct={(data) => this.addRelatedProduct(data)}
            relatedProducts={baseInfo.relatedProducts}
          />
        </ContentWrapper>
      </Tabs.Tab>,
    );

    tabs.push(
      <Tabs.Tab id="tab9" title={__('Recenzie')}>
        <ContentWrapper>
          <Reviews
            catalogProductId={getIdFromProps(this.props)}
            firm={this.props.firm}
          />
        </ContentWrapper>
      </Tabs.Tab>,
    );

    tabs.push(
      <Tabs.Tab id="tab10" title={__('Rozsiahly popis')}>
        <ContentWrapper>
          <ProductDescription
            productId={getIdFromProps(this.props)}
            firm={this.props.firm}
            data={baseInfo.extended_description}
          />
        </ContentWrapper>
      </Tabs.Tab>,
    );

    return (
      <React.Fragment>
        <PopUpWrapper
          display={copySuccessId}
          small
          onClose={() =>
            this.setState({
              copySuccessId: false,
            })
          }
        >
          <ConfirmDialog
            message={__(
              'Kópia produktu bola vytvorená. Prajete si prejsť na jej detail?',
            )}
            onDismiss={() => this.redirectToCopy(copySuccessId)}
            onConfirm={() =>
              this.setState({
                copySuccessId: false,
              })
            }
            dismissComponent={
              <HrefButton href={`./${copySuccessId}`}>{__('Áno')}</HrefButton>
            }
            confirmMessage={__('Nie')}
          />
        </PopUpWrapper>
        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.showConfirmDialog()}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť produkt ?')}
            onDismiss={() => this.showConfirmDialog()}
            onConfirm={() => this.deleteProduct()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showReviewWarning}
          small
          message={__('Prajete si pokračovať ?')}
          onClose={() =>
            this.setState({
              showReviewWarning: false,
              showReviewDialog: true,
            })
          }
        >
          <ConfirmDialog
            message={__('Recenzie pravdepodobne neprisluchaju danému produktu')}
            onDismiss={() =>
              this.setState({
                showReviewWarning: false,
                showReviewDialog: true,
              })
            }
            onConfirm={() => {
              this.getReviewsFromGoogle(true);
            }}
            loading={reviewsLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showReviewDialog}
          small
          message={__('Stiahnúť recenzie z Google')}
          onClose={() =>
            this.setState({
              showReviewDialog: false,
            })
          }
        >
          <PopUpInsideWrapper>
            <InputWrapper>
              <StyledLabel>{__('Preložiť recenziu do jazykov')}</StyledLabel>
              <SearchableSelect
                loadOptions={() => {
                  return REVIEWS_LANGS.map((lang) => ({
                    label: lang,
                    value: lang,
                  }));
                }}
                handleOnChange={(e) =>
                  this.setState({
                    reviewTranslations: e,
                  })
                }
                value={reviewTranslations}
                placeholder={__('Vyberte jazyky prekladu')}
                isMulti
              />
            </InputWrapper>
            <InputWrapper>
              <StyledLabel> {__('URL recenzie v Google')}</StyledLabel>
              <StyledInput
                style={{ width: '100%' }}
                value={reviewGoogleUrl}
                placeholder={__('URL link')}
                onChange={(e) =>
                  this.setState({
                    reviewGoogleUrl: e.target.value,
                  })
                }
              />
            </InputWrapper>
            <InputWrapper>
              <StyledLabel> {__('Počet recenzii na stiahnutie')}</StyledLabel>
              <StyledInput
                style={{ width: '100%' }}
                value={reviewGoogleLimit}
                placeholder={__('10')}
                onChange={(e) =>
                  this.setState({
                    reviewGoogleLimit: e.target.value,
                  })
                }
              />
            </InputWrapper>
            <ButtonWrapper>
              <Button
                small
                disabled={
                  !reviewGoogleUrl ||
                  !(reviewTranslations && reviewTranslations.length > 0)
                }
                loading={reviewsLoading}
                onClick={() => this.getReviewsFromGoogle()}
                primary
              >
                {__('Uložiť')}
              </Button>
            </ButtonWrapper>
          </PopUpInsideWrapper>
        </PopUpWrapper>

        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>
        <AnimatedFormMessageWrapper display={success}>
          <Message success message={success} />
        </AnimatedFormMessageWrapper>
        <Tabs
          color={ThemeAdmin.color.primary}
          onChange={(to, from) => {
            return this.saveCard(from, to);
          }}
          activeTab={{
            id: activeTab,
          }}
        >
          {tabs}
        </Tabs>
      </React.Fragment>
    );
  }
}

ProductCard.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ProductCard.defaultProps = {
  firm: '',
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductCard);
