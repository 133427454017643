import Loader from 'oxyrion-ui/lib/Loader';
import Message from 'oxyrion-ui/lib/Message';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import Button from 'oxyrion-ui/lib/Button';
import { rem } from 'polished';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import React from 'react';
import styled from 'styled-components';
import ReactTable from 'react-table';
import AdminAPI from '../../../AdminAPI';
import { LoaderWrapper } from '../../../Components/ReusableComponents';
import { connect } from '../../../Store';
import { __ } from '../../../Utils';
import PopUpWrapper from '../../../Components/PopUpWrapper';
import ConfirmDialog from '../../../Components/ConfirmDialog';
import API from '../../../API';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Wrapper = styled.div`
  overflow: auto;
  margin-top: ${rem(12)};
  margin-bottom: ${rem(12)};
`;

const StyledHeader = styled.div`
  display: flex;
  margin-left: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #434245;
  margin-top: 15px;
`;

const StyledTd = styled.div`
  display: flex;
  margin: 8px;
  margin: 8px;
  font-size: 11px;
`;

const CenteredStyledTd = styled(StyledTd)`
  justify-content: center;
  align-items: center;
`;

const LabeledCheckboxWrapper = styled.div`
  margin-right: ${rem(8)};
`;

const LabeledCheckboxWrapperPopup = styled.div`
  margin-bottom: ${rem(8)};
`;

const TableWrapper = styled.div`
  min-width: ${rem(1600)};
`;

const ShowAllVariants = styled.div`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
`;

const VariantsWrapper = styled.div`
  min-height: ${rem(480)};
  max-height: ${rem(1000)};
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: ${rem(20)};
  padding-bottom: ${rem(0)};
`;

const PopupButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: ${rem(20)};
`;

const Space = styled.div`
  width: ${rem(16)};
  height: ${rem(16)};
`;

class FarleskProductsExport extends React.Component {
  state = {
    products: [],
    changedProducts: [],
    error: null,
  };

  componentDidMount() {
    this.fetchProducts();
  }

  async fetchProducts() {
    this.setState({
      loading: true,
    });

    try {
      const { items: products } = await AdminAPI.getAllCatalogProductsAction();

      this.setState({
        loading: false,
        products,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: __('Produkty sa nepodarilo načítať'),
      });
    }
  }

  handleCheckboxClick(id, value) {
    const { products, changedProducts } = this.state;

    changedProducts.push(id);

    products.find(p => p._id === id).exportable = value;

    this.setState({
      products,
      changedProducts,
    });
  }

  handleVariantCheckboxClick(productId, variantId, value) {
    const { products, changedProducts } = this.state;

    changedProducts.push(productId);

    products.find(p => p._id === productId).exportable = true;

    products
      .find(p => p._id === productId)
      .variants.find(v => v._id === variantId).exportable = value;

    this.setState({
      products,
      changedProducts,
    });
  }

  async saveExport() {
    this.setState({
      saveLoading: true,
    });

    try {
      const { products, changedProducts } = this.state;

      const _products = products
        .filter(p => changedProducts.includes(p._id))
        .map(p => {
          return {
            product_id: p._id,
            value: p.exportable || false,
            variants: p.variants.map(v => ({
              _id: v._id,
              value: v.exportable || false,
            })),
          };
        });

      const params = {};

      params.body = {
        products: _products,
      };

      await AdminAPI.putProductsExportFarleskAction(params);

      this.setState({
        saveLoading: false,
        error: false,
        success: __('Dáta úspešne uložené'),
      });
    } catch (e) {
      this.setState({
        saveLoading: false,
        success: false,
        error: __('Dáta sa nepodarilo uložiť'),
      });
    }
  }

  async export() {
    try {
      this.setState({
        exportLoading: true,
      });

      await this.saveExport();

      const result = await API.getProductsFileAction();

      this.setState({
        showDownloadDialog: true,
        downloadId: result.url,
        exportLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Produkty sa nepodarilo vyexportovať'),
      });
    }
  }

  downloadFileWithId() {
    window.location.href = this.state.downloadId;
    this.setState({
      downloadId: undefined,
      showDownloadDialog: false,
    });
  }

  createColumns() {
    const result = [
      {
        accessor: 'exportable',
        Header: () => <StyledHeader>{__('Exportovať')}</StyledHeader>,
        maxWidth: 100,
        Cell: props => {
          return (
            <CenteredStyledTd>
              <Checkbox
                checked={props.value}
                onChange={e =>
                  this.handleCheckboxClick(props.original._id, e.target.checked)
                }
              />
            </CenteredStyledTd>
          );
        },
      },
      {
        accessor: 'name',
        Header: () => <StyledHeader>{__('Názov produktu')}</StyledHeader>,
        maxWidth: 300,
        Cell: props => {
          return <StyledTd>{props.value}</StyledTd>;
        },
      },
      {
        accessor: 'variants',
        Header: () => <StyledHeader>{__('Varianty')}</StyledHeader>,
        Cell: props => {
          return (
            <StyledTd>
              {props.value.slice(0, 5).map(v => (
                <LabeledCheckboxWrapper>
                  <LabeledCheckbox
                    labelText={
                      v.name
                        .toLowerCase()
                        .replace(props.original.name.toLowerCase(), '') ||
                      v.name
                    }
                    onChange={e =>
                      this.handleVariantCheckboxClick(
                        props.original._id,
                        v._id,
                        e.target.checked,
                      )
                    }
                    checked={v.exportable}
                  />
                </LabeledCheckboxWrapper>
              ))}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'variants',
        Header: () => <StyledHeader />,
        maxWidth: 200,
        Cell: props => {
          return (
            <StyledTd>
              {/* {props.value.length > 5 ? ( */}
              <ShowAllVariants
                onClick={() =>
                  this.setState({
                    productVariantsToShow: props.original._id,
                  })
                }
              >
                {`${__('Zobraziť všetky varianty')} (${props.value.length})`}
              </ShowAllVariants>
              {/* ) : (
                `${props.value.length} variant`
              )} */}
            </StyledTd>
          );
        },
      },
    ];

    return result;
  }

  useCustomheader(columns) {
    return columns.map(i => ({
      Header: () => <div>{i.name}</div>,
      ...i,
    }));
  }

  render() {
    const {
      products,
      loading,
      error,
      success,
      productVariantsToShow,
      saveLoading,
      showDownloadDialog,
      exportLoading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <Wrapper>
        {error && <Message error message={error} />}
        {success && <Message message={success} />}

        <PopUpWrapper
          display={showDownloadDialog}
          small
          message={__('Sťiahnúť súbor(y)')}
          onClose={() => this.setState({ showDownloadDialog: false })}
        >
          <ConfirmDialog
            message={__('Želáte si stiahnúť zvolený súbor ?')}
            onDismiss={() => this.setState({ showDownloadDialog: false })}
            onConfirm={() => this.downloadFileWithId()}
          />
        </PopUpWrapper>

        <PopUpWrapper
          small
          display={productVariantsToShow}
          onClose={() =>
            this.setState({
              productVariantsToShow: undefined,
            })
          }
          message={`${__('Varianty produktu')} ${productVariantsToShow &&
            products.find(p => p._id === productVariantsToShow).name}`}
          onEscClose={() =>
            this.setState({
              productVariantsToShow: undefined,
            })
          }
        >
          <VariantsWrapper>
            {products.find(p => p._id === productVariantsToShow) &&
              products
                .find(p => p._id === productVariantsToShow)
                .variants.map(v => (
                  <LabeledCheckboxWrapperPopup>
                    <LabeledCheckbox
                      onChange={e =>
                        this.handleVariantCheckboxClick(
                          productVariantsToShow,
                          v._id,
                          e.target.checked,
                        )
                      }
                      labelText={v.name}
                      checked={v.exportable}
                    />
                  </LabeledCheckboxWrapperPopup>
                ))}
          </VariantsWrapper>
          <PopupButtonWrapper>
            <Button
              onClick={() =>
                this.setState({
                  productVariantsToShow: undefined,
                })
              }
              primary
            >
              {__('Uložiť a pokračovať')}
            </Button>
          </PopupButtonWrapper>
        </PopUpWrapper>

        <TableWrapper>
          <ReactTableFixedColumns
            columns={this.useCustomheader(this.createColumns())}
            resizable={false}
            data={products || []}
            pageSize={50}
            showPageSizeOptions={false}
            pageText={__('Strana')}
            ofText={__('z')}
            previousText={__('Späť')}
            nextText={__('Ďalej')}
            getTdProps={() => {
              return { style: { padding: 0, margin: 'auto' } };
            }}
          />
        </TableWrapper>
        <PopupButtonWrapper>
          <Button
            loading={saveLoading}
            onClick={() => this.saveExport()}
            primary
          >
            {__('Uložiť')}
          </Button>
          <Space />
          <Button loading={exportLoading} onClick={() => this.export()} primary>
            {__('Exportovať')}
          </Button>
        </PopupButtonWrapper>
      </Wrapper>
    );
  }
}

FarleskProductsExport.propTypes = {};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FarleskProductsExport);
