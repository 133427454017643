import React from 'react';
import moment from 'moment';
import tableHeaders from '../../../ContentConfig/brandPortfolioHeaders';
import BaseTable from '../../BaseTable';
import AdminAPI from '../../../AdminAPI';
import ControllBar from '../../../Components/ControllBar';
import ControlButton from '../../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper } from '../../../Components/ReusableComponents';
import ControlBarButton from '../../../Components/ControllBar/ControlBarButton';
import { connect } from '../../../Store';
import {
  __,
  FORMAT_HUMANE_DATETIME,
  formatDate,
  renderLink,
} from '../../../Utils';
import API from '../../../API';

class BrandPortfolioList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Portfólio značiek');
    this.showBulkOperations = false;
    this.accessors = ['name', 'created_date', 'updated_date'];
    this.setSorter('name');
    this.state = {
      ...this.state,
      states: [],
      lastSearch: '',
      limit: 100,
      brandIds: [],
      commonMessage: __('Všetky'),
    };
  }

  handleItemClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/brand-portfolio/${id}`);
  }

  async componentWillMount() {
    const res = await API.getBrandsAction(this.props.firm, {});
    const brands = res.fullBrands.map(item => ({
      value: item._id,
      label: item.brand,
      brand: item,
    }));

    this.setState({ brandIds: brands });
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, limit } = this.state;
      this.setState({ loading: true });

      const params = Object.assign({}, parameters, { sorter, limit });
      params.firm = this.props.firm;

      const newData = await AdminAPI.getPortfolioBrandsAction(params);
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const { brandIds } = this.state;

    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      const brandName = brandIds.find(b => b.value === item.brand);

      acumulator.push({
        name: _shapeData(
          renderLink(
            `./brand-portfolio/${item._id}`,
            `${(brandName && brandName.label) || '-'}`,
          ),
          () => this.handleItemClick(item._id),
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        updated_date: _shapeData(
          formatDate(moment(item.updated_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }

  handleCommonMessage(value, name) {
    this.setState({ commonMessage: name }, () => {
      const params = { shopId: value };
      this.fetchData(value && params);
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { commonMessage, brandIds } = this.state;

    return (
      <ControllBar
        onChange={val => this.loadSearchResult(val)}
        history={history}
        name={__('Portfólio značiek')}
      >
        <ControlButton name={commonMessage}>
          <RouteLinkWraper>
            <div onClick={() => this.handleCommonMessage(null, __('Všetky'))}>
              {__('Všetky')}
            </div>
            {brandIds.map(c => {
              return (
                <div
                  onClick={e =>
                    this.handleCommonMessage(c.value, e.target.innerHTML)
                  }
                >
                  {c.label}
                </div>
              );
            })}
          </RouteLinkWraper>
        </ControlButton>
        <ControlBarButton
          primary
          small
          onClick={() => this.handleItemClick('new')}
          icon="plus"
        >
          {__('Pridať portfólio')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BrandPortfolioList);
