import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import queryString from 'query-string';
import Icon from 'oxyrion-ui/lib/Icon';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Toggle, ToggleItem } from 'oxyrion-ui/lib/Toggle';
import InfiniteScroll from 'react-infinite-scroll-component';
import Input from 'oxyrion-ui/lib/Input';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Message from 'oxyrion-ui/lib/Message';
import Loader from 'oxyrion-ui/lib/Loader';
import { Button, Label } from 'oxyrion-ui/lib';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import Editor from '../../Components/Editor';
import AttachmentsComponent from '../../Components/AttachmentsComponent';
import { connect } from '../../Store';
import { getIdFromProps, __, renderLinkOnNewTab } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { signature } from './defaultSignature';

import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';

import AdminAPI from '../../AdminAPI';
import DateAndTimePicker from '../../Components/DateAndTimePicker';
import SearchableSelect from '../../Components/SearchableSelect';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import ImagesManager from '../ImagesManager';
import API from '../../API';
import MarketingDetailedList from '../../Components/MarketingDetailedList';
import { searchInLocalArray } from '../../Utils/ApiSearchFunctions';

const StyledInput = styled(Input)`
  width: ${rem(400)};
`;

const StyledTagInput = styled(Input)`
  width: ${rem(200)};
`;

const ErrorWrapper = styled.div``;

const SearchableSelectWrapper = styled.div`
  width: ${rem(424)};
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(10)};
  justify-content: space-between;
  width: ${rem(424)};
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  padding-left: ${rem(8)};
  padding-right: ${rem(8)};
  flex-direction: column;
  margin-bottom: ${rem(12)};
  justify-content: flex-start;
  ${({ theme }) => theme.media.l`
    flex-direction: row;
    justify-content: space-between
  `};

  .attachments-wrapper {
    width: 600px;
    max-width: 37.5rem;
  }

  .text {
    font-size: ${rem(12)};
    color: #cccccc;
    margin-left: ${rem(4)};
  }
`;

const AddImagesWrapper = styled.div`
  padding-top: ${rem(4)};
  padding-bottom: ${rem(8)};
  padding-right: ${rem(8)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledLabel = styled(Label)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
const ContactRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(12)};
  border-bottom: 1px solid #dcdada;
`;
const ContactEmail = styled.div`
  font-size: ${rem(12)};
  width: 50%;
`;

const MarketingListButton = styled.div`
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  //margin-top: ${rem(8)};
  font-size: ${rem(14)};
  .hover {
    text-decoration: underline;
  }
`;

const MarketingListButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(8)};
`;

const MenuWrapper = styled.div`
  margin: 0px;
  padding: 0px;
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  z-index: 1000;
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
  background: white;
  min-height: ${props => props.minHeight};
`;

const MarketingListHeaderWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  padding-top: ${rem(24)};
  padding-bottom: ${rem(12)};
`;

const Header = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${rem(16)};
  align-items: center;
`;
const ToList = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
`;

const CloseWrapper = styled.div`
  margin-right: ${rem(12)};
  cursor: pointer;
`;

const StyledToggle = styled(Toggle)`
  // height: ${rem(40)};
  label {
    padding: 8px 0;
    border: 1px solid #fff;
    // background-color: #50a550;
  }

  input:checked:not(:disabled) + label {
    background-color: #cccccc;
    box-shadow: 0 0.1875rem 0.75rem #aaaaaa;
    color: #000;
  };
  margin-right: ${rem(24)};
`;

const FirmInfo = styled.div`
  font-size: ${rem(12)};
  color: gray;
  margin-left: ${rem(16)};
`;

const StyledToggleItem = styled(ToggleItem)``;

const StyledSlidingPane = styled(SlidingPane)`
  z-index: 10000;
`;

const Hint = styled.div`
  font-size: 11px;
  color: #929292;
`;

const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/newsletter/{newsletterId}/attachments`;

const companyOptions = [
  { value: 'Dejmark', label: 'Dejmark' },
  { value: 'xFarby', label: 'xFarby' },
];

class NewsLetterDetail extends React.Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.state = {
      newTag: '',
      tags: [],
      loading: true,
      newsletterInfo: {
        tags: [],
        name: '',
        marketing_list: null,
        subject: '',
        scheduled_date: '',
        company: companyOptions[0],
        sender: null,
      },
      nameError: false,
      subjectError: false,
      marketingListError: false,
      companyError: false,
      showInfo: true,
      showDeletePopup: false,
      showCopyPopup: false,
      redirectId: '',
      marketingListIsOpen: false,
      marketingListContacts: [],
      limit: 100,
      offset: 0,
      selectedLang: queryString.parse(this.props.location.search).lang,
      addSender: true,
      showMarketingListPopup: false,
    };
  }

  async componentDidMount() {
    const newsletterId = getIdFromProps(this.props);
    const tags = await this.fetchTags();

    const params = queryString.parse(this.props.location.search);

    if (newsletterId !== 'new') {
      await this.fetchData(newsletterId);
    }
    this.setState({ tags, loading: false, selectedLang: params.lang || 'SK' });
  }

  getSliderSizeByScreeWidth() {
    const width = window.innerWidth;

    if (width > 1400) return 30;
    if (width > 1000) return 40;
    if (width > 800) return 60;
    return 75;
  }

  getUploadParams = ({ file }) => {
    const headers = AdminAPI.appendAuthHeaders();
    const formData = new FormData();
    formData.append('file', file);

    return {
      url: fileUploadURL.replace('{newsletterId}', getIdFromProps(this.props)),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };
  showError(error) {
    this.setState({ error });
  }

  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    const { newsletterInfo } = this.state;

    if (status === 'preparing') {
      this.setState({
        uploading: true,
      });

      window.addEventListener('beforeunload', this.handler);
    }

    if (status === 'done') {
      window.removeEventListener('beforeunload', this.handler);

      const res = await AdminAPI.getNewsletterAction(
        getIdFromProps(this.props),
      );

      newsletterInfo.attachments = res.attachments;

      this.setState(
        {
          newsletterInfo,
        },
        () => remove(),
      );
    }
  };

  async deleteFile(fullPath) {
    const { newsletterInfo } = this.state;

    try {
      const result = await AdminAPI.deleteNewsletterAttachmentAction(
        getIdFromProps(this.props),
        encodeURIComponent(fullPath),
      );

      newsletterInfo.attachments = result.files || [];

      this.setState({
        newsletterInfo,
      });
    } catch (e) {
      this.setState({
        error: __('Prílohu sa nepodarilo odstrániť'),
      });
    }
  }

  async fetchData(newsletterId) {
    const { selectedLang } = this.state;

    try {
      const res = await AdminAPI.getNewsletterAction(
        newsletterId,
        selectedLang,
      );

      this.setState({
        loading: false,
        langLoading: false,
        error: undefined,
        success: undefined,
        newsletterInfo: {
          _id: res._id,
          twin_id: res.twin_id,
          name: res.name || '',
          subject: res.subject ? res.subject : '',
          campaign: res.campaign,
          marketing_list: res.marketing_list
            ? {
                value: res.marketing_list._id,
                label: res.marketing_list.displaye_name,
                campaigns: res.marketing_list.campaigns || [],
              }
            : null,
          json_data: res.json_data && JSON.parse(res.json_data),
          scheduled_date: res.scheduled_date ? moment(res.scheduled_date) : '',
          status: res.status,
          tags: res.tags
            ? res.tags.map(t => ({
                value: t,
                label: t,
              }))
            : [],
          company: res.company
            ? {
                value: res.company,
                label: res.company,
              }
            : null,
          sender: res.sender,
          attachments: res.attachments,
        },
        addSender: res.addSender,
        addDiscountCode: res.addDiscountCode,
      });
    } catch (e) {
      this.setState({
        error: __('Newsletter sa nepodarilo načítať'),
      });
      console.log(e);
    }
  }

  async unschedule() {
    const { newsletterInfo, selectedLang } = this.state;
    try {
      const params = {};

      params.body = {
        name: newsletterInfo.name,
        marketing_list_id:
          newsletterInfo.marketing_list && newsletterInfo.marketing_list.value,
        scheduled_date: null,
        subject: newsletterInfo.subject,
        status: 'to_send',
      };

      const result = await AdminAPI.putNewsletterAction(
        newsletterInfo._id,
        selectedLang,
        params,
      );

      this.setState({
        newsletterInfo: {
          name: result.name || '',
          subject: result.subject ? result.subject : '',
          marketing_list: {
            value: result.marketing_list._id,
            label: result.marketing_list.displaye_name,
            campaigns: result.marketing_list.campaigns || [],
          },
          json_data: result.json_data && JSON.parse(result.json_data),
          scheduled_date: result.scheduled_date
            ? moment(result.scheduled_date)
            : '',
          status: result.status,
          tags: result.tags
            ? result.tags.map(t => ({
                value: t,
                label: t,
              }))
            : [],
          company: {
            value: result.company,
            label: result.company,
          },
          sender: result.sender,
        },
        showUnschedulePopup: false,
        addSender: result.addSender,
        addDiscountCode: result.addDiscountCode,
      });
    } catch (e) {
      this.setState({
        error: __('Newsletter sa nepodarilo uložiť'),
        saveLoading: false,
      });
    }
  }

  async fetchTags() {
    const { selectedLang } = this.state;
    try {
      const result = await AdminAPI.getNewslettersTagsAction(selectedLang);
      return result.tags || [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async fetchMarketingLists(query) {
    try {
      return AdminAPI.getMarketingListsAction({
        query,
        firm: this.state.selectedLang,
      }).then(res => {
        return res.items.map(item => ({
          value: item._id,
          label: item.name,
          campaigns: item.campaigns || [],
        }));
      });
    } catch (e) {
      return [];
    }
  }

  fetchMarketingListContacts() {
    const { newsletterInfo, marketingListContacts, limit, offset } = this.state;
    if (!newsletterInfo || !newsletterInfo.marketing_list) {
      return;
    }

    this.setState({
      contactsLoading: true,
    });

    const marketingListId = newsletterInfo.marketing_list.value;

    try {
      AdminAPI.getMarketingListContactsAction(marketingListId, {
        limit,
        offset,
      }).then(res => {
        if (!res.items) {
          this.setState({
            marketingListContacts: [],
            hasMoreItems: false,
          });
        } else {
          this.setState({
            marketingListContacts: marketingListContacts.concat(
              res.items.filter(c => c.active),
            ),
            hasMoreItems: res.items.length === limit,
            offset: res.next_offset,
          });
        }
      });
    } catch (e) {
      this.setState({
        error: __('Kontakty marketingového zoznamu sa nepodarilo načítať'),
        contactsLoading: false,
      });
    }
  }

  searchTags(query) {
    const { tags } = this.state;

    return tags
      ? tags
          .filter(t => t.text && t.text.includes(query))
          .map(t => ({
            value: t.id,
            label: t.text,
          }))
      : [];
  }
  async downloadFile(item) {
    return API.getNewsletterAttachmentAction(
      this.props.firm,
      getIdFromProps(this.props),
      item.fullPath,
    );
  }
  searchMarketingLists(query) {
    const { marketingLists } = this.state;

    return marketingLists
      .filter(t => t.name.includes(query))
      .map(t => ({
        value: t._id,
        label: t.name,
      }));
  }

  redirectToMarketingList() {}

  addNewTag() {
    const { newTag } = this.state;
    const { newsletterInfo } = this.state;
    const trimmed = newTag.trim();

    if (!newsletterInfo.tags) {
      newsletterInfo.tags = [];
    }

    const a = newsletterInfo.tags;

    if (trimmed && !newsletterInfo.tags.find(t => t.value === trimmed)) {
      a.push({ value: trimmed, label: trimmed });
      this.handleSearchables(a, 'tags');
      this.setState({ newTag: '' });
    }
  }

  handleNewsletterInfoChange(e, field) {
    const { newsletterInfo } = this.state;
    let { newTag } = this.state;
    if (field === 'name') {
      newsletterInfo.name = e.target.value;
    } else if (field === 'subject') {
      newsletterInfo.subject = e.target.value;
    } else if (field === 'scheduled_date') {
      newsletterInfo.scheduled_date = e;
    } else if (field === 'new_tag') {
      newTag = e.target.value;
      this.setState({ newTag });
    }
    this.setState({
      newsletterInfo,
    });
  }

  replaceSignatureData(info, company) {
    let stringified = JSON.stringify(signature);

    const { selectedLang } = this.state;

    stringified = stringified.replace(
      '{{senderName}}',
      `${info.forename} ${info.surname}`,
    );

    if (company === 'Dejmark') {
      stringified = stringified.replace(
        '{{domain}}',
        selectedLang === 'SK' ? 'www.dejmark.sk' : 'www.dejmark.cz',
      );
      stringified = stringified.replace(
        '{{dejmarkSlogan}}',
        selectedLang === 'SK'
          ? 'Dejmark - profesionáli vo svete farieb'
          : 'Dejmark - profesionálové ve světe barev a nátěrů',
      );
      stringified = stringified.replace(
        '{{footer_text}}',
        selectedLang === 'SK'
          ? 'Dejmark spol. s r.o., Priekopská; 3706/104, 036 01 Martin'
          : 'Dejmark Czech s.r.o., Poděbradská 1020/30, 190 00 Praha 9',
      );
    } else {
      stringified = stringified.replace(
        '{{domain}}',
        selectedLang === 'SK' ? 'www.xfarby.sk' : 'www.xbarvy.cz',
      );
      stringified = stringified.replace(
        '{{dejmarkSlogan}}',
        selectedLang === 'SK'
          ? 'Xfarby - X farebných možností'
          : 'Xfarby - X barevných možností',
      );
      stringified = stringified.replace('{{footer_text}}', '');
    }

    const role =
      info.crm_roles &&
      info.crm_roles.find(r => r.companyId === selectedLang) &&
      info.crm_roles.find(r => r.companyId === selectedLang).name.length
        ? info.crm_roles.find(r => r.companyId === selectedLang).name
        : 'ZADAJTE ROLU POUŽÍVATEĽA';

    stringified = stringified.replace(
      '{{nice_day_label}}',
      selectedLang === 'SK' ? 'Prajem príjemný deň' : 'Přeji příjemný den',
    );
    stringified = stringified.replace('{{senderRole}}', role);
    stringified = stringified.replace(
      '{{senderImage}}',
      info.profile_picture
        ? `${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${info.profile_picture.fullPath}`
        : '',
    );

    stringified = stringified.replace('{{senderPhone}}', info.mobile);
    stringified = stringified.replace('{{senderEmail}}', info.email);
    return stringified;
  }

  handleSearchables(e, name) {
    const { newsletterInfo } = this.state;

    newsletterInfo[name] = e;
    this.setState({
      newsletterInfo,
    });
  }

  handleSignature(e) {
    const { newsletterInfo } = this.state;

    this.editor.exportHtml(async data => {
      const { design } = data;
      const newBodyRows = design.body.rows.filter(
        dbr => dbr.values.id !== '__currentSignature',
      );

      newsletterInfo.sender = null;

      if (e) {
        const jsonSignature = JSON.parse(
          this.replaceSignatureData(e.info, newsletterInfo.company.value),
        );
        const lastElement = design.body.rows.slice(-1);
        design.body.rows = newBodyRows
          .slice(0, -1)
          .concat(jsonSignature)
          .concat(lastElement);

        newsletterInfo.sender = {
          value: e.value,
          label: e.label,
        };
      } else {
        // design.body.rows = newBodyRows;
      }

      this.setState({
        newsletterInfo,
      });

      this.editor.loadDesign(design);
    });
  }

  checkData(newsletterInfo) {
    let nameError = false;
    let subjectError = false;
    let marketingListError = false;
    let companyError = false;
    let senderError = false;
    let scheduledError = false;

    let valid = true;
    if (newsletterInfo.name.length <= 0) {
      valid = false;
      nameError = true;
    }
    if (newsletterInfo.subject.length <= 0) {
      valid = false;
      subjectError = true;
    }
    if (!newsletterInfo.marketing_list) {
      valid = false;
      marketingListError = true;
    }
    if (!newsletterInfo.company) {
      valid = false;
      companyError = true;
    }

    if (!newsletterInfo.sender) {
      valid = false;
      senderError = true;
    }

    if (newsletterInfo.scheduled_date) {
      const duration = moment.duration(
        newsletterInfo.scheduled_date.diff(moment()),
      );
      if (duration.asHours() < 2) {
        valid = false;
        scheduledError = true;
      }
    }

    this.setState({
      nameError,
      subjectError,
      marketingListError,
      companyError,
      senderError,
      scheduledError,
    });

    return valid;
  }

  async showMarketingList() {
    const { newsletterInfo, limit } = this.state;
    if (!newsletterInfo || !newsletterInfo.marketing_list) {
      return;
    }

    this.setState({
      contactsLoading: true,
    });

    const marketingListId = newsletterInfo.marketing_list.value;

    try {
      const contacts = await AdminAPI.getMarketingListContactsAction(
        marketingListId,
      );
      this.setState({
        marketingListContacts: contacts.items.filter(c => c.active),
        marketingListIsOpen: true,
        error: false,
        contactsLoading: false,
        offset: contacts.next_offset,
        hasMoreItems: contacts.items.length === limit,
      });
    } catch (e) {
      this.setState({
        error: __('Kontakty marketingového zoznamu sa nepodarilo načítať'),
        contactsLoading: false,
      });
    }
  }

  async deleteNewsletter() {
    const { newsletterInfo, selectedLang } = this.state;
    const newsletterId = newsletterInfo._id;
    try {
      await AdminAPI.deleteNewsletterAction(newsletterId);

      if (newsletterInfo.twin_id) {
        this.fetchData(newsletterInfo.twin_id);

        this.setState({
          showDeletePopup: false,
          selectedLang: selectedLang === 'SK' ? 'CZ' : 'SK',
        });
      } else {
        this.props.history.push(`/${this.props.firm}/newsletter`);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async createCopy() {
    const { showCopyPopup, newsletterInfo, selectedLang } = this.state;
    const newsletterId = newsletterInfo._id;

    try {
      const result = await AdminAPI.postNewsletterCopyAction(
        newsletterId,
        selectedLang,
      );
      this.setState({
        redirectId: result._id,
        showCopyPopup: !showCopyPopup,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async saveNewsletter(json, html, action) {
    const { newsletterInfo, selectedLang } = this.state;

    this.setState({
      saveLoading: action,
    });

    const { history } = this.props;

    const newsletterId = newsletterInfo._id;

    let result;
    try {
      const params = {};
      params.body = {
        name: newsletterInfo.name,
        marketing_list_id:
          newsletterInfo.marketing_list && newsletterInfo.marketing_list.value,
        type: 'EMAIL',
        scheduled_date:
          newsletterInfo.scheduled_date &&
          new Date(newsletterInfo.scheduled_date),
        subject: newsletterInfo.subject,
        json_data: json,
        html_data: html,
        tags: newsletterInfo.tags && newsletterInfo.tags.map(t => t.value),
        company: newsletterInfo.company.value,
        sender: newsletterInfo.sender ? newsletterInfo.sender.value : null,
        addSender: this.state.addSender,
        addDiscountCode: this.state.addDiscountCode,
        campaign_id: newsletterInfo.campaign && newsletterInfo.campaign.value,
      };
      if (newsletterId) {
        result = await AdminAPI.putNewsletterAction(
          newsletterId,
          selectedLang,
          params,
        );
      } else {
        result = await AdminAPI.postNewsletterAction(selectedLang, params);
        this.props.history.push(`/${this.props.firm}/newsletter/${result._id}`);
      }
    } catch (e) {
      this.setState({
        error: __('Newsletter sa nepodarilo uložiť'),
        saveLoading: null,
      });
    }

    if (action === 'leave') {
      history.push(`/${this.props.firm}/newsletter`);
    } else if (action === 'stay') {
      this.setState({
        success: __('Newsletter bol uložený'),
        newsletterInfo: {
          _id: result._id,
          twin_id: result.twin_id,
          name: result.name || '',
          subject: result.subject ? result.subject : '',
          marketing_list: {
            value: result.marketing_list._id,
            label: result.marketing_list.displaye_name,
            campaigns: result.marketing_list.campaigns || [],
          },
          json_data: result.json_data && JSON.parse(result.json_data),
          scheduled_date: result.scheduled_date
            ? moment(result.scheduled_date)
            : '',
          status: result.status,
          tags: result.tags
            ? result.tags.map(t => ({
                value: t,
                label: t,
              }))
            : [],
          company: {
            value: result.company,
            label: result.company,
          },
          sender: result.sender,
        },
        saveLoading: null,
        addSender: result.addSender,
      });
    } else if (action === 'publish') {
      try {
        await AdminAPI.sendNewsletterEmailAction(newsletterId, selectedLang);

        if (!newsletterInfo.scheduled_date) {
          newsletterInfo.status = 'sent';
        } else {
          newsletterInfo.status = 'scheduled';
        }

        this.setState({
          success: newsletterInfo.scheduled_date
            ? __('Newsletter je pripravený na odoslanie')
            : __('Newsletter bol odoslaný'),
          saveLoading: null,
        });
      } catch (e) {
        this.setState({
          error: __('Newsletter sa nepodarilo odoslať/pripraviť na odoslanie'),
          saveLoading: null,
        });
      }
    } else if (action === 'test') {
      try {
        await AdminAPI.sendNewsletterTestEmailAction(
          newsletterId,
          selectedLang,
        );

        this.setState({
          success: __('Newsletter bol odoslaný'),
          saveLoading: null,
        });
      } catch (e) {
        this.setState({
          error: __('Testovací newsletter sa nepodarilo odoslať'),
          saveLoading: null,
        });
      }
    }
  }

  async handleLangChange(lang) {
    const { selectedLang, newsletterInfo } = this.state;
    const newsletterId = newsletterInfo._id;

    if (selectedLang === lang) {
      return;
    }
    const disabled =
      newsletterInfo.status === 'sent' || newsletterInfo.status === 'scheduled';
    if (!disabled) {
      if (!this.checkData(newsletterInfo)) {
        alert('Pred zmenou jazyka je potrebné vyplniť všetky povinné polia');
        return;
      }
    }

    this.setState(
      {
        selectedLang: lang,
        langLoading: true,
      },
      async () => {
        this.editor.exportHtml(async data => {
          const { design, html } = data;
          await this.saveNewsletter(JSON.stringify(design), html, 'nothing');

          if (!newsletterInfo.twin_id) {
            const copy = await AdminAPI.postNewsletterCopyAction(
              newsletterId,
              lang,
              { forCompanyId: lang, isLangCopy: true },
            );

            window.location.href = `./${copy._id}?lang=${lang}`;
          } else {
            window.location.href = `./${newsletterInfo.twin_id}?lang=${lang}`;
          }
        });
      },
    );
  }

  fetchUsers(query) {
    try {
      return API.getAllUsersAction({
        q: query,
        limit: 10,
      }).then(res => {
        return res.users.map(item => ({
          value: item._id,
          label: item.display_name,
          info: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const {
      showInfo,
      showDeletePopup,
      newsletterInfo,
      showUnschedulePopup,
      selectedLang,
      langLoading,
    } = this.state;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Newsletter')}>
          {langLoading ? (
            <Loader color="white" />
          ) : (
            <StyledToggle
              style={{
                width: rem(325),
              }}
              name="type"
              disabled={!newsletterInfo._id}
            >
              <StyledToggleItem
                style={{ padding: rem(0) }}
                checked={selectedLang === 'SK'}
                value="SK"
                label={__('SK')}
                onClick={() => this.handleLangChange('SK')}
              />
              <StyledToggleItem
                checked={selectedLang === 'CZ'}
                value="CZ"
                label={__('CZ')}
                onClick={() => this.handleLangChange('CZ')}
              />
            </StyledToggle>
          )}
          <ControlBarButton
            onClick={() =>
              this.setState({
                showInfo: !showInfo,
              })
            }
            primary
            small
            icon={showInfo ? 'arrow-top' : 'arrow-down'}
          >
            {showInfo ? __('Skryť detail') : __('Zobraziť detail')}
          </ControlBarButton>
          <ControlBarButton
            onClick={() => this.createCopy()}
            primary
            small
            icon="plus"
          >
            {__('Vytvoriť kópiu')}
          </ControlBarButton>
          <ControlBarButton
            onClick={() =>
              this.setState({
                showDeletePopup: !showDeletePopup,
              })
            }
            small
            danger
            icon="delete"
          >
            {__('Zmazať')}
          </ControlBarButton>
          {newsletterInfo.status === 'scheduled' && (
            <ControlBarButton
              onClick={() =>
                this.setState({
                  showUnschedulePopup: !showUnschedulePopup,
                })
              }
              small
              danger
              icon="close"
            >
              {__('Zrušiť odoslanie')}
            </ControlBarButton>
          )}
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      newsletterInfo,
      nameError,
      subjectError,
      marketingListError,
      companyError,
      senderError,
      showInfo,
      error,
      loading,
      success,
      saveLoading,
      showDeletePopup,
      showCopyPopup,
      redirectId,
      showImagesSelector,
      showUnschedulePopup,
      marketingListIsOpen,
      marketingListContacts,
      contactsLoading,
      showMarketingListPopup,
      scheduledError,
      selectedLang,
    } = this.state;

    let days = -1;

    if (newsletterInfo.scheduled_date) {
      const duration = moment.duration(
        newsletterInfo.scheduled_date.diff(moment()),
      );
      days = duration.asDays();
    }

    const disabled =
      newsletterInfo.status === 'sent' || newsletterInfo.status === 'scheduled';

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <StyledSlidingPane
          style={{ zIndex: 10000 }}
          onRequestClose={() =>
            this.setState({
              marketingListIsOpen: false,
              marketingListContacts: [],
            })
          }
          from="left"
          isOpen={marketingListIsOpen}
          width="500px"
        >
          <MarketingListHeaderWrapper>
            <ToList>
              <Icon name="arrow-left" color="#ee6500" />
              {newsletterInfo.marketing_list && (
                <Header onClick={() => this.redirectToMarketingList()}>
                  {renderLinkOnNewTab(
                    `../lists/${newsletterInfo.marketing_list.value}`,
                    __('Prejst na detail marketingového zoznamu'),
                  )}
                </Header>
              )}
            </ToList>
            <CloseWrapper
              onClick={() =>
                this.setState({
                  marketingListIsOpen: false,
                  contactsLoading: false,
                  marketingListContacts: [],
                  offset: 0,
                })
              }
            >
              <Icon name="close" color="#ee6500" size="l" />
            </CloseWrapper>
          </MarketingListHeaderWrapper>
          {marketingListIsOpen && (
            <ScrollWrapper id="scrollableDiv">
              <InfiniteScroll
                dataLength={this.state.marketingListContacts.length}
                next={() => this.fetchMarketingListContacts()}
                height={window.innerHeight}
                scrollThreshold={0.8}
                // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                hasMore={this.state.hasMoreItems}
                loader={
                  <LoaderWrapper>
                    <Loader size="xl" />
                  </LoaderWrapper>
                }
                scrollableTarget="scrollableDiv"
              >
                <MenuWrapper>
                  {!marketingListContacts.length && loading && (
                    <LoaderWrapper>
                      <Loader size="xl" />
                    </LoaderWrapper>
                  )}
                  {marketingListContacts.map(c => {
                    return (
                      <ContactRow>
                        {renderLinkOnNewTab(
                          ` ${process.env.REACT_APP_CRM_DOMAIN}/${this.props.firm}/contacts/${c._id}`,
                          `${c.name} ${c.surname}`,
                        )}
                        <ContactEmail>{c.email}</ContactEmail>
                      </ContactRow>
                    );
                  })}
                </MenuWrapper>
              </InfiniteScroll>
            </ScrollWrapper>
          )}
        </StyledSlidingPane>
        {this.renderControlBar()}
        {error && (
          <ErrorWrapper>
            <Message message={error} error />
          </ErrorWrapper>
        )}
        {success && (
          <ErrorWrapper>
            <Message message={success} />
          </ErrorWrapper>
        )}
        <PopUpWrapper
          display={showUnschedulePopup}
          small
          onClose={() =>
            this.setState({
              showUnschedulePopup: false,
            })
          }
        >
          <ConfirmDialog
            message={__(
              'Naozaj si prajete zrušiť automatické odoslanie newslettera?',
            )}
            onDismiss={() =>
              this.setState({
                showUnschedulePopup: false,
              })
            }
            onConfirm={() => this.unschedule()}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showMarketingListPopup}
          message={__('Zoznam marketingových zoznamov')}
          onClose={() =>
            this.setState({
              showMarketingListPopup: false,
            })
          }
        >
          <MarketingDetailedList
            firm={selectedLang}
            onListSelect={e => {
              this.handleSearchables(e, 'marketing_list');

              this.setState({
                showMarketingListPopup: false,
              });
            }}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showDeletePopup}
          small
          onClose={() =>
            this.setState({
              showDeletePopup: false,
            })
          }
        >
          <ConfirmDialog
            message={__('Naozaj si prajete zmazať newsletter?')}
            onDismiss={() =>
              this.setState({
                showDeletePopup: false,
              })
            }
            onConfirm={() => this.deleteNewsletter()}
          />
        </PopUpWrapper>
        <PopUpWrapper
          display={showImagesSelector}
          message={__('Zoznam obrázkov')}
          onClose={() =>
            this.setState({
              showImagesSelector: false,
            })
          }
        >
          {showImagesSelector && <ImagesManager />}
        </PopUpWrapper>
        <PopUpWrapper
          display={showCopyPopup}
          small
          onClose={() =>
            this.setState({
              showCopyPopup: false,
            })
          }
        >
          <ConfirmDialog
            message={__('Bola vytvorená kópia, prajete si ju otvoriť?')}
            onDismiss={() =>
              this.setState({
                showCopyPopup: false,
              })
            }
            onConfirm={() => {
              this.props.history.push(`./${redirectId}`);
              this.setState({
                showCopyPopup: false,
                loading: true,
              });
              this.fetchData(redirectId);
            }}
          />
        </PopUpWrapper>
        <AnimatedFormMessageWrapper display={showInfo}>
          <Wrapper>
            <SubWrapper>
              <StyledLabel> {__('Názov*')}</StyledLabel>
              <StyledInput
                placeholder={__('Zadajte názov')}
                disabled={disabled}
                onChange={e => this.handleNewsletterInfoChange(e, 'name')}
                value={this.state.newsletterInfo.name}
                error={nameError}
              />
              <StyledLabel> {__('Marketingový zoznam*')}</StyledLabel>
              <SearchableSelectWrapper>
                <SearchableSelect
                  disabled={disabled}
                  value={newsletterInfo.marketing_list}
                  loadOptions={query => {
                    return this.fetchMarketingLists(query);
                  }}
                  keyProps={this.state.selectedLang}
                  placeholder={__('Zvoľte marketingové zoznamy')}
                  name={newsletterInfo.marketing_list}
                  error={marketingListError}
                  handleOnChange={e =>
                    this.handleSearchables(e, 'marketing_list')
                  }
                />
              </SearchableSelectWrapper>

              <MarketingListButtonWrapper>
                <div>
                  {!disabled && (
                    <MarketingListButton
                      onClick={() =>
                        this.setState({
                          showMarketingListPopup: true,
                        })
                      }
                    >
                      {__('Vybrať zo zoznamu')}
                    </MarketingListButton>
                  )}
                </div>
                {newsletterInfo.marketing_list && (
                  <MarketingListButton onClick={() => this.showMarketingList()}>
                    {__('Zobraziť adresy')}
                    {contactsLoading && <Loader size="xs" />}
                  </MarketingListButton>
                )}
              </MarketingListButtonWrapper>

              <StyledLabel> {__('Kampaň')}</StyledLabel>
              <SearchableSelectWrapper>
                <SearchableSelect
                  disabled={
                    disabled ||
                    !newsletterInfo.marketing_list ||
                    newsletterInfo.marketing_list.campaigns.length === 0
                  }
                  value={newsletterInfo.campaign}
                  loadOptions={query => {
                    return searchInLocalArray(
                      newsletterInfo.marketing_list &&
                        newsletterInfo.marketing_list.campaigns,
                      'name',
                      query,
                    );
                  }}
                  keyProps={
                    newsletterInfo.marketing_list &&
                    newsletterInfo.marketing_list.value
                  }
                  placeholder={__('Zvoľte kampaň')}
                  name={newsletterInfo.marketing_list}
                  error={marketingListError}
                  handleOnChange={e => this.handleSearchables(e, 'campaign')}
                />
              </SearchableSelectWrapper>
              {newsletterInfo.marketing_list &&
                newsletterInfo.marketing_list.campaigns.length === 0 && (
                  <Hint>
                    {__(
                      'K zvolenému marketingovému zoznamu neprishlúcha žiadna kampaň',
                    )}
                  </Hint>
                )}

              <Label>
                <Checkbox
                  checked={this.state.addDiscountCode}
                  disabled={disabled || !newsletterInfo.campaign}
                  onChange={() =>
                    this.setState({
                      addDiscountCode: !this.state.addDiscountCode,
                    })
                  }
                />
                {__('Odoslať zľavový kód')}
              </Label>
              {this.state.addDiscountCode && (
                <Hint>
                  {__(
                    'Kód bude vložený na miesto označené ako {{discount_code}}',
                  )}
                </Hint>
              )}
              <StyledLabel> {__('Tagy')}</StyledLabel>
              <SearchableSelectWrapper>
                <SearchableSelect
                  disabled={disabled}
                  value={newsletterInfo.tags}
                  loadOptions={query => {
                    return this.searchTags(query);
                  }}
                  keyProps={newsletterInfo.tags && newsletterInfo.tags.length}
                  isMulti
                  placeholder={__('Zvoľte tagy')}
                  handleOnChange={e => this.handleSearchables(e, 'tags')}
                />
              </SearchableSelectWrapper>
              <TagWrapper>
                <StyledTagInput
                  disabled={disabled}
                  placeholder={__('Zadajte nový tag')}
                  onChange={e => this.handleNewsletterInfoChange(e, 'new_tag')}
                  value={this.state.newTag}
                />
                <Button
                  disabled={disabled}
                  primary
                  small
                  onClick={() => this.addNewTag()}
                >
                  {__('Pridať tag')}
                </Button>
              </TagWrapper>
            </SubWrapper>
            <SubWrapper>
              <StyledLabel> {__('Predmet emailu*')}</StyledLabel>
              <StyledInput
                disabled={disabled}
                placeholder={__('Zadajte predmet emailu')}
                onChange={e => this.handleNewsletterInfoChange(e, 'subject')}
                value={this.state.newsletterInfo.subject}
                error={subjectError}
              />
              <StyledLabel>
                {__('Dátum a čas automatického odoslania')}
              </StyledLabel>
              <DateAndTimePicker
                disabled={disabled}
                value={newsletterInfo.scheduled_date}
                maxWidth={400}
                error={scheduledError}
                onChange={e =>
                  this.handleNewsletterInfoChange(e, 'scheduled_date')
                }
                minTime={days < 0 ? moment().add(2, 'hours') : null}
                maxTime={
                  days < 0 ? moment().set({ hour: 23, minute: 59 }) : null
                }
                timePlaceHolder="Čas"
                datePlaceHolder="Dátum"
                minDate={moment()}
                timeIntervals={60}
              />
              <StyledLabel> {__('Odosielateľ*')}</StyledLabel>
              <SearchableSelectWrapper>
                <SearchableSelect
                  disabled={disabled}
                  value={newsletterInfo.sender}
                  loadOptions={query => {
                    return this.fetchUsers(query);
                  }}
                  error={senderError}
                  placeholder={__('Odosielateľ')}
                  name={newsletterInfo.sender}
                  handleOnChange={e => this.handleSignature(e)}
                />
              </SearchableSelectWrapper>
              <Label>
                <Checkbox
                  disabled={disabled}
                  checked={this.state.addSender}
                  onChange={() =>
                    this.setState({
                      addSender: !this.state.addSender,
                    })
                  }
                />
                {__('Pridať odosielateľa k príjemcom')}
              </Label>

              <StyledLabel> {__('Spoločnosť*')}</StyledLabel>
              <SearchableSelectWrapper>
                <SearchableSelect
                  disabled={disabled}
                  value={newsletterInfo.company}
                  loadOptions={() => {
                    return companyOptions;
                  }}
                  placeholder={__('Zvoľte spoločnosť')}
                  name={newsletterInfo.company}
                  error={companyError}
                  handleOnChange={e => this.handleSearchables(e, 'company')}
                />
              </SearchableSelectWrapper>
            </SubWrapper>
            {newsletterInfo._id ? (
              <AttachmentsComponent
                attachments={newsletterInfo.attachments}
                history={this.props.history}
                selectedLang=""
                onFileDelete={fullPath => this.deleteFile(fullPath)}
                onFileDownload={item => this.downloadFile(item)}
                getUploadParams={(e, view) => this.getUploadParams(e, view)}
                onError={fileError => this.showError(fileError)}
                handleChangeStatus={e => this.handleChangeStatus(e)}
              />
            ) : (
              <SubWrapper className="attachments-wrapper">
                <StyledLabel> {__('Prílohy :')}</StyledLabel>
                <div className="text">
                  {__('Je možné nahrať až po uložení')}
                </div>
              </SubWrapper>
            )}
          </Wrapper>
        </AnimatedFormMessageWrapper>
        <AddImagesWrapper>
          <FirmInfo>
            {this.state.selectedLang === 'SK'
              ? __('Máte zvolenú Slovenskú verziu newslettera')
              : __('Máte zvolenú Českú verziu newslettera')}
          </FirmInfo>
          <CustomButton
            onClick={() =>
              this.setState({
                showImagesSelector: true,
              })
            }
          >
            <Icon name="view_module" />
            {__('Prehľadávať obrázky')}
          </CustomButton>
        </AddImagesWrapper>
        <Editor
          jsonData={newsletterInfo.json_data}
          sendDate={newsletterInfo.scheduled_date}
          disabled={disabled}
          keyProps={newsletterInfo._id}
          setEditor={e => {
            this.editor = e;
          }}
          lang={selectedLang}
          key={newsletterInfo._id}
          loadingField={saveLoading}
          checkData={() => this.checkData(newsletterInfo)}
          newsletterInfo={newsletterInfo}
          onSave={(json, html, action) =>
            this.saveNewsletter(json, html, action)
          }
        />
      </React.Fragment>
    );
  }
}

NewsLetterDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(NewsLetterDetail);
