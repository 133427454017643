import { initStore } from 'react-waterfall';

import initialState from './state';
import actionsCreators from './actions';

const config = {
  initialState,
  actions: actionsCreators,
};

export const { Provider, connect, actions } = initStore(config);
