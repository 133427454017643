import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Img from 'react-image';
import Dropzone from 'react-dropzone-uploader';
import {
  Button,
  Loader,
  Icon,
  Input,
  Label,
  Checkbox,
  Message,
} from 'oxyrion-ui/lib';
import API from '../../API';
import TableV3 from '../../Components/TableV3';
import AdminAPI from '../../AdminAPI';
import { __, getIdFromProps, formatBytes } from '../../Utils';
import { connect } from '../../Store';
import LanguageSwitch from '../../Components/LanguageSwitch';
import {
  LoaderWrapper,
  DHeader,
  P,
  ClickableIconWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import ConfirmDialog from '../../Components/ConfirmDialog';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import DatePicker from '../../Components/DatePicker';
import SearchableSelect from '../../Components/SearchableSelect';
import { LANGS } from '../../ContentConfig/languagesArray';

const acceptedMimeTypesViewImage = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/svg+xml',
];
const baseURL = process.env.REACT_APP_IMAGES_BASE_PATH;

const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/products-packages/{productsPackageId}/image`;

const Image = styled(Img)`
  max-width: ${rem(200)};
`;

const ImageInfo = styled.div`
  margin-left: ${rem(8)};
  font-size: ${rem(12)};
`;
const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${rem(12)};
`;
const Row = styled.div`
  margin-bottom: ${rem(15)};
  display: flex;
`;
const ImageRow = styled(Row)`
  border-bottom: ${rem(1)} solid #eee;
  padding: ${rem(12)};
`;

const ImageInfoWrapper = styled.div`
  margin-left: ${rem(24)};
`;

const ImageDelete = styled.div`
  font-size: 12px;
  color: red;
  display: flex;
  cursor: pointer;
  margin-left: ${rem(8)};
`;

const Wrapper = styled.div``;

const StyledDatePicker = styled(DatePicker)`
  max-width: 90%;
`;
const DatePickerWrapper = styled.div`
  max-width: ${rem(150)};
  margin-right: ${rem(10)};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(300)};
`;

const MainLabel = styled(Label)`
  margin-right: ${rem(10)};
  font-size: ${rem(16)};
`;
const SearchableSelectWrapper = styled.div`
  width: ${rem(500)};
`;

const AddNewRow = styled.div`
  margin-top: ${rem(20)};
  display: flex;
  flex-direction: row;
`;

const PlusWrapper = styled.div`
  height: ${rem(34)};
  width: ${rem(34)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const PublicationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledInput = styled(Input)`
  width: ${rem(300)};
`;
const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1550px) {
    flex-direction: row;
  }
`;

const Space = styled.div`
  width: ${rem(20)};
  margin-bottom: ${rem(20)};
`;
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(12)};
`;
const Halfer = styled.div`
  width: 100%;
  margin-bottom: ${rem(20)};

  @media only screen and (min-width: 1550px) {
    width: auto;
    margin-right: ${rem(16)};
  }
`;
const ProductsPackageInfoWrapper = styled.div`
  padding: ${rem(8)};
  display: flex;
  flex-direction: row;
`;
const ProductsPackageInfoLabel = styled.div`
  margin-right: ${rem(8)};
  font-weight: bold;
`;
const ProductsPackageInfoValue = styled.div`
  margin-right: ${rem(24)};
`;

class ProductsPackageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      productsPackage: {
        _id: null,
        name: { SK: '', CZ: '' },
        meta_description: { SK: '', CZ: '' },
        meta_title: { SK: '', CZ: '' },
        published_from: moment(new Date()),
        published_to: moment(new Date()),
        publication: [],
        products: [],
        image: null,
      },
      showConfirmDialog: false,
      saveLoading: false,
      selectedLang: 'SK',
    };
  }

  async componentDidMount() {
    const id = getIdFromProps(this.props);
    if (id === 'new') {
      this.setState({
        loading: false,
      });
    } else {
      await this.fetchPackage();
    }
  }
  async onImageDelete(fullPath) {
    const { productsPackage } = this.state;
    try {
      await AdminAPI.deleteProductsPackageImageAction(
        productsPackage._id,
        encodeURIComponent(fullPath),
      );
      productsPackage.image = null;
      this.setState({
        productsPackage,
        success: __('Obrázok sa podarilo úspešne odstrániť'),
      });
    } catch (e) {
      this.setState({
        error: __('Obrázok sa nepodarilo odstrániť'),
      });
    }
  }

  async onImageUpload({ file }) {
    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();

    formData.append('file', file);

    return {
      url: fileUploadURL.replace(
        '{productsPackageId}',
        this.state.productsPackage._id,
      ),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  }

  setTempProduct(p) {
    this.setState({
      productToAddVariant: p || null,
    });
  }
  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    const { productsPackage } = this.state;
    if (status === 'done') {
      const { image } = await AdminAPI.getProductsPackageImageAction(
        productsPackage._id,
      );
      productsPackage.image = image;
      this.setState(productsPackage);
    }
  };
  async fetchPackage() {
    const id = getIdFromProps(this.props);
    this.setState({
      loading: true,
    });
    const productsPackage = await AdminAPI.getProductsPackageAction(id);
    // productsPackage.published_to = this.changePublishedToDate(
    //   productsPackage.published_to,
    // );
    if (productsPackage.products && productsPackage.products.length > 0) {
      const productIds = productsPackage.products.map(p => p._id);

      const { products: fullProducts } = await API.getProductsAction(
        this.props.firm,
        {
          productIds,
        },
      );
      productsPackage.products = productsPackage.products.map(p => {
        const fullProduct = fullProducts.find(fp => fp._id === p._id);

        return Object.assign(p, {
          name: fullProduct.Description,
          price: fullProduct.Unit_Price,
          price_vat: fullProduct.Unit_Price_VAT,
        });
      });
    }
    this.setState({
      productsPackage,
      loading: false,
    });
  }
  fetchProducts(query) {
    try {
      return API.getProductsAction(this.props.firm, {
        q: query,
        limit: 100,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: item.Description,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }
  deleteProduct(id) {
    const { productsPackage } = this.state;
    productsPackage.products = productsPackage.products.filter(
      p => p._id !== id,
    );
    this.setState({
      productsPackage,
    });
  }
  addProductToPackage() {
    const { productToAddVariant, productsPackage } = this.state;
    if (productToAddVariant === null || productToAddVariant === undefined) {
      return;
    }
    const tempProduct = {
      _id: productToAddVariant.product._id,
      name: productToAddVariant.product.Description,
      price: productToAddVariant.product.Unit_Price,
      price_vat: productToAddVariant.product.Unit_Price_VAT,
      discount: 0,
    };
    if (!productsPackage.products) {
      productsPackage.products = [];
    }

    productsPackage.products.push(tempProduct);

    this.setState({
      productsPackage,
      productToAddVariant: null,
    });
  }
  changeProductDiscount(id, discount) {
    const { productsPackage } = this.state;
    productsPackage.products.find(p => p._id === id).discount = discount;
    this.setState({
      productsPackage,
    });
  }
  tableBackgroundColor(index) {
    if (index % 2 === 0) {
      return 'white';
    }
    return '#eee';
  }
  async deletePackage() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteProductsPackageAction(getIdFromProps(this.props));
      history.push(`/${firm}/products-packages`);
    } catch (e) {
      this.setState({
        error: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }
  redirectToPackage(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/products-packages/${id}`);
  }
  changePublishedToDate(publishedDate) {
    let publishedTo = new Date(publishedDate);
    publishedTo = moment(publishedTo)
      .utcOffset(0)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toDate()
      .toISOString();
    return publishedTo;
  }
  async savePackage() {
    const { productsPackage } = this.state;
    this.setState({ saveLoading: true });
    if (!this.validateTextInputs()) {
      this.setState({
        error: __('Musíte vyplniť všetky polia'),
        saveLoading: false,
      });
      return;
    }

    if (!this.validateProductsDiscounts()) {
      this.setState({
        error: __('Zľavy pri produktoch musia byť v rozmedzí 0 až 100%'),
        saveLoading: false,
      });
      return;
    }
    // const newProductsPackage = this.formatDatesToISO(productsPackage);
    // productsPackage.published_to = this.changePublishedToDate(
    //   productsPackage.published_to,
    // );
    const params = {};
    params.body = {
      package: productsPackage,
    };

    let createdProductsPackage;

    try {
      if (productsPackage._id) {
        createdProductsPackage = await AdminAPI.putProductsPackageAction(
          productsPackage._id,
          params,
        );
      } else {
        createdProductsPackage = await AdminAPI.postProductsPackageAction(
          params,
        );
      }
      this.setState({
        productsPackage: createdProductsPackage,
        saveLoading: false,
        error: false,
        success: __('Balík úspešne vytvorený'),
      });
      this.redirectToPackage(createdProductsPackage._id);
    } catch (e) {
      this.setState({
        error: __('Balík sa nepodarilo vytvoriť'),
        success: false,
        saveLoading: false,
      });
    }
  }
  calculateProductDiscountPrice(price, discount) {
    let calculatedPrice = price * ((100 - discount) / 100);
    calculatedPrice = Math.round(calculatedPrice * 100) / 100;
    return calculatedPrice;
  }

  calculateTotalDefaultPrice(products) {
    let price = 0;
    let currency = '';
    products.forEach(p => {
      price += p.price_vat.amount;
    });
    if (products && products.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      currency = products[0].price_vat.currency;
    }
    price = Math.round(price * 100) / 100;
    return `${price.toString()} ${currency}`;
  }
  calculateTotalDiscountPrice(products) {
    let price = 0;
    let currency = '';
    products.forEach(p => {
      price += p.price_vat.amount * ((100 - p.discount) / 100);
    });
    if (products && products.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      currency = products[0].price_vat.currency;
    }
    price = Math.round(price * 100) / 100;
    return `${price.toString()} ${currency}`;
  }
  calculateTotalPriceDifference(products) {
    let currency = '';
    let priceBefore = 0;
    products.forEach(p => {
      priceBefore += p.price_vat.amount;
    });
    let priceAfter = 0;
    products.forEach(p => {
      priceAfter += p.price_vat.amount * ((100 - p.discount) / 100);
    });
    if (products && products.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      currency = products[0].price_vat.currency;
    }
    let priceDifference = priceBefore - priceAfter;
    priceDifference = Math.round(priceDifference * 100) / 100;
    return `${priceDifference.toString()} ${currency}`;
  }
  normalizeProducts(products) {
    return products.map(p => {
      return { _id: p._id, discount: p.discount };
    });
  }
  validateTextInputs() {
    const { productsPackage } = this.state;
    let filled = true;
    LANGS.forEach(lang => {
      if (productsPackage.name[lang] === '') {
        filled = false;
      }
      if (productsPackage.meta_title[lang] === '') {
        filled = false;
      }
      if (productsPackage.meta_description[lang] === '') {
        filled = false;
      }
    });
    return filled;
  }
  validateProductsDiscounts() {
    const { productsPackage } = this.state;
    let valid = true;
    productsPackage.products.forEach(p => {
      if (p.discount > 100 || p.discount < 0) {
        valid = false;
      }
    });
    return valid;
  }
  changeAttribute(attribute, value) {
    const newPackage = Object.assign({}, this.state.productsPackage);
    const { selectedLang } = this.state;
    switch (attribute) {
      case 'name':
        newPackage.name[selectedLang] = value;
        break;
      case 'title':
        newPackage.meta_title[selectedLang] = value;
        break;
      case 'description':
        newPackage.meta_description[selectedLang] = value;
        break;
      default:
        break;
    }
    this.setState({
      productsPackage: newPackage,
    });
  }
  changePublicationLang(l) {
    let langs = [];
    if (this.state.productsPackage.publication !== undefined) {
      langs = [...this.state.productsPackage.publication];
    }
    if (langs.includes(l)) {
      langs = langs.filter(lang => {
        return lang !== l;
      });
    } else {
      langs.push(l);
    }
    this.setState({
      productsPackage: { ...this.state.productsPackage, publication: langs },
    });
  }
  createColumns() {
    return [
      {
        accessor: '_id',
        Header: () => <DHeader>{__('Číslo výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },
      {
        accessor: 'name',
        Header: () => <DHeader>{__('Názov výrobku')}</DHeader>,
        minWidth: 200,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'price_vat',
        Header: () => <DHeader>{__('Cena pred zľavou')}</DHeader>,
        Cell: props => {
          return (
            <P>
              {Math.round(props.value.amount * 100) / 100}{' '}
              {props.value.currency}
            </P>
          );
        },
      },
      {
        accessor: '_id',
        Header: () => <DHeader>{__('Zľava v %')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <Input
                style={{ height: rem(30) }}
                onChange={e =>
                  this.changeProductDiscount(props.value, e.target.value)
                }
                key={props.value}
                value={props.original.discount}
                placeholder={__('Zlava')}
                type="number"
                min="0"
                max="100"
              />
            </P>
          );
        },
      },
      {
        accessor: '',
        Header: () => <DHeader>{__('Cena po zľave bez DPH')}</DHeader>,
        Cell: props => {
          return (
            <P>
              {this.calculateProductDiscountPrice(
                props.original.price.amount,
                props.original.discount,
              )}{' '}
              {props.value.price.currency}
            </P>
          );
        },
      },
      {
        accessor: '',
        Header: () => <DHeader>{__('Cena po zľave s DPH')}</DHeader>,
        Cell: props => {
          return (
            <P>
              {this.calculateProductDiscountPrice(
                props.original.price_vat.amount,
                props.original.discount,
              )}{' '}
              {props.value.price.currency}
            </P>
          );
        },
      },
      {
        accessor: '_id',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <ClickableIconWrapper
              onClick={() => this.deleteProduct(props.value)}
            >
              <Icon
                name="close"
                size="l"
                color={
                  props.row._index === this.state.selected ? 'white' : 'black'
                }
              />
            </ClickableIconWrapper>
          );
        },
        width: 70,
      },
    ];
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Balík produktov')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }
  render() {
    const {
      loading,
      productsPackage,
      deleteLoading,
      saveLoading,
      showConfirmDialog,
      deleteError,
      selectedLang,
      productToAddVariant,
      error,
      success,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <Wrapper>
          <PopUpWrapper
            display={showConfirmDialog}
            small
            onClose={() => this.setState({ showConfirmDialog: false })}
          >
            <ConfirmDialog
              message={__('Naozaj si prajete odstrániť produktový balíček?')}
              onDismiss={() => this.setState({ showConfirmDialog: false })}
              onConfirm={() => this.deletePackage()}
              error={deleteError}
              loading={deleteLoading}
            />
          </PopUpWrapper>
          <ContentWrapper>
            <Halfer style={{ marginTop: rem(12) }}>
              <ColumnWrapper>
                <Label>{__('Publikovaný: ')}</Label>
                <PublicationWrapper>
                  {LANGS.map(l => (
                    <Label key={l}>
                      <Space />
                      <Space />
                      <Checkbox
                        checked={
                          productsPackage.publication &&
                          productsPackage.publication.indexOf(l) > -1
                        }
                        onChange={() => this.changePublicationLang(l)}
                      />
                      {l}
                    </Label>
                  ))}
                </PublicationWrapper>
              </ColumnWrapper>
              <ColumnWrapper>
                <LanguageSwitch
                  onChange={lang => {
                    this.setState({ selectedLang: lang });
                    return true;
                  }}
                  activeId={selectedLang}
                />

                <InputWrapper>
                  <StyledLabel>{__('Názov balíčka*')}</StyledLabel>
                  <StyledInput
                    value={
                      productsPackage.name && productsPackage.name[selectedLang]
                    }
                    placeholder={__('Zadajte výstižný názov')}
                    onChange={e => this.changeAttribute('name', e.target.value)}
                    // this.setState({
                    //   productsPackage: { name: e.target.value },
                    // })
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Meta title*')}</StyledLabel>
                  <StyledInput
                    value={
                      productsPackage.name &&
                      productsPackage.meta_title[selectedLang]
                    }
                    placeholder={__('Meta title balíčka')}
                    onChange={e =>
                      this.changeAttribute('title', e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('Meta description*')}</StyledLabel>
                  <StyledInput
                    value={
                      productsPackage.meta_description &&
                      productsPackage.meta_description[selectedLang]
                    }
                    placeholder={__('Meta description balíčka')}
                    onChange={e =>
                      this.changeAttribute('description', e.target.value)
                    }
                  />
                </InputWrapper>
              </ColumnWrapper>
              <ColumnWrapper>
                <DatePickerWrapper>
                  <StyledLabel>{__('Publikovaný od')}</StyledLabel>
                  <StyledDatePicker
                    placeholderText={__('Od')}
                    dateFormat="dd DD.MM.YYYY"
                    selected={moment(productsPackage.published_from)}
                    minDate={moment()}
                    onChange={e =>
                      this.setState({
                        productsPackage: {
                          ...this.state.productsPackage,
                          published_from: e,
                        },
                      })
                    }
                  />
                </DatePickerWrapper>
                <DatePickerWrapper>
                  <StyledLabel>{__('Publikovaný do')}</StyledLabel>
                  <StyledDatePicker
                    placeholderText={__('Od')}
                    dateFormat="dd DD.MM.YYYY"
                    selected={moment(productsPackage.published_to)}
                    minDate={moment(productsPackage.published_from)}
                    onChange={e => {
                      this.setState({
                        productsPackage: {
                          ...this.state.productsPackage,
                          published_to: e,
                        },
                      });
                      console.log(e);
                    }}
                  />
                </DatePickerWrapper>
              </ColumnWrapper>
              <InputWrapper style={{ width: rem(500) }}>
                {productsPackage && productsPackage.image && (
                  <StyledLabel>{__('Obrázok')}</StyledLabel>
                )}
                <ImageUploadWrapper>
                  {productsPackage && productsPackage.image && (
                    <ImageRow>
                      <Image
                        src={[
                          `${baseURL}/images/250x0/${productsPackage.image.fullPath}`,
                        ]}
                        loader={<Loader color="#70af00" />}
                      />
                      <ImageInfoWrapper>
                        <Label>{__('Typ súboru')}</Label>
                        <ImageInfo>{productsPackage.image.mimeType}</ImageInfo>
                        <Space />

                        <Label>{__('Veľkosť súboru')}</Label>
                        <ImageInfo>
                          {formatBytes(productsPackage.image.size)}
                        </ImageInfo>
                        <Space />
                        <ImageDelete
                          onClick={() =>
                            this.onImageDelete(
                              productsPackage._id,
                              productsPackage.image.fullPath,
                            )
                          }
                        >
                          {__('Odstrániť ')}
                        </ImageDelete>
                      </ImageInfoWrapper>
                    </ImageRow>
                  )}
                  {productsPackage && productsPackage._id && (
                    <Dropzone
                      files={
                        productsPackage.image ? [productsPackage.image] : []
                      }
                      getUploadParams={e => this.onImageUpload(e)}
                      onChangeStatus={this.handleChangeStatus}
                      accept={acceptedMimeTypesViewImage.join(',')}
                      inputContent={
                        productsPackage && productsPackage.image
                          ? __('Nahrať iný obrázok')
                          : __('Nahrať obrázok')
                      }
                      canCancel={false}
                      initialFiles={
                        productsPackage.image ? [productsPackage.image] : []
                      }
                      styles={{
                        inputLabel: { color: '#ee6500' },
                      }}
                    />
                  )}
                </ImageUploadWrapper>
              </InputWrapper>
              <Row
                style={{
                  justifyContent: 'space-between',
                  maxWidth: 720,
                  marginBottom: rem(40),
                  marginTop: rem(12),
                }}
              >
                <ColumnWrapper>
                  <Button
                    loading={saveLoading}
                    onClick={() => {
                      this.savePackage();
                    }}
                    primary
                  >
                    {getIdFromProps(this.props) === 'new'
                      ? __('Uložiť')
                      : __('Upraviť')}
                  </Button>
                </ColumnWrapper>
              </Row>
            </Halfer>
            <Halfer style={{ marginTop: rem(12) }} />

            <Halfer
              style={{
                width: '100%',
              }}
            >
              <Row>
                <MainLabel>{__('Pridávanie produktov do balíčka')}</MainLabel>
              </Row>
              <AddNewRow>
                <SearchableSelectWrapper>
                  <SearchableSelect
                    value={productToAddVariant}
                    loadOptions={query => this.fetchProducts(query)}
                    placeholder={__('Zadajte kód alebo názov tovaru')}
                    name={productToAddVariant}
                    isClearable={false}
                    handleOnChange={e => this.setTempProduct(e)}
                  />
                </SearchableSelectWrapper>
                <PlusWrapper onClick={() => this.addProductToPackage()}>
                  <Icon name="plus" size="l" />
                </PlusWrapper>
              </AddNewRow>
              <Space />
              <Space />
              <TableV3
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: {
                        background: this.tableBackgroundColor(rowInfo.index),
                        cursor: 'pointer',
                      },
                    };
                  }
                  return {};
                }}
                columns={this.createColumns()}
                minWidth={10}
                loading={loading}
                noDataText={__('Žiadne produkty')}
                data={productsPackage.products}
                getTdProps={() => {
                  return { style: { padding: 0, margin: 'auto' } };
                }}
                getTheadThProps={(state, rowInfo, column) => {
                  if (column && column.sortable) {
                    return {
                      onClick: () => {},
                    };
                  }
                  return {};
                }}
                className="-highlight -striped"
              />
              <Space />
              <ProductsPackageInfoWrapper>
                <ProductsPackageInfoLabel>
                  {__('Pôvodná celková cena: ')}
                </ProductsPackageInfoLabel>
                <ProductsPackageInfoValue>
                  {this.calculateTotalDefaultPrice(productsPackage.products)}
                </ProductsPackageInfoValue>
                <ProductsPackageInfoLabel>
                  {__('Nová celková cena so zľavou: ')}
                </ProductsPackageInfoLabel>
                <ProductsPackageInfoValue>
                  {this.calculateTotalDiscountPrice(productsPackage.products)}
                </ProductsPackageInfoValue>
                <ProductsPackageInfoLabel>
                  {__('Zľava: ')}
                </ProductsPackageInfoLabel>
                <ProductsPackageInfoValue>
                  {this.calculateTotalPriceDifference(productsPackage.products)}
                </ProductsPackageInfoValue>
              </ProductsPackageInfoWrapper>
            </Halfer>
          </ContentWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

ProductsPackageDetail.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
ProductsPackageDetail.defaultProps = {
  firm: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductsPackageDetail);
