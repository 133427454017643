import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { Loader, Select } from 'oxyrion-ui/lib';
import { Link } from 'react-router-dom';
import { __, translate } from '../../Utils';
import Filter from '../ProductCatalog/Components/Filter';
import CatalogPager from '../ProductCatalog/Components/CatalogPager';
import API from '../../API';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import CombinedFilter from '../ProductCatalog/Components/CombinedFilter';
import DataTable from '../../Components/TableV2';
import AsyncSearchInput from '../ProductCatalog/Components/AsyncSearchInput';

const MainWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

const MainContainer = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: ${props => (props.marginTop ? rem(45) : 0)};
  margin-left: ${props =>
    props.filterDisplayed ? rem(props.filterSize + 5) : '8px'};
  overflow: hidden;
`;

const FilterWrapper = styled.div`
  max-width: ${props => rem(props.maxWidth)};
  position: fixed;
  overflow: auto;
  height: ${props =>
    props.customFilterHeight
      ? props.customFilterHeight
      : props.defaultFilterHeight};
  margin-top: ${rem(45)};
  margin-bottom: ${rem(300)};
  background: linear-gradient(to bottom, #f0f0f0, #f8f8f8, #f3f1f0);
`;

const Header = styled.div`
  background: #f0f0f0;
  justify-content: flex-start;
  position: fixed;
  height: ${rem(45)};
  z-index: 9;
  display: flex;
  align-items: center;
  width: ${props =>
    props.customHeaderWidth ? props.customHeaderWidth : '100%'};
`;

const Pager = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: ${rem(50)};
  margin-bottom: ${rem(4)};
  margin-top: ${rem(4)};
  margin-right: ${rem(36)};
`;

const ProductsNotFound = styled.div`
  height: ${rem(50)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${rem(30)};
  background: #f0f0f0;
  color: red;
  border-radius: ${rem(2)};
`;

const LevelHeader = styled.div`
  cursor: pointer;
  display: flex;
  font-size: ${rem(12)};
  margin-left: ${rem(8)};
  font-weight: 700;
  margin-bottom: ${rem(10)};
  font-weight: ${props => (props.selected ? 'bold' : 'regular')};
  color: ${props => (props.selected ? '##ee6500' : '#434245')};
  &:hover {
    text-decoration: underline;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
`;

const SwitchButton = styled.div`
  margin-right: ${rem(8)};
  background: white;
  height: ${rem(32)};
  width: ${rem(145)};
  padding-left: ${rem(8)};
  padding-right: ${rem(8)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem(12)};
  background: ${props => (props.selected ? '#ee6500' : 'white')};
  color: ${props => (props.selected ? 'white' : '#434245')};
  border-radius: ${rem(2)};
  &:hover {
    box-shadow: 0 0 ${rem(8)} ${rem(0)} #e0e0e0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const CustomContentWrapper = styled.div`
  /* margin-top: ${rem(8)}; */
  height: ${props => (props.height ? rem(props.height - 320) : 'auto')};
  padding: ${rem(8)};
`;

const SearchAndButtonsWrapper = styled.div`
  display: flex;
`;

const PageSizeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 94px;
  right: ${rem(100)};
  top: ${rem(10)};
  font-size: ${rem(14)};
  color: #434245;
`;

const PageSizeTitle = styled.div`
  margin-right: ${rem(8)};
`;

const CustomHeaderContent = styled.div``;

class BaseLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.pageSizes = [25, 50, 75];
    this.defaultLimit = 25;
    this.state = {
      checkedFilter: [],
      defaultFilter: [],
      doNotLoadFilters: false,
      maxFilterWidth: 140,
      showFilter: true,
      showPager: true,
      showSearch: true,
      buttonsSwitch: [],
      actualPage: 1,
      limit: 25,
      offset: 0,
      previousLevel: 0,
      loading: true,
      items: [],
      tableItems: [],
      filtersShowOnly: [],
      filters: [],
      notFoundMessage: '',
      defaultSearchText: '',
      showHeader: true,
    };
  }

  componentWillUnmount() {
    const {
      actualPage,
      offset,
      nextOffset,
      checkedFilter,
      total,
      searchText,
    } = this.state;
    this.saveActualData(
      actualPage,
      offset,
      nextOffset,
      checkedFilter,
      total,
      searchText,
    );
  }

  onPageClick(page) {
    const { limit } = this.state;

    this.setState(
      {
        actualPage: page,
        loading: true,
        filtersDisabled: true,
      },
      () => {
        this.fetchData({
          offset: (page - 1) * limit,
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
    );
  }
  onIncrease(page) {
    const { nextOffset } = this.state;

    this.setState(
      {
        actualPage: page,
        loading: true,
        filtersDisabled: true,
      },
      () => {
        this.fetchData({
          offset: nextOffset,
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
    );
  }
  onDecrease(page) {
    const { limit, offset } = this.state;

    this.setState(
      {
        actualPage: page,
        loading: true,
        filtersDisabled: true,
      },
      () => {
        this.fetchData({
          offset: offset - limit,
        });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
    );
  }

  setLocalStorage(name, value, constructorName) {
    localStorage.setItem(constructorName + name, value);
  }

  getLocalStorage(name, constructorName) {
    return localStorage.getItem(constructorName + name);
  }

  saveActualData(
    actualPage,
    offset,
    nextOffset,
    checkedFilter,
    total,
    searchText,
  ) {
    this.setLocalStorage('actualPage', actualPage, this.state.title);

    this.setLocalStorage(
      'checkedFilter',
      JSON.stringify(checkedFilter),
      this.state.title,
    );
    this.setLocalStorage('offset', offset, this.state.title);
    this.setLocalStorage('nextOffset', nextOffset, this.state.title);
    this.setLocalStorage('total', total, this.state.title);
    this.setLocalStorage('searchText', searchText, this.state.title);
    this.setLocalStorage('scrollPosition', window.scrollY, this.state.title);
  }

  loadActualData() {
    return {
      actualPage: this.getLocalStorage('actualPage', this.state.title),
      checkedFilter: this.getLocalStorage('checkedFilter', this.state.title),
      offset: this.getLocalStorage('offset', this.state.title),
      nextOffset: this.getLocalStorage('nextOffset', this.state.title),
      total: this.getLocalStorage('total', this.state.title),
      searchText: this.getLocalStorage('searchText', this.state.title),
      scrollPosition: this.getLocalStorage('scrollPosition', this.state.title),
    };
  }
  async fetchCombinedFilter() {
    this.setState({
      combinedFiltersLoading: true,
    });

    try {
      const result = await API.getCatalogCombinedFiltersAction(this.props.firm);

      this.setState({
        combinedFilters: result.combined_filters || [],
        combinedFiltersLoading: false,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        combinedFiltersLoading: false,
      });
    }
  }
  async fetchFilter(firstLoad = false) {
    const { checkedFilter, doNotLoadFilters } = this.state;

    if (doNotLoadFilters) {
      return;
    }

    this.setState({
      filtersLoading: true,
    });

    try {
      const params = {};
      if (
        checkedFilter &&
        checkedFilter.find(c => c.id === 'type') &&
        checkedFilter.find(c => c.id === 'type').values &&
        checkedFilter.find(c => c.id === 'type').values.length
      ) {
        params.productTypes = checkedFilter.find(c => c.id === 'type').values;
      }
      const result = await API.getCatalogFiltersAction(this.props.firm, params);

      if (firstLoad) {
        const defaultCheckedFilter = result.filters
          .filter(f => f.product_types.length === 3)
          .map(r => {
            return {
              id: r.product_value._id,
              values: r.values.filter(v => v.default_value).map(v => v.label),
            };
          });
        this.setState({
          checkedFilter: defaultCheckedFilter,
        });
      }

      this.setState({
        filters: result.filters || [],
        filtersLoading: false,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        filtersLoading: false,
      });
    }
  }

  handleFilterChange(id, value, logic = 'OR') {
    const { checkedFilter, filters } = this.state;

    if (!checkedFilter.find(c => c.id === id)) {
      checkedFilter.push({
        id,
        values: [],
      });
    }

    if (checkedFilter.find(c => c.id === id).values.indexOf(value) > -1) {
      checkedFilter
        .find(c => c.id === id)
        .values.splice(
          checkedFilter.find(c => c.id === id).values.indexOf(value),
          1,
        );
    } else {
      checkedFilter.find(c => c.id === id).values.push(value);
    }
    let newCheckedFilters = checkedFilter;

    checkedFilter.find(c => c.id === id).logic = logic;

    if (id === 'type') {
      newCheckedFilters = checkedFilter.map(c => {
        if (
          filters.find(f => f.product_value._id === c.id) &&
          filters.find(f => f.product_value._id === c.id).product_type_filter
        ) {
          return c;
        }

        return {
          id: c.id,
          values: [],
        };
      });
    }

    this.setState(
      {
        checkedFilter: newCheckedFilters,
      },

      () => {
        window.clearTimeout(this.currentFetchTimeout);
        this.currentFetchTimeout = window.setTimeout(() => {
          this.setState(
            {
              filtersDisabled: true,
              loading: true,
              actualPage: 1,
              offset: 0,
              nextOffset: null,
            },
            () => {
              this.fetchData();
              this.fetchFilter();
            },
          );
        }, 800);
      },
    );
  }

  handleSearchOnChange(value) {
    this.setState(
      {
        searchText: value,
      },
      () => {
        this.setState({ loading: true, filtersDisabled: true }, () => {
          this.fetchData();
        });
      },
    );
  }

  handleCombinedFilterChange(
    id,
    value,
    logic = 'OR',
    type = '',
    isNode = false,
    level,
  ) {
    let { checkedFilter, previousLevelFilter, previousLevel } = this.state;

    if (level < previousLevel && previousLevelFilter) {
      const combined = checkedFilter.filter(f => f.combined);
      const others = checkedFilter.filter(f => !f.combined);

      combined.slice(0, level - 1);
      previousLevel = level;
      checkedFilter = combined.slice(0, level).concat(others);
    }

    if (type === 'checkbox') {
      this.handleFilterChange(id, value, logic);
      return;
    }

    if (!checkedFilter.find(c => c.id === id)) {
      checkedFilter.push({
        id,
        values: [],
        combined: true,
      });
    }

    if (!isNode || type === 'select') {
      if (checkedFilter.find(c => c.id === id).values.indexOf(value) > -1) {
        checkedFilter.find(c => c.id === id).values = [];
      } else {
        checkedFilter.find(c => c.id === id).values = [value];
      }
    } else if (
      checkedFilter.find(c => c.id === id).values.indexOf(value) > -1
    ) {
      checkedFilter
        .find(c => c.id === id)
        .values.splice(
          checkedFilter.find(c => c.id === id).values.indexOf(value),
          1,
        );
    } else {
      checkedFilter.find(c => c.id === id).values.push(value);
    }

    checkedFilter.find(c => c.id === id).logic = logic;

    if (level > previousLevel) {
      previousLevelFilter = [...checkedFilter];
      previousLevel = level;
    }

    this.setState(
      {
        checkedFilter,
        previousLevelFilter,
        previousLevel,
      },

      () => {
        window.clearTimeout(this.currentFetchTimeout);
        this.currentFetchTimeout = window.setTimeout(() => {
          this.setState(
            {
              filtersDisabled: true,
              loading: true,
              actualPage: 1,
              offset: 0,
              nextOffset: null,
            },
            () => {
              this.fetchData();
            },
          );
        }, 800);
      },
    );
  }

  renderCustomHeaderContent() {
    return <div />;
  }

  render() {
    const {
      items,
      tableItems,
      checkedFilter,
      filters,
      actualPage,
      loading,
      total,
      limit,
      filtersLoading,
      filtersDisabled,
      buttonsSwitch,
      notFoundMessage,
      combinedFilters,
      combinedFiltersLoading,
      defaultFilter,
      tableHeight,
      sorter,
      showFilter,
      showPager,
      showSearch,
      defaultSearchText,
      customHeaderWidth,
      customFilterHeight,
      filtersShowOnly,
      showHeader,
    } = this.state;

    return (
      <MainContainer>
        {showHeader && (
          <Header customHeaderWidth={customHeaderWidth}>
            <SearchAndButtonsWrapper>
              {showSearch && (
                <AsyncSearchInput
                  defaultText={defaultSearchText}
                  loadOptions={query => this.handleSearchOnChange(query)}
                />
              )}
              <SwitchWrapper>
                {buttonsSwitch
                  .filter(
                    b =>
                      b.rolesExcluded &&
                      b.rolesExcluded.indexOf(this.props.user.role) === -1,
                  )
                  .map(b => {
                    return (
                      <StyledLink to={b.url}>
                        <SwitchButton
                          selected={this.props.location.pathname.includes(
                            b.url.replace('.', ''),
                          )}
                        >
                          {b.label}
                        </SwitchButton>
                      </StyledLink>
                    );
                  })}
              </SwitchWrapper>
            </SearchAndButtonsWrapper>
            {this.state.showPager && (
              <PageSizeWrapper>
                <PageSizeTitle>{__('Na stranu')}</PageSizeTitle>

                <Select
                  size="xs"
                  iconRight
                  style={{ height: rem(28), borderRadius: rem(2) }}
                  onChange={e =>
                    this.setState(
                      {
                        limit: e.target.value,
                        loading: true,
                        actualPage: 1,
                        offset: 0,
                        nextOffset: null,
                      },
                      () =>
                        this.fetchData({
                          offset: 0,
                        }),
                    )
                  }
                >
                  {this.pageSizes.map(s => (
                    <option selected={s === this.state.limit} value={s}>
                      {s}
                    </option>
                  ))}
                </Select>
              </PageSizeWrapper>
            )}
            <CustomHeaderContent>
              {this.renderCustomHeaderContent()}
            </CustomHeaderContent>
          </Header>
        )}

        <MainWrapper>
          {showFilter && (
            <FilterWrapper
              maxWidth={this.state.maxFilterWidth}
              customFilterHeight={customFilterHeight}
              defaultFilterHeight={rem(window.innerHeight - 98)}
            >
              <LevelHeader
                onClick={() =>
                  this.setState(
                    {
                      checkedFilter: [...defaultFilter] || [],
                    },
                    () => {
                      this.setState(
                        {
                          filtersDisabled: true,
                          loading: true,
                          actualPage: 1,
                          offset: 0,
                          nextOffset: null,
                        },
                        () => {
                          this.fetchData();
                        },
                      );
                    },
                  )
                }
                selected={checkedFilter.length === defaultFilter.length}
              >
                {__('Všetky')}
              </LevelHeader>
              <CombinedFilter
                data={combinedFilters}
                loading={combinedFiltersLoading}
                filtersShowOnly={filtersShowOnly}
                disabled={false}
                firm={this.props.firm}
                onFilterChange={(id, e, logic, type, isNode, level) =>
                  this.handleCombinedFilterChange(
                    id,
                    e,
                    logic,
                    type,
                    isNode,
                    level,
                  )
                }
                checkedValues={checkedFilter}
              />

              <Filter
                data={filters.map(f => {
                  return {
                    title: translate(this.props.firm, f.translations, f.name),
                    id: f.product_value._id,
                    logic: f.logic,
                    values: f.values.map(v => {
                      return {
                        value: v.label,
                        label: v.label,
                        id: v.value,
                        translations: v.translations || [],
                      };
                    }),
                  };
                })}
                loading={filtersLoading}
                disabled={filtersDisabled}
                firm={this.props.firm}
                onFilterChange={(id, e, logic) =>
                  this.handleFilterChange(id, e, logic)
                }
                checkedValues={checkedFilter}
              />
            </FilterWrapper>
          )}
          <div>{/* <Line /> */}</div>

          <Wrapper
            filterDisplayed={this.state.showFilter}
            filterSize={this.state.maxFilterWidth}
            marginTop={showHeader}
          >
            <AnimatedFormMessageWrapper display={!loading} maxHeight="none">
              {items.length === 0 && tableItems.length === 0 && (
                <ProductsNotFound>{notFoundMessage}</ProductsNotFound>
              )}

              {items.length > 0 && (
                <React.Fragment>
                  {showPager && (
                    <Pager>
                      <CatalogPager
                        actualPage={actualPage}
                        onPageUp={() => this.onIncrease(actualPage + 1)}
                        onPageDown={() => this.onDecrease(actualPage - 1)}
                        onPageClick={page => this.onPageClick(page)}
                        total={total}
                        limit={limit}
                      />
                    </Pager>
                  )}
                  <CustomContentWrapper>
                    {this.renderItemsList()}
                  </CustomContentWrapper>
                  {showPager && (
                    <Pager>
                      <CatalogPager
                        actualPage={actualPage}
                        onPageUp={() => this.onIncrease(actualPage + 1)}
                        onPageDown={() => this.onDecrease(actualPage - 1)}
                        onPageClick={page => this.onPageClick(page)}
                        total={total}
                        limit={limit}
                      />
                    </Pager>
                  )}
                </React.Fragment>
              )}

              {tableItems.length > 0 && (
                <React.Fragment>
                  {/* {showPager && (
                    <Pager>
                      <CatalogPager
                        actualPage={actualPage}
                        onPageUp={() => this.onIncrease(actualPage + 1)}
                        onPageDown={() => this.onDecrease(actualPage - 1)}
                        onPageClick={page => this.onPageClick(page)}
                        total={total}
                        limit={limit}
                      />
                    </Pager>
                  )} */}
                  <CustomContentWrapper>
                    <DataTable
                      noDataText={__('Nenašli sa žiadne dáta')}
                      minWidth={10}
                      data={tableItems}
                      columns={
                        this.createColumns ? this.createColumns(sorter) : []
                      }
                      pageSize={tableItems.length}
                      style={{
                        maxHeight: tableHeight || 'auto',
                        border: 'unset',
                      }}
                      getTdProps={(state, rowInfo) => {
                        return {
                          style: {
                            cursor: 'pointer',
                            padding: 0,
                            margin: 'auto',
                          },
                          onClick: () =>
                            this.handleTdClick
                              ? this.handleTdClick(rowInfo.original._id)
                              : () => {
                                  return {};
                                },
                        };
                      }}
                      getTheadThProps={(state, rowInfo, column) => {
                        if (column && column.sortable) {
                          return {
                            onClick: e => {
                              this.handleSorter(e.target.innerHTML);
                            },
                          };
                        }
                        return {};
                      }}
                      getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                          return {
                            onClick: () => {},
                            style: {
                              cursor: 'pointer',
                            },
                          };
                        }
                        return {};
                      }}
                      className="-highlight -striped"
                    />
                  </CustomContentWrapper>
                  {showPager && (
                    <Pager>
                      <CatalogPager
                        actualPage={actualPage}
                        onPageUp={() => this.onIncrease(actualPage + 1)}
                        onPageDown={() => this.onDecrease(actualPage - 1)}
                        onPageClick={page => this.onPageClick(page)}
                        total={total}
                        limit={limit}
                      />
                    </Pager>
                  )}
                </React.Fragment>
              )}
            </AnimatedFormMessageWrapper>
            {loading && (
              <LoaderWrapper style={{ marginTop: rem(100) }}>
                <Loader size="xl" />
              </LoaderWrapper>
            )}
          </Wrapper>
        </MainWrapper>
      </MainContainer>
    );
  }
}

export default BaseLayout;
