import React from 'react';
import BaseTable from '../BaseTable';
import moment from 'moment';
import API from '../../API';
import tableHeaders from '../../ContentConfig/colourCards';
import { __, formatPrice, formatDate, renderLink } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';

class ColourCard extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Vzorkovníky');
    this.accessors = [
      'name',
      'countOfColors',
      'product_nav',
      'price_nav',
      'created_date',
    ];
    this.showBulkOperations = false;
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      lastSearch: '',
    };
  }

  handleColorCardOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/colourCards/${id}`);
  }

  async loadSearchRrsult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearch: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearch: value.toLowerCase() });
    }
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, selectedCustomer, lastSearch } = this.state;
      const { firm } = this.props;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }
      const newData = await API.getColorcardsAction(firm, params);
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(`./colourCards/${item._id}`, `${item.name || '-'}`),
          () => this.handleColorCardOnClick(item._id),
        ),
        countOfColors: _shapeData(item.shades ? item.shades.length : 0),
        product_nav: _shapeData(item.product_name),
        price_nav: _shapeData(
          item.product_price && formatPrice(item.product_price),
        ),
        created_date: _shapeData(
          item.created_date ? formatDate(moment(item.created_date)) : '',
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar
        history={history}
        name={__('Zoznam vzorkovníky')}
        defaultValue={this.state.lastSearchValue}
        onChange={val => this.loadSearchRrsult(val)}
      >
        {/* <CheckAccess
          operation="postColorCardAction"
          Component={ */}
        <ControlBarButton
          small
          primary
          basic
          onClick={() => this.handleAddNewClick()}
        >
          {__('Vytvoriť vzorkovník')}
        </ControlBarButton>
        {/* }
        /> */}
      </ControllBar>
    );
  }

  handleAddNewClick() {
    const { history, firm } = this.props;
    history.push(`/${firm}/colourCards/new`);
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ColourCard);
