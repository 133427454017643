import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { rem } from 'polished';
import Img from 'react-image';
import {
  Button,
  Message,
  Input,
  Label,
  Loader,
  Textarea,
  Checkbox,
} from 'oxyrion-ui/lib';
import productsCanals from '../../ContentConfig/productsCanals';
import { connect } from '../../Store';
import {
  getIdFromProps,
  __,
  fetchCodelistByQuery,
  getYears,
  getTranslatedData,
  transDataDecode,
  formatBytes,
} from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  ButtonRelativeWrapperLeft,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import LanguageSwitch from '../../Components/LanguageSwitch';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SettingsBox from '../../Components/SettingsBox';
import API from '../../API';
import SearchableSelect from '../../Components/SearchableSelect';
import ProductSelector from '../../Components/ProductSelector';
import ShadeSelector from '../../Components/ShadeSelector';
import MetaDescriptionTextArea from '../../Components/MetaDescriptionTextArea';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const Image = styled(Img)`
  max-width: ${rem(230)};
`;

const ImageInfo = styled.div`
  margin-left: ${rem(8)};
  font-size: ${rem(12)};
`;

const ImageInfoWrapper = styled.div`
  margin-left: ${rem(24)};
`;

const ImageDelete = styled.div`
  font-size: 12px;
  color: red;
  display: flex;
  cursor: pointer;
  margin-left: ${rem(8)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding-left: ${rem(20)};
  max-width: ${rem(500)};
  margin-bottom: ${rem(20)};
  flex-direction: column;
  ${({ theme }) => theme.media.l`
 flex-direction: row;
`};
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
`;

const ImageRow = styled(Row)`
  border-bottom: ${rem(1)} solid #eee;
  padding: ${rem(12)};
`;

const StyledInput = styled(Input)`
  width: ${rem(330)};
`;

const CustomTextArea = styled(Textarea)`
  width: 96%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  // margin-right: 16px;
  align-items: center;
  justify-content: center;
`;

const ChannelName = styled.div`
  font-size: 14px;
`;

const acceptedMimeTypesViewImage = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/svg+xml',
];

const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/references/{referenceId}/images`;

const years = getYears(2000, moment().year());

class FilterDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      types: [],
      categories: [],
      brands: [],
      showAddProduct: false,
      surfaces: [],
      activeLang: 'SK',
      data: {
        name: '',
        surfaces: [],
        objects: [],
        trans: [],
      },
    };

    this.handler = ev => {
      ev.preventDefault();
      return (ev.returnValue = __(
        'Prebieha nahrávanie súboru, naozaj chcete odísť?',
      ));
    };
  }

  componentWillMount() {
    this.loadData();
  }

  getUploadParams = ({ file }) => {
    const headers = AdminAPI.appendAuthHeaders();
    const { activeLang } = this.state;

    const formData = new FormData();

    formData.append('file', file);
    formData.append('firm', activeLang);

    return {
      url: fileUploadURL.replace('{referenceId}', this.state.data._id),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };

  getImageForLang(files, lang, hasSame, type) {
    if (!files) {
      return null;
    }
    const langLocal = hasSame ? 'SK' : lang;

    const images = files.filter(i => i.lang === langLocal);

    const image = images.find(i => i.type === type);

    if (image) {
      return image.value;
    }
    return null;
  }

  async loadData(invokeLoader) {
    if (invokeLoader) {
      this.setState({
        loading: true,
      });
    }
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      this.setState({
        loading: true,
      });
      await this.fetchReferenceData(id);
    }
  }

  searchYear(query) {
    return years
      .filter(u => u.toString().includes(query))
      .map(u => ({
        value: u,
        label: u,
      }));
  }

  fetchLocalProducts(query) {
    const { data } = this.state;

    if (!data.products) {
      return [];
    }
    return data.products
      .filter(u => u.name.toUpperCase().includes(query.toUpperCase()))
      .map(res => ({
        value: res._id,
        label: res.name,
      }));
  }

  async fetchReferenceData(id) {
    try {
      const data = await AdminAPI.getReferenceAction(id);

      this.setState({
        data,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Referencia sa nenašla'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async deleteReference() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteReferenceAction(getIdFromProps(this.props));
      history.push(`/${firm}/references`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri mazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  checkData(data) {
    let isValid = true;

    if (!data.name || data.name.length === 0) {
      isValid = false;
    }

    if (!data.year) {
      isValid = false;
    }

    return isValid;
  }

  async saveAndSendEmailData() {
    await this.saveData(false, false);
    const { data } = this.state;

    try {
      await API.sendReferenceForAprroveAction(this.props.firm, data._id, {
        status: 'approved',
      });
      data.status = 'approved';

      this.setState({
        success: __('Dáta uložené a odoslaný email o spracovaní referencie'),
        data,
      });
    } catch (e) {
      this.setState({
        error: __('Nepodarilo sa odoslať informáciu o spracovaní referencie'),
        saveLoading: false,
      });
    }
  }

  async saveData(checkFile = false, showSucces = true) {
    const { data } = this.state;

    this.setState({
      saveLoading: true,
    });

    if (this.checkData(data, checkFile)) {
      try {
        const params = {};
        params.body = {
          name: data.name,
          description: data.description,
          description_short: data.description_short,
          meta_title: data.meta_title,
          meta_description: data.meta_description,
          trans: data.trans || [],
          images: data.images || [],
          publication: data.publication,
          surfaces: data.surfaces || [],
          objects: data.objects || [],
          country: data.country,
          year: Number(data.year),
          city: data.city,
        };

        if (data.products) {
          params.body.products = data.products.map(u => ({
            _id: u._id,
            name: u.name,
            published: u.published,
            shades: u.shades && u.shades,
          }));
        }

        if (getIdFromProps(this.props) === 'new') {
          const result = await AdminAPI.postReferencesAction(params);
          this.props.history.push(
            `/${this.props.firm}/references/${result._id}`,
          );
          this.setState({
            data: result,
            success: showSucces && __('Dáta uložené'),
            saveLoading: false,
          });
        } else {
          const result = await AdminAPI.putReferenceAction(
            getIdFromProps(this.props),
            params,
          );
          this.setState({
            data: result,
            success: showSucces && __('Dáta uložené'),
            saveLoading: false,
          });
        }
      } catch (e) {
        this.setState({
          error: __('Dáta sa nepodarilo uložiť'),
          saveLoading: false,
        });
      }
    } else {
      this.setState({
        error: __('Nie sú vyplnené všetky potrebné hodnoty'),
        saveLoading: false,
      });
    }
  }

  removeProduct(id) {
    const { data } = this.state;

    data.products = data.products.filter(u => u._id !== id);

    this.setState({
      data,
    });
  }

  handleProductPublicationChange(e, id) {
    const { data } = this.state;

    data.products.find(u => u._id === id).published = e.target.checked;

    this.setState({
      data,
    });
  }

  async handleChange(field, value) {
    const { data } = this.state;

    if (field === 'surfaces' || field === 'objects') {
      data[field] = value ? value.map(v => v.value) : [];
    } else if (field === 'country' || field === 'year') {
      data[field] = value ? value.value : '';
    } else {
      data[field] = value;
    }

    this.setState({ data, error: false });
  }

  handleLocalChange(field, lang, value) {
    const { data } = this.state;

    const newData = Object.assign(
      data,
      transDataDecode(field, data.trans, value, lang),
    );

    this.setState({
      data: newData,
      error: false,
    });
  }

  addProduct(e) {
    const { data } = this.state;

    if (!data.products) {
      data.products = [];
    }

    data.products.push({
      _id: e.value,
      name: e.label,
      published: true,
      shades: [],
    });

    this.setState({
      data,
    });
  }

  handleProductToAddChange(e) {
    let { productToAdd } = this.state;

    productToAdd = e;

    this.setState({ productToAdd });
  }

  handleProductToAddShade(e) {
    let { productToShadeAdd } = this.state;

    productToShadeAdd = e;

    this.setState({ productToShadeAdd });
  }

  removeShadeFromProduct(colorCode, productId, index) {
    const { data } = this.state;

    data.products.find(u => u._id === productId).shades.splice(index, 1);

    this.setState({
      data,
    });
  }

  handleShadeSelect(e, productId) {
    const { data } = this.state;

    if (!data.products.find(u => u._id === productId).shades) {
      data.products.find(u => u._id === productId).shades = [];
    }

    data.products.find(u => u._id === productId).shades.push(e.raw);

    this.setState({
      data,
    });
  }

  handleProductChange(value) {
    const { data } = this.state;

    if (!data.product) {
      data.product = {};
    }

    data.product = value;

    this.setState({
      data,
      error: false,
    });
  }

  handlePublicationChange(value) {
    const { data, activeLang } = this.state;

    if (!data.publication) {
      data.publication = {};
    }

    if (!data.publication[activeLang]) {
      data.publication[activeLang] = [];
    }

    if (data.publication[activeLang].includes(value)) {
      data.publication[activeLang].splice(
        data.publication[activeLang].indexOf(value),
        1,
      );
    } else {
      data.publication[activeLang].push(value);
    }

    this.setState({
      data,
      error: false,
    });
  }

  handleTitleImageChange(fullPath) {
    const { data, activeLang } = this.state;

    data.images = data.images.map(i => {
      if (i.firm === activeLang) {
        if (i.fullPath === fullPath) {
          return Object.assign(i, { title_image: true });
        }
        return Object.assign(i, { title_image: false });
      }
      return i;
    });

    this.setState({
      data,
    });
  }

  async deleteImage(fullPath) {
    const { data } = this.state;

    try {
      const result = await AdminAPI.deleteReferenceImageAction(
        data._id,
        encodeURIComponent(fullPath),
      );

      this.setState({
        data: result,
      });
    } catch (e) {
      this.setState({
        error: __('Obrázok sa nepodarilo odtrániť'),
      });
    }
  }

  async checkShades() {
    const { data } = this.state;

    this.setState({
      checkLoading: true,
    });

    await this.saveData(false, false);

    const promises = [];
    data.products
      .filter(u => u.shades && u.shades.length)
      .forEach(product => {
        product.shades.forEach(shade => {
          promises.push(this.checkShadePrice(product, shade));
        });
      });

    const result = await Promise.all(promises);

    data.products.map(product =>
      Object.assign(product, {
        shades:
          product.shades &&
          product.shades.length &&
          product.shades.map(shade2 => {
            const toned = result.find(
              r =>
                r.catalogProductId === product._id &&
                r.shadeId === shade2.color_code,
            );

            return Object.assign(shade2, {
              canBeToned: toned && toned.prices && toned.prices.length ? 1 : 0,
            });
          }),
      }),
    );

    this.setState({
      data,
      checkLoading: false,
    });
  }

  async checkShadePrice(catalogProduct, shade) {
    try {
      const result = await API.getCatalogProductPricesAction(
        this.props.firm,
        catalogProduct._id,
        {
          shadeId: shade.color_code,
          colorCardId: shade.colorcard_id,
        },
      );

      return Object.assign(result, {
        catalogProductId: catalogProduct._id,
        shadeId: shade.color_code,
      });
    } catch (e) {
      return null;
    }
  }

  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    if (status === 'preparing') {
      this.setState({
        uploading: true,
      });

      if (getIdFromProps(this.props) === 'new') {
        cancel();
        await this.saveData();
        restart();
      }

      window.addEventListener('beforeunload', this.handler);
    }

    if (status === 'done') {
      const data = await AdminAPI.getReferenceAction(this.state.data._id);

      window.removeEventListener('beforeunload', this.handler);
      this.setState(
        {
          data,
          uploading: false,
        },
        () => remove(),
      );
    }
  };

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Referencia')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }

  render() {
    const {
      saveLoading,
      loading,
      notValidData,
      deleteError,
      deleteLoading,
      showConfirmDialog,
      error,
      data,
      success,
      activeLang,
      uploading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť referenciu?')}
            onDismiss={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.deleteReference()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
        </Wrapper>

        <ContentWrapper>
          <Row style={{ height: rem(762) }}>
            <SettingsBox addDisabled title={__('Základné informácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Názov*')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(330) }}
                    value={data.name}
                    placeholder={__('Názov')}
                    onChange={e => this.handleChange('name', e.target.value)}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Mesto')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(330) }}
                    value={data.city}
                    placeholder={__('Mesto')}
                    onChange={e => this.handleChange('city', e.target.value)}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper style={{ width: rem(350) }}>
                  <StyledLabel>{__('Krajina')}</StyledLabel>

                  <SearchableSelect
                    value={data.country}
                    loadOptions={query =>
                      fetchCodelistByQuery(query, 'countries')
                    }
                    placeholder={__('Krajina')}
                    handleOnChange={e => this.handleChange('country', e)}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper style={{ width: rem(350) }}>
                  <StyledLabel>{__('Rok*')}</StyledLabel>

                  <SearchableSelect
                    value={data.year}
                    loadOptions={query => this.searchYear(query)}
                    placeholder={__('Rok')}
                    handleOnChange={e => this.handleChange('year', e)}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper style={{ width: rem(350) }}>
                  <StyledLabel>{__('Povrch')}</StyledLabel>
                  <SearchableSelect
                    value={
                      data.surfaces &&
                      data.surfaces.map(s => ({
                        value: s,
                        label: s,
                      }))
                    }
                    loadOptions={query =>
                      fetchCodelistByQuery(query, 'povrch_ns')
                    }
                    isClearable={false}
                    isMulti
                    placeholder={__('Povrch')}
                    handleOnChange={e => this.handleChange('surfaces', e)}
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper style={{ width: rem(350) }}>
                  <StyledLabel>{__('Objekt/Priestor')}</StyledLabel>
                  <SearchableSelect
                    value={
                      data.objects &&
                      data.objects.map(s => ({
                        value: s,
                        label: s,
                      }))
                    }
                    loadOptions={query =>
                      fetchCodelistByQuery(query, 'objekt_priestor')
                    }
                    isClearable={false}
                    isMulti
                    placeholder={__('Objekt/Priestor')}
                    handleOnChange={e => this.handleChange('objects', e)}
                  />
                </InputWrapper>
              </Row>
            </SettingsBox>
            <Space />
          </Row>

          <div>
            <Row>
              <LanguageSwitch
                disabled={uploading}
                onChange={lang => {
                  this.setState({
                    activeLang: lang,
                  });
                  return true;
                }}
                activeId={activeLang}
              />
            </Row>

            <Row>
              <SettingsBox addDisabled>
                <Row>
                  <InputWrapper>
                    <StyledLabel>{`${__(
                      'Publikácia',
                    )} - ${activeLang}`}</StyledLabel>
                    <Row style={{ margin: 0 }}>
                      {productsCanals.map(c => (
                        <CheckboxWrapper>
                          <Checkbox
                            onChange={() => this.handlePublicationChange(c)}
                            checked={
                              data.publication && data.publication[activeLang]
                                ? data.publication[activeLang].includes(c)
                                : false
                            }
                          />
                          <ChannelName>{c}</ChannelName>
                          <Space />
                        </CheckboxWrapper>
                      ))}
                    </Row>
                  </InputWrapper>
                </Row>
                <Space />
                <Space />
                <Row>
                  <InputWrapper style={{ width: rem(350) }}>
                    <StyledLabel>{__('Názov v lokálnom jazyku')}</StyledLabel>
                    <StyledInput
                      style={{
                        width: rem(330),
                      }}
                      value={getTranslatedData(
                        data.name,
                        data.trans,
                        activeLang,
                        'name',
                      )}
                      placeholder={__('Názov')}
                      onChange={e =>
                        this.handleLocalChange(
                          'name',
                          activeLang,
                          e.target.value,
                        )
                      }
                    />
                  </InputWrapper>
                </Row>

                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Krátky popis')}</StyledLabel>
                    <CustomTextArea
                      onChange={e =>
                        this.handleLocalChange(
                          'description_short',
                          activeLang,
                          e.target.value,
                        )
                      }
                      value={getTranslatedData(
                        data.description_short,
                        data.trans,
                        activeLang,
                        'description_short',
                      )}
                      placeholder={__('Krátky popis')}
                    />
                  </InputWrapper>
                </Row>
                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Rozsiahly popis')}</StyledLabel>
                    <CustomTextArea
                      onChange={e =>
                        this.handleLocalChange(
                          'description',
                          activeLang,
                          e.target.value,
                        )
                      }
                      value={getTranslatedData(
                        data.description,
                        data.trans,
                        activeLang,
                        'description',
                      )}
                      placeholder={__('Rozsiahly popis')}
                    />
                  </InputWrapper>
                </Row>

                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Meta title')}</StyledLabel>
                    <CustomTextArea
                      onChange={e =>
                        this.handleLocalChange(
                          'meta_title',
                          activeLang,
                          e.target.value,
                        )
                      }
                      value={getTranslatedData(
                        data.meta_title,
                        data.trans,
                        activeLang,
                        'meta_title',
                      )}
                      placeholder={__('Meta title')}
                    />
                  </InputWrapper>
                </Row>
                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Meta description')}</StyledLabel>
                    <MetaDescriptionTextArea
                      onChange={e =>
                        this.handleLocalChange(
                          'meta_description',
                          activeLang,
                          e.target.value,
                        )
                      }
                      value={getTranslatedData(
                        data.meta_description,
                        data.trans,
                        activeLang,
                        'meta_description',
                      )}
                      placeholder={__('Meta description')}
                    />
                  </InputWrapper>
                </Row>

                <Row>
                  <InputWrapper style={{ width: rem(400) }}>
                    <StyledLabel>{__('Galéria')}</StyledLabel>

                    {data.images &&
                      data.images
                        .filter(i => i.firm === activeLang)
                        .map(image => (
                          <ImageRow>
                            <Image
                              src={[
                                `${process.env.REACT_APP_IMAGES_BASE_PATH}/images/400x0/${image.fullPath}`,
                              ]}
                              loader={<Loader color="#70af00" />}
                            />
                            <ImageInfoWrapper>
                              <Label>{__('Typ súboru')}</Label>
                              <ImageInfo>{image.mimeType}</ImageInfo>
                              <Space />

                              <Label>{__('Veľkosť súboru')}</Label>
                              <ImageInfo>{formatBytes(image.size)}</ImageInfo>
                              <Space />

                              <Label>{__('Titulný')}</Label>
                              <ImageInfo>
                                <Checkbox
                                  onChange={() =>
                                    this.handleTitleImageChange(image.fullPath)
                                  }
                                  checked={image.title_image}
                                />
                              </ImageInfo>

                              <Space />
                              <Space />
                              <ImageDelete
                                onClick={() => this.deleteImage(image.fullPath)}
                              >
                                {__('Odstrániť ')}
                              </ImageDelete>
                            </ImageInfoWrapper>
                          </ImageRow>
                        ))}

                    <Dropzone
                      files={data.images}
                      getUploadParams={e => this.getUploadParams(e)}
                      onChangeStatus={this.handleChangeStatus}
                      accept={acceptedMimeTypesViewImage.join(',')}
                      inputContent={__('Nahrať obrázky')}
                      canCancel={false}
                      initialFiles={data.images}
                      styles={{
                        inputLabel: { color: '#ee6500' },
                      }}
                    />
                  </InputWrapper>
                </Row>
              </SettingsBox>
              <Space />
            </Row>
          </div>

          {data._id && (
            <Row>
              <SettingsBox addDisabled>
                <ProductSelector
                  firm={this.props.firm}
                  usedProducts={data.products}
                  type="catalog"
                  onCatalogProductSelect={e => this.addProduct(e)}
                  removeProduct={id => this.removeProduct(id)}
                  handleProductPublicationChange={(e, variantId) =>
                    this.handleProductPublicationChange(e, variantId)
                  }
                  label={__('Použité produkty')}
                />

                <ShadeSelector
                  firm={this.props.firm}
                  usedProducts={data.products}
                  fetchLocalProducts={q => this.fetchLocalProducts(q)}
                  handleShadeSelect={(e, productId) =>
                    this.handleShadeSelect(e, productId)
                  }
                  removeShadeFromProduct={(colorCode, productId, index) =>
                    this.removeShadeFromProduct(colorCode, productId, index)
                  }
                  checkShades={() => this.checkShades()}
                  label={__('Použité odtiene')}
                  type="catalog"
                />
              </SettingsBox>
            </Row>
          )}
        </ContentWrapper>

        <ButtonRelativeWrapperLeft>
          <Button onClick={() => this.loadData(true)} danger>
            {__('Zahodiť zmeny')}
          </Button>
          <Space />
          <Space />
          <Space />

          {data.status && data.status === 'for_approval' ? (
            <Button
              loading={saveLoading}
              onClick={() => this.saveAndSendEmailData(true)}
              primary
            >
              {__('Uložiť a odoslať informáciu o spracovaní')}
            </Button>
          ) : (
            <Button
              loading={saveLoading}
              onClick={() => this.saveData(true)}
              primary
            >
              {__('Uložiť')}
            </Button>
          )}
        </ButtonRelativeWrapperLeft>
      </React.Fragment>
    );
  }
}

FilterDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FilterDetail);
