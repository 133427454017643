import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import {
  Button,
  Loader,
  Message,
  Label,
  Input,
  Textarea,
  Select,
  Checkbox,
} from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';
import API from '../../API';
import SearchableSelect from '../../Components/SearchableSelect';
import LanguageSwitch from '../../Components/LanguageSwitch';
import AdminAPI from '../../AdminAPI';
import AdminGroupForm from '../../Components/AdminGroupForm';

const MainWrapper = styled.div`
  display: flex;
  padding: ${rem(15)};
`;

const StyledTextarea = styled(Textarea)`
  margin-top: ${rem(10)};
  width: calc(100% - 30px);
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  margin-bottom: ${rem(20)};
  display: flex;
`;

const StyledInput = styled(Input)`
  width: ${rem(300)};
`;

const StyledProductInputSmall = styled(Input)`
  width: ${rem(50)};
  height: ${rem(33)};
`;

const StyledSearchableSelect = styled(SearchableSelect)``;

const InputWrapper = styled.div`
  width: ${rem(400)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${rem(10)};
`;

const CheckBoxesWrapper = styled.div`
  margin-left: ${rem(30)};
  display: flex;
`;

const StyledSelect = styled(Select)`
  width: ${rem(322)};
`;

const SearchableSelectWrapper = styled.div`
  width: 100%;
  max-width: ${rem(200)};
  padding-right: ${rem(10)};
  padding-left: ${rem(30)};
`;

const AddLayer = styled.div`
  margin-left: ${rem(3)};
  width: ${rem(120)};
  font-size: ${rem(14)};
  cursor: pointer;
`;

const Halfer = styled.div`
  margin-right: ${rem(15)};
`;

const HSFTotal = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

class PaintSystemDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteLoading: false,
      showConfirmDialog: false,
      saveLoading: false,
      loading: true,
      brands: [],
      area_of_use: [],
      category_of_surface: [],
      layers: [],
      publishing: [
        {
          chanel: 'CRM',
          value: false,
        },

        {
          chanel: 'B2B',
          value: false,
        },

        {
          chanel: 'xFarby',
          value: false,
        },

        {
          chanel: 'xBarvy',
          value: false,
        },
      ],

      activeLanguage: 'SK',
      code: 'PS00001',
      paintSystem: {
        products: [
          {
            layer: 0,
          },
        ],
      },
    };
  }

  async componentDidMount() {
    const id = getIdFromProps(this.props);

    await this.fetchProductParams();
    await this.fetchDefaultCodelistsAction();
    this.fetchBrandsAction();

    if (id !== 'new') {
      const paintSystem = await this.fetchData(id);
      this.updateParamsValues(paintSystem);
      this.setState({
        paintSystem,
        code: paintSystem.code,
        publishing: paintSystem.publishing,
      });
    } else {
      const code = await this.getNewPaintSystemCode();

      this.setState({
        code,
      });
    }
    this.setState({
      loading: false,
    });
  }

  async getNewPaintSystemCode() {
    try {
      const result = await AdminAPI.getPaintSystemNextCodeAction();
      return result.code || 'PS00000';
    } catch (e) {
      console.log(e);

      return {};
    }
  }

  async fetchDefaultCodelistsAction() {
    try {
      const codelists = await Promise.all([
        await AdminAPI.getCodelist('oblast_pouzitia'),
        await AdminAPI.getCodelist('kategoria_povrchu'),
        await AdminAPI.getCodelist('vrstvy'),
      ]);

      this.setState({
        area_of_use: codelists[0] ? codelists[0].codelist : [],
        category_of_surface: codelists[1] ? codelists[1].codelist : [],
        layers: codelists[2] ? codelists[2].codelist : [],
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchBrandsAction() {
    const { firm } = this.props;
    try {
      const result = await API.getBrandsAction(firm);
      this.setState({
        brands: result.brands || [],
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateParamsValues(paintSystem) {
    const { paramsFromGroups, paramsNotGrouped } = this.state;
    const paramsNotGroupedWithData = paramsNotGrouped.map(g => {
      return {
        params: g.params.map(p => {
          const paintSystemParam = paintSystem.params.find(
            pp => pp._id === p._id,
          );
          return Object.assign(p, {
            value: paintSystemParam && paintSystemParam.value,
            selectedUnit: paintSystemParam && paintSystemParam.selectedUnit,
          });
        }),
        group: g.group,
      };
    });

    const paramsFromGroupsWithData = paramsFromGroups.map(g => {
      return {
        params: g.params.map(p => {
          const paintSystemParam = paintSystem.params.find(
            pp => pp._id === p._id,
          );
          return Object.assign(p, {
            value: paintSystemParam && paintSystemParam.value,
            selectedUnit: paintSystemParam && paintSystemParam.selectedUnit,
          });
        }),
        group: g.group,
      };
    });

    this.setState({
      paramsNotGrouped: paramsNotGroupedWithData,
      paramsFromGroups: paramsFromGroupsWithData,
    });
  }

  async fetchProductParams() {
    try {
      const result = await AdminAPI.getProductTypeParamsAction(
        'NATEROVY_SYSTEM',
      );

      const distinct = (value, index, self) => {
        return self.indexOf(value) === index;
      };

      const groups = result.filter(r => r.group);
      const notGroups = result.filter(r => !r.group);
      const distinctGroups = groups.map(g => g.group).filter(distinct);

      const fetchedGroups = await Promise.all(
        distinctGroups.map(r => {
          return AdminAPI.getProductTypeGroupAction('NATEROVY_SYSTEM', r);
        }),
      );

      const groupsParams = groups.map(g => g.params);

      const codelists = await Promise.all(
        groupsParams
          .flat()
          .concat(notGroups)
          .filter(n => n.codelist)
          .map(async r => {
            return {
              id: r.codelist,
              codelist: await AdminAPI.getCodelist(r.codelist),
            };
          }),
      );

      const paramsFromGroups = groups.map(g => {
        return {
          group: fetchedGroups.find(f => f._id === g.group).name || '',
          params: g.params.map(p => {
            return Object.assign(p, {
              values: codelists.find(c => c.id === p.codelist).codelist,
            });
          }),
        };
      });

      const paramsNotGrouped = [
        {
          group: '',
          params: notGroups.map(p => {
            return Object.assign(p, {
              values:
                codelists.find(c => c.id === p.codelist) &&
                codelists.find(c => c.id === p.codelist).codelist,
            });
          }),
        },
      ];

      this.setState({
        paramsFromGroups,
        paramsNotGrouped,
      });
    } catch (e) {
      console.log(e);
    }
  }

  fetchProducts(query) {
    try {
      return API.getCatalogProductsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: item.name,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchData(id) {
    try {
      const paintSystem = await AdminAPI.getPaintSystemAction(id);
      return paintSystem;
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __(
              'Na zobrazenie náterového systému nemáte potrebné práva.',
            ),
          });
          break;
        case 404:
          this.setState({
            error: __('Náterový systém sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
    return {};
  }

  checkData() {
    const valid = true;

    return valid;
  }

  prepareData() {
    const {
      paintSystem,
      paramsFromGroups,
      paramsNotGrouped,
      code,
      publishing,
    } = this.state;

    const groupedParams = paramsFromGroups.concat(
      Array.isArray(paramsNotGrouped) ? paramsNotGrouped : [paramsNotGrouped],
    );

    const params = groupedParams.map(g => {
      return g.params.map(p => {
        return {
          _id: p._id,
          group: g.group,
          value: p.value,
          selectedUnit: p.unit
            ? Array.isArray(p.unit)
              ? p.selectedUnit
              : p.unit.label
            : '',
          name: p.name,
          type: p.type,
        };
      });
    });

    return {
      code,
      producer_code: paintSystem.producer_code,
      area_of_use:
        paintSystem.area_of_use && paintSystem.area_of_use.length > 2
          ? paintSystem.area_of_use
          : null,
      category_of_surface:
        paintSystem.category_of_surface &&
        paintSystem.category_of_surface.length > 2
          ? paintSystem.category_of_surface
          : null,
      products: paintSystem.products.filter(p => p.product && p.product.value),
      brand: paintSystem.brand,
      short_description: paintSystem.short_description,
      _description: paintSystem._description,
      publishing,
      params: params.flat(),
    };
  }

  async savePaintSystem(showSuccess = true) {
    let { paintSystem } = this.state;
    const { history, firm } = this.props;

    this.setState({
      saveLoading: true,
      success: undefined,
      error: undefined,
    });
    if (this.checkData()) {
      const dataToSend = this.prepareData();
      try {
        let result = paintSystem;
        const params = {};
        params.body = dataToSend;
        if (paintSystem._id) {
          result = await AdminAPI.putPaintSystemAction(paintSystem._id, params);
        } else {
          result = await AdminAPI.postPaintSystemAction(params);
          if (result && getIdFromProps(this.props) === 'new') {
            history.push(`/${firm}/paint-systems/${result._id}`);
            paintSystem = result;
          }
        }

        this.setState({
          paintSystem: result,
          success: showSuccess && __('Náterový systém bol úspešne uložená'),
        });
      } catch (e) {
        this.setState({
          error: __('Pri ukladaní dát sa vyskytla chyba'),
        });
      }
    }
    this.setState({
      saveLoading: false,
    });
  }

  async deletePaintSystem() {
    const { paintSystem } = this.state;
    const { firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deletePaintSystemAction(paintSystem._id);
      window.location.href = `${
        process.env.REACT_APP_ADMIN_UI_DOMAIN
      }/${firm}/paint-systems`;
    } catch (e) {
      this.setState({
        deleteError: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  handleValueChange(field, value, translate) {
    const { paintSystem, activeLanguage } = this.state;

    const objCopy = Object.assign({}, paintSystem);

    if (translate) {
      if (!objCopy[translate]) {
        objCopy[translate] = [];
      }

      if (!objCopy[translate].find(o => o.lang === activeLanguage)) {
        objCopy[translate].push({
          lang: activeLanguage,
          value: '',
        });
      }
      objCopy[translate].find(o => o.lang === activeLanguage).value = value;

      if (activeLanguage === 'SK') {
        objCopy[field] = value;
      }
    } else {
      objCopy[field] = value;
    }

    this.setState({
      paintSystem: objCopy,
    });
  }

  handleParamChange(value, groupIndex, fieldIndex, grouped = false) {
    const { paintSystem, paramsFromGroups, paramsNotGrouped } = this.state;

    if (!paintSystem.params) {
      paintSystem.params = [];
    }

    if (grouped) {
      if (paramsFromGroups[groupIndex].params[fieldIndex])
        paramsFromGroups[groupIndex].params[fieldIndex].value = value;
    } else {
      if (paramsNotGrouped[0].params[fieldIndex].type === 'codelist') {
        paramsNotGrouped[0].params[fieldIndex].value = value.value;
      } else {
        paramsNotGrouped[0].params[fieldIndex].value = value;
      }
    }

    this.setState({
      paramsFromGroups,
      paramsNotGrouped,
    });
  }

  handleUnitChange(value, groupIndex, fieldIndex, grouped) {
    const { paramsFromGroups, paramsNotGrouped } = this.state;
    if (grouped) {
      if (paramsFromGroups[groupIndex].params[fieldIndex])
        paramsFromGroups[groupIndex].params[fieldIndex].selectedUnit = value;
    } else {
      paramsNotGrouped[0].params[fieldIndex].selectedUnit = value;
    }

    this.setState({
      paramsFromGroups,
      paramsNotGrouped,
    });
  }

  handleProductChange(index, field, value) {
    const { paintSystem } = this.state;

    if (!paintSystem.products) {
      paintSystem.products = [];
    }

    if (index === -1) {
      paintSystem.products.push({
        layer: paintSystem.products.length,
      });
    } else {
      paintSystem.products[index][field] = value;
    }

    this.setState({
      paintSystem,
    });
  }

  handlePublishingChange(chanel) {
    let { publishing } = this.state;

    if (!publishing) {
      publishing = [];
    }

    if (!publishing.find(p => p.chanel === chanel)) {
      publishing.push({
        chanel,
        value: false,
      });
    }

    publishing.find(p => p.chanel === chanel).value = !publishing.find(
      p => p.chanel === chanel,
    ).value;

    this.setState({ publishing });
  }

  async createCopy() {
    const { history, firm } = this.props;

    try {
      this.setState({
        copyLoading: true,
      });
      await this.savePaintSystem(false);

      const newPaintSystem = await AdminAPI.getPaintSystemCopyAction(
        getIdFromProps(this.props),
      );
      history.push(`/${firm}/paint-systems/${newPaintSystem._id}`);
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __(
              'Na zobrazenie náterového systému nemáte potrebné práva.',
            ),
          });
          break;
        case 404:
          this.setState({
            error: __('Nepodarilo sa vytvoriť kópiu'),
          });
          break;
        default:
          this.setState({
            error: __('Nepodarilo sa vytvoriť kópiu'),
          });
          break;
      }
    } finally {
      this.setState({
        copyLoading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { copyLoading } = this.state;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Náterový systém')}>
          <ControlBarButton
            small
            primary
            loading={copyLoading}
            onClick={() => this.createCopy()}
            icon="plus"
          >
            {__('Vytvoriť kópiu')}
          </ControlBarButton>

          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      deleteLoading,
      showConfirmDialog,
      error,
      success,
      saveLoading,
      deleteError,
      loading,
      paintSystem,
      brands,
      activeLanguage,
      paramsFromGroups,
      paramsNotGrouped,
      code,
      publishing,
      area_of_use,
      category_of_surface,
      layers,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    let hsfTotal = 0;

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() =>
            this.setState({
              showConfirmDialog: false,
            })
          }
        >
          <ConfirmDialog
            message={__('Prajete si odstániť náterový systém?')}
            onDismiss={() =>
              this.setState({
                showConfirmDialog: false,
              })
            }
            onConfirm={() => this.deletePaintSystem()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>
        <MainWrapper>
          <Halfer>
            <Row>
              <Col>
                <InputWrapper>
                  <StyledLabel>{__('Číslo náterového systému')}</StyledLabel>
                  <StyledInput value={code} disabled />
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Kód výrobcu')}</StyledLabel>
                  <StyledInput
                    value={paintSystem.producer_code}
                    placeholder={__('Kód výrobcu')}
                    onChange={e =>
                      this.handleValueChange('producer_code', e.target.value)
                    }
                  />
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Značka')}</StyledLabel>
                  <StyledSelect
                    size="s"
                    onChange={e => {
                      this.handleValueChange('brand', e.target.value);
                    }}
                  >
                    <option selected={!paintSystem.brand} value={undefined}>
                      {'-'}
                    </option>

                    {brands.map(item => {
                      return (
                        <option
                          selected={paintSystem.brand === item}
                          value={item._id}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </StyledSelect>
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Oblasť použitia')}</StyledLabel>
                  <StyledSelect
                    size="s"
                    onChange={e => {
                      this.handleValueChange('area_of_use', e.target.value);
                    }}
                  >
                    <option
                      selected={!paintSystem.area_of_use}
                      value={undefined}
                    >
                      {'-'}
                    </option>

                    {area_of_use.map(item => {
                      return (
                        <option
                          selected={paintSystem.area_of_use === item._id}
                          value={item._id}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </StyledSelect>
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Kategória povrchu')}</StyledLabel>
                  <StyledSelect
                    size="s"
                    onChange={e => {
                      this.handleValueChange(
                        'category_of_surface',
                        e.target.value,
                      );
                    }}
                  >
                    <option
                      selected={!paintSystem.category_of_surface}
                      value={undefined}
                    >
                      {'-'}
                    </option>

                    {category_of_surface.map(item => {
                      return (
                        <option
                          selected={
                            paintSystem.category_of_surface === item._id
                          }
                          value={item._id}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </StyledSelect>
                </InputWrapper>
              </Col>

              <Col>
                <InputWrapper>
                  <LanguageSwitch
                    activeId={activeLanguage}
                    onChange={e => {
                      this.setState({
                        activeLanguage: e,
                      });
                      return true;
                    }}
                  />
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Krátky popis')}</StyledLabel>

                  <StyledTextarea
                    style={{ height: rem(30) }}
                    placeholder={__(
                      `Sem zadajte kratký popis pre ${activeLanguage} verziu`,
                    )}
                    onChange={e =>
                      this.handleValueChange(
                        'short_description',
                        e.target.value,
                        'short_description_translations',
                      )
                    }
                    value={
                      activeLanguage &&
                      paintSystem.short_description_translations &&
                      paintSystem.short_description_translations.find(
                        t => t.lang === activeLanguage,
                      )
                        ? paintSystem.short_description_translations.find(
                            t => t.lang === activeLanguage,
                          ).value
                        : paintSystem.short_description
                    }
                  />
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Rozsiahly popis')}</StyledLabel>

                  <StyledTextarea
                    style={{ height: rem(45) }}
                    placeholder={__(
                      `Sem zadajte rozsiahly popis pre ${activeLanguage} verziu`,
                    )}
                    onChange={e =>
                      this.handleValueChange(
                        '_description',
                        e.target.value,
                        'description_translations',
                      )
                    }
                    value={
                      activeLanguage &&
                      paintSystem.description_translations &&
                      paintSystem.description_translations.find(
                        t => t.lang === activeLanguage,
                      )
                        ? paintSystem.description_translations.find(
                            t => t.lang === activeLanguage,
                          ).value
                        : paintSystem._description
                    }
                  />
                </InputWrapper>
              </Col>
            </Row>
            <Col>
              <Row>
                <StyledLabel>{__('Publikácia')}</StyledLabel>

                <CheckBoxesWrapper>
                  <CheckBoxWrapper
                    onClick={() => this.handlePublishingChange('CRM')}
                  >
                    <Checkbox
                      checked={
                        publishing &&
                        publishing.find(p => p.chanel === 'CRM') &&
                        publishing.find(p => p.chanel === 'CRM').value
                      }
                      onChange={() => this.handlePublishingChange('CRM')}
                    />
                    <Label>{__('CRM')}</Label>
                  </CheckBoxWrapper>

                  <CheckBoxWrapper
                    onClick={() => this.handlePublishingChange('B2B')}
                  >
                    <Checkbox
                      checked={
                        publishing &&
                        publishing.find(p => p.chanel === 'B2B') &&
                        publishing.find(p => p.chanel === 'B2B').value
                      }
                      onChange={() => this.handlePublishingChange('B2B')}
                    />
                    <Label>{__('B2B')}</Label>
                  </CheckBoxWrapper>

                  <CheckBoxWrapper
                    onClick={() => this.handlePublishingChange('xBarvy')}
                  >
                    <Checkbox
                      checked={
                        publishing &&
                        publishing.find(p => p.chanel === 'xBarvy') &&
                        publishing.find(p => p.chanel === 'xBarvy').value
                      }
                      onChange={() => this.handlePublishingChange('xBarvy')}
                    />
                    <Label>{__('xBarvy')}</Label>
                  </CheckBoxWrapper>

                  <CheckBoxWrapper
                    onClick={() => this.handlePublishingChange('xFarby')}
                  >
                    <Checkbox
                      checked={
                        publishing &&
                        publishing.find(p => p.chanel === 'xFarby') &&
                        publishing.find(p => p.chanel === 'xFarby').value
                      }
                      onChange={() => this.handlePublishingChange('xFarby')}
                    />
                    <Label>{__('xFarby')}</Label>
                  </CheckBoxWrapper>
                </CheckBoxesWrapper>
              </Row>

              <StyledLabel>{__('Produkty')}</StyledLabel>

              {paintSystem.products &&
                paintSystem.products.map((p, index) => {
                  hsfTotal += Number(p.hsf && p.hsf > 0 ? p.hsf : 0);
                  return (
                    <Row>
                      <StyledSelect
                        size="s"
                        onChange={e => {
                          this.handleProductChange(
                            index,
                            'layer',
                            e.target.value,
                          );
                        }}
                      >
                        <option selected={!p.layer} value={undefined}>
                          {'-'}
                        </option>

                        {layers.map(item => {
                          return (
                            <option
                              selected={p.layer === item._id}
                              value={item._id}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </StyledSelect>
                      <SearchableSelectWrapper>
                        <StyledSearchableSelect
                          value={p.product}
                          loadOptions={query => this.fetchProducts(query)}
                          placeholder={__('Vyhľadať produkt')}
                          handleOnChange={e =>
                            this.handleProductChange(index, 'product', e)
                          }
                        />
                      </SearchableSelectWrapper>

                      <StyledProductInputSmall
                        value={p.hsf}
                        placeholder={__('HSF')}
                        type="number"
                        onChange={e =>
                          this.handleProductChange(
                            index,
                            'hsf',
                            Number(e.target.value),
                          )
                        }
                      />
                      <CheckBoxesWrapper>
                        <CheckBoxWrapper>
                          <Checkbox
                            checked={p.unit === 'g/m2'}
                            onChange={() =>
                              this.handleProductChange(index, 'unit', 'g/m2')
                            }
                          />
                          <Label>{__('g/m2')}</Label>
                        </CheckBoxWrapper>

                        <CheckBoxWrapper>
                          <Checkbox
                            checked={p.unit === 'µm'}
                            onChange={() =>
                              this.handleProductChange(index, 'unit', 'µm')
                            }
                          />
                          <Label>{__('µm')}</Label>
                        </CheckBoxWrapper>
                      </CheckBoxesWrapper>
                    </Row>
                  );
                })}

              <Row style={{ alignItems: 'center', width: rem(440) }}>
                {!paintSystem.products ||
                  (paintSystem.products.length < 5 && (
                    <AddLayer onClick={() => this.handleProductChange(-1)}>
                      {__('+ Pridať vrstvu')}
                    </AddLayer>
                  ))}
                <HSFTotal>
                  <StyledProductInputSmall
                    value={hsfTotal}
                    placeholder={__('0')}
                    type="number"
                    disabled
                  />
                </HSFTotal>
              </Row>
            </Col>

            <Col>
              {paramsNotGrouped && (
                <AdminGroupForm
                  groups={
                    Array.isArray(paramsNotGrouped)
                      ? paramsNotGrouped
                      : [paramsNotGrouped]
                  }
                  onChange={(value, groupIndex, fieldIndex) => {
                    this.handleParamChange(
                      value,
                      groupIndex,
                      fieldIndex,
                      false,
                    );
                  }}
                  handleUnitChange={(
                    groupIndex,
                    fieldIndex,
                    rowIndex,
                    columnIndex,
                    value,
                  ) => {
                    this.handleUnitChange(value, groupIndex, fieldIndex, false);
                  }}
                />
              )}
            </Col>
          </Halfer>
          <Halfer>
            {paramsFromGroups && (
              <AdminGroupForm
                groups={paramsFromGroups}
                onChange={(value, groupIndex, fieldIndex) => {
                  this.handleParamChange(value, groupIndex, fieldIndex, true);
                }}
                handleUnitChange={(
                  groupIndex,
                  fieldIndex,
                  rowIndex,
                  columnIndex,
                  value,
                ) => {
                  this.handleUnitChange(value, groupIndex, fieldIndex, true);
                }}
              />
            )}
          </Halfer>
        </MainWrapper>

        <ButtonRelativeWrapper>
          <Button
            primary
            loading={saveLoading}
            onClick={() => this.savePaintSystem()}
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

PaintSystemDetail.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

PaintSystemDetail.defaultProps = {
  firm: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(PaintSystemDetail);
