/* eslint-disable react/sort-comp */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import Message from 'oxyrion-ui/lib/Message';
import Label from 'oxyrion-ui/lib/Label/Label';
import { PageNavigator, Button, Input } from 'oxyrion-ui/lib';
import Select from 'oxyrion-ui/lib/Select';
import TableV2 from '../../Components/TableV2';
import {
  FlexBox,
  AnimatedFormMessageWrapper,
  DHeader,
  RouteLinkWraper as LinkWrap,
} from '../../Components/ReusableComponents';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import API from '../../API';
import { __, parseQuery, buildQuery } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import sendRecents from '../../Utils/sendRecents';
import IconTd from '../../Components/IconTd';
import OwnStateCheckbox from '../../Components/OwnStateCheckbox';
import PageInfo from '../../Components/PageInfo';
import SearchableSelect from '../../Components/SearchableSelect';
import DateAndTimerPicker from '../../Components/DateAndTimePicker';
import moment from 'moment';

const PageWrapper = styled(FlexBox)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 2px 2px ${({ theme }) => theme.separatorColor};
  justify-content: space-between;
  padding: ${rem(10)} ${rem(20)};
  min-height: ${rem(15)};
  margin: auto;
  font-size: ${({ theme }) => theme.fontSize || rem(11)};
`;

const FiltersMainWrapper = styled.div`
  padding: ${rem(16)};

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: ${rem(32)};
  }

  .space {
    width: ${rem(12)};
    height: ${rem(12)};
  }
`;

const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 350px));
  grid-column-gap: 3px;
  grid-row-gap: 3px;

  .input-wrapper {
    width: ${rem(300)};
  }

  .select-wrapper {
    width: ${rem(324)};
  }

  .search-select-wrapper {
    width: ${rem(324)};
    z-index: 100000;
  }

  .filterItem {
    z-index: 4;
  }
`;

const TableWrapper = styled.div`
  margin: ${({ small }) => (small ? 'unset' : 'auto')};
  min-height: ${({ small }) => (small ? 'unset' : '70vh')};
  width: 100%;
  margin-bottom: ${({ showPager }) => (showPager ? rem(55) : 0)};
`;

const P = styled.p`
  margin: 0;
  color: ${props => props.closed && '#a9a9a9'};
  padding: 0 ${rem(10)};
  min-height: ${rem(32)};
  font-size: ${rem(12)};
  align-items: center;
  display: flex;
  justify-content: start;
`;

class BaseTable extends React.Component {
  constructor(props) {
    super(props);
    this.headers = [];
    this.filters = [];
    this.segmentName = __('');
    this.showBulkOperations = false;
    const { history, loading } = props;
    this.defaultSorter = '';
    const q = history ? parseQuery(history.location.search) : {};

    this.setSorter(this.defaultSorter);
    this.accessors = ['', '', '', '', '', '', '', '', ''];
    this.state = {
      content: [],
      selectedFilter: [],
      offset: 0,
      limit: 100,
      total: null,
      loading,
      checkedRows: [],
      allRowsChecked: false,
      activateError: null,
      loadResults: {},
      error: null,
      sorter: q.sorter || props.sorter || this.defaultSorter,
      lastSearchValue: '',
      lastOpenedId: '',
      defaultScrollPosition: 0,
    };
  }

  componentWillUnmount() {
    const { offset, nextOffset, total, lastSearchValue, sorter } = this.state;

    this.setLocalStorage('offset', offset, this.segmentName);
    this.setLocalStorage('nextOffset', nextOffset, this.segmentName);
    this.setLocalStorage('total', total, this.segmentName);
    this.setLocalStorage('lastSearchValue', lastSearchValue, this.segmentName);
    this.setLocalStorage('sorter', sorter, this.segmentName);
  }

  componentDidMount() {
    this.init();
  }

  init() {
    const offset = this.getLocalStorage('offset', this.segmentName);
    const nextOffset = this.getLocalStorage('nextOffset', this.segmentName);
    const total = this.getLocalStorage('total', this.segmentName);
    const lastSearchValue = this.getLocalStorage(
      'lastSearchValue',
      this.segmentName,
    );
    const sorter = this.getLocalStorage('sorter', this.segmentName);

    const lastOpenedId = this.getLocalStorage('lastOpenedId', this.segmentName);

    const defaultScrollPosition = this.getLocalStorage(
      'defaultScrollPosition',
      this.segmentName,
    );

    this.setLocalStorage('defaultScrollPosition', 0, this.segmentName);
    this.setLocalStorage('lastOpenedId', '', this.segmentName);
    this.setLocalStorage('offset', 0, this.segmentName);
    this.setLocalStorage('nextOffset', null, this.segmentName);
    this.setLocalStorage('total', null, this.segmentName);

    const q = this.propshistory
      ? parseQuery(this.props.history.location.search)
      : {};

    this.setLocalStorage(
      'sorter',
      q.sorter || this.props.sorter || this.defaultSorter,
      this.segmentName,
    );

    const searchValue =
      lastSearchValue !== 'null' && lastSearchValue ? lastSearchValue : '';

    const lastFilter = this.getLocalStorage('selectedFilter', this.segmentName);

    let parsedFilter = null;

    let { showFilter } = this.state;

    if (
      lastFilter &&
      lastFilter !== 'undefined' &&
      lastFilter !== null &&
      lastFilter !== 'null' &&
      lastFilter.length
    ) {
      parsedFilter = JSON.parse(lastFilter);
      showFilter = true;
      this.setLocalStorage('selectedFilter', null, this.segmentName);
    }
    this.setLocalStorage('lastSearchValue', '', this.segmentName);

    this.setState(
      {
        selectedFilter: parsedFilter || this.state.selectedFilter,
        offset: Number(offset || 0),
        nextOffset: Number(nextOffset || 0),
        total: Number(total || 0),
        lastSearchValue: searchValue,
        defaultScrollPosition,
        lastOpenedId,
        showFilter,
        sorter: sorter || this.state.sorter,
      },
      async () => {
        this.fetchData({
          q: searchValue,
          nextOffset,
          offset: Number(offset || 0),
          total,
        });
        sendRecents();
      },
    );
  }

  saveScrollPositions(value, rowInfo) {
    this.setLocalStorage('defaultScrollPosition', value, this.segmentName);
    this.setLocalStorage(
      'lastOpenedId',
      rowInfo.original._id,
      this.segmentName,
    );
  }

  saveFiltersToLocalStorage(selectedFilter) {
    const { lastSearch } = this.state;

    if (selectedFilter) {
      this.setLocalStorage(
        'lastSearch',
        lastSearch !== null && lastSearch !== undefined ? lastSearch : '',
        this.segmentName,
      );

      this.setLocalStorage(
        'selectedFilter',
        JSON.stringify(selectedFilter),
        this.segmentName,
      );
    }
  }

  setLocalStorage(name, value, constructorName) {
    localStorage.setItem(constructorName + name, value);
  }

  getLocalStorage(name, constructorName) {
    return localStorage.getItem(constructorName + name);
  }

  async componentDidUpdate(newProps) {
    if (newProps.loading !== this.props.loading) {
      this.setState({
        loading: !newProps.loading,
      });
    }
    if (newProps.firm !== this.props.firm) {
      await this.fetchData();
    }
  }

  onPageClick(page) {
    const { limit } = this.state;
    this.fetchData({
      offset: (page - 1) * limit,
    });
  }
  onIncrease() {
    const { nextOffset } = this.state;
    this.fetchData({
      offset: nextOffset,
    });
  }
  onDecrease() {
    const { limit, offset } = this.state;
    this.fetchData({
      offset: offset - limit,
    });
  }

  getMainContact(contacts) {
    const contact = contacts.reduce((acum, item) => {
      if (item.is_default) {
        return item;
      }
      return acum;
    }, {});
    return contact;
  }

  setSorter(sorter) {
    if (sorter !== null) {
      const { history } = this.props;
      history.push({
        search: buildQuery({ sorter }),
      });
    }
  }

  buildParamsByFilter(selectedFilter) {
    return {};
  }

  async sortBy(sorterType, ascending) {
    const sign = ascending ? '' : '-';
    this.setState({ sorter: `${sign}${sorterType}` }, () => {
      this.fetchData();
    });
  }

  handleFilterChange(field, value) {
    const { selectedFilter } = this.state;
    if (selectedFilter.find(s => s.filterValue === field)) {
      selectedFilter.find(s => s.filterValue === field).selected = value;
    }

    this.setState(
      {
        selectedFilter,
      },
      () => this.fetchData({}),
    );
  }

  async sortByV2(s) {
    const { sorter } = this.state;

    let newSorter = sorter;
    const value = this.headers.find(h => h.name === s);

    if (value && value.sorterValue) {
      if (sorter === value.sorterValue) {
        newSorter = `-${value.sorterValue}`;
      } else {
        newSorter = value.sorterValue;
      }
      this.setSorter(newSorter);
      this.setState({ sorter: newSorter }, () => this.fetchData());
    }
  }

  async fetchData() {
    // specificke pre kazdu tabulku
    return null;
  }

  createColumns(sorter) {
    let columns = [];

    const { allRowsChecked, checkedRows } = this.state;

    if (this.showBulkOperations) {
      columns = [
        {
          Header: () => (
            <OwnStateCheckbox
              checked={allRowsChecked}
              onChange={() => this.handleAllRowsOnChange()}
            />
          ),
          Cell: props => {
            return (
              <P style={{ justifyContent: 'center' }}>
                <OwnStateCheckbox
                  checked={
                    allRowsChecked ||
                    checkedRows.indexOf(props.original.id) > -1
                  }
                  onChange={() => this.handleRowOnChange(props.original.id)}
                />
              </P>
            );
          },
        },
        ...this.headers.map((header, index) => {
          if (header.clickable) {
            return {
              accessor: header.accessor || this.accessors[index],
              sortable: true,
              Header: () => (
                <IconTd
                  sorter={
                    sorter === header.sorterValue ||
                    sorter === `-${header.sorterValue}`
                      ? sorter
                      : null
                  }
                >
                  {header.name}
                </IconTd>
              ),
              Cell: props => {
                return (
                  <P closed={props.original.closed}>
                    {props &&
                      props.value &&
                      props.value.value &&
                      props.value.value}
                  </P>
                );
              },
              ...header.otherProps,
            };
          }
          return {
            accessor: header.accessor || this.accessors[index],
            Header: () => <DHeader>{header.name}</DHeader>,
            Cell: props => {
              return (
                <P closed={props.original.closed}>
                  {props &&
                    props.value &&
                    props.value.value &&
                    props.value.value}
                </P>
              );
            },
            ...header.otherProps,
          };
        }),
      ];
    } else {
      columns = this.headers.map((header, index) => {
        if (header.clickable) {
          return {
            accessor: header.accessor || this.accessors[index],
            sortable: true,
            Header: () => (
              <IconTd
                sorter={
                  sorter === header.sorterValue ||
                  sorter === `-${header.sorterValue}`
                    ? sorter
                    : null
                }
              >
                {header.name}
              </IconTd>
            ),
            Cell: props => {
              return (
                <P closed={props.original.closed}>
                  {props &&
                    props.value &&
                    props.value.value &&
                    props.value.value}
                </P>
              );
            },
            ...header.otherProps,
          };
        }
        return {
          accessor: header.accessor || this.accessors[index],
          Header: () => <DHeader>{header.name}</DHeader>,
          Cell: props => {
            return (
              <P closed={props.original.closed}>
                {props && props.value && props.value.value && props.value.value}
              </P>
            );
          },
          ...header.otherProps,
        };
      });
    }

    return columns;
  }

  normalizeColumns() {
    // specificke pre kazdu tabulku
    return [];
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 'unset',
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeData(a, b) {
    const newHeaders = this.normalizedHeaders(a);
    const data = this.normalizeColumns(b);

    return { headers: newHeaders, rows: data };
  }

  handleContactOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/contacts/${id}`);
  }

  handleNameOnClick(id) {
    const { history, match, customNameOnClick } = this.props;
    if (customNameOnClick) {
      customNameOnClick(id);
    } else {
      history.push(`${match.url}/${id}`);
    }
  }

  handleRowOnChange(id) {
    const { checkedRows } = this.state;
    if (checkedRows.includes(id)) {
      this.setState({
        checkedRows: checkedRows.filter(f => f !== id),
      });
    } else {
      this.setState({ checkedRows: [...checkedRows, id] });
    }
  }

  handleAllRowsOnChange() {
    const { content, allRowsChecked } = this.state;
    if (allRowsChecked) {
      this.setState({ checkedRows: [], allRowsChecked: false });
    } else {
      this.setState({
        checkedRows: content.map(item => item.id),
        allRowsChecked: true,
      });
    }
  }

  async usersActivation(status = 'active') {
    try {
      const { firm } = this.props;
      const { checkedRows } = this.state;

      await API.putCustomersStatusAction(firm, {
        data: {
          newStatus: status,
          customers: checkedRows,
        },
      });

      // const newContent = content.filter(f => !checkedRows.includes(f.id));
      this.fetchData();
      this.setState({
        // content: newContent,
        checkedRows: [],
        allRowsChecked: false,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            activateError: __('Na úžívateľa nemáte potrebné práva'),
          });
          break;
        default:
          this.setState({
            activateError: __('Vyskytla sa chyba.'),
          });
      }
    }
  }

  sort(sorter) {
    this.setSorter(sorter);
    this.setState({ sorter }, () => {
      this.fetchData();
    });
  }

  async loadSearchRrsult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults });
    }
  }

  renderControlBar() {
    const { selectedFilter } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        {this.renderConfigFilters(selectedFilter)}
        <ControllBar
          name={this.segmentName}
          history={history}
          defaultValue={this.state.lastSearchValue}
          onChange={val => this.loadSearchRrsult(val)}
        />
      </React.Fragment>
    );
  }

  renderPageNavigation(limit, offset, total) {
    return (
      <PageWrapper>
        <PageInfo offset={offset} limit={limit} total={total} />
        <PageNavigator
          activePage={offset / limit + 1}
          end={total / limit + 1}
          onPageClick={page => this.onPageClick(page)}
          onDecrease={() => this.onDecrease()}
          onIncrease={() => this.onIncrease()}
        />
      </PageWrapper>
    );
  }

  renderConfigFilters(selectedFilter) {
    return this.filters.map(filter => {
      const { selected } = selectedFilter.find(
        s => s.filterValue === filter.filterValue,
      );

      return (
        <ControlButton
          name={filter.values.find(v => v.value === selected).name}
        >
          <LinkWrap>
            {filter.values.map(value => (
              <div
                onClick={() =>
                  this.handleFilterChange(filter.filterValue, value.value)
                }
              >
                {value.name}
              </div>
            ))}
          </LinkWrap>
        </ControlButton>
      );
    });
  }
  renderFilterItem(item, index) {
    const { filters } = this.state;

    if (item.type === 'input') {
      return (
        <div className="input-wrapper">
          <Label>{item.label}</Label>
          <Input
            onChange={e => {
              filters[index].value = e.target.value;
              this.setState({
                filters,
              });
            }}
            value={item.value}
            placeholder={item.label}
          />
        </div>
      );
    }

    if (item.type === 'select') {
      return (
        <div className="select-wrapper">
          <Label>{item.label}</Label>
          <Select
            size="s"
            placeholder={item.label}
            onChange={e => {
              filters[index].value = e.target.value;
              this.setState({
                filters,
              });
            }}
          >
            <option>-</option>
            {item.values.map(selectItem => (
              <option
                key={selectItem.key}
                value={selectItem.value}
                selected={selectItem.value === item.value}
              >
                {selectItem.name}
              </option>
            ))}
          </Select>
        </div>
      );
    }

    if (item.type === 'number') {
      return (
        <div className="input-wrapper">
          <Label>{item.label}</Label>
          <Input
            type="number"
            onChange={e => {
              filters[index].value = e.target.value;
              this.setState({
                filters,
              });
            }}
            value={item.value}
            placeholder={item.label}
          />
        </div>
      );
    }

    if (item.type === 'search-select') {
      return (
        <div className="search-select-wrapper">
          <Label>{item.label}</Label>
          <SearchableSelect
            value={item.value}
            loadOptions={query => item.onSearch(query)}
            placeholder={item.label}
            name={item.name}
            handleOnChange={e => {
              filters[index].value = e;
              this.setState({
                filters,
              });
            }}
          />
        </div>
      );
    }

    if (item.type === 'date') {
      return (
        <div className="input-wrapper">
          <Label>{item.label}</Label>
          <DateAndTimerPicker
            timePlaceHolder="Čas"
            datePlaceHolder="Dátum"
            value={item.value && moment(item.value)}
            onChange={e => {
              filters[index].value = e;
              this.setState({
                filters,
              });
            }}
          />
        </div>
      );
    }
  }
  renderFilters() {
    const { filters } = this.state;

    return (
      <FiltersMainWrapper>
        <FiltersWrapper>
          {filters &&
            filters.map((f, index) => (
              <div
                className="filterItem"
                style={{
                  zIndex: f.type === 'search-select' ? 100 : 4,
                }}
              >
                {this.renderFilterItem(f, index)}
              </div>
            ))}
        </FiltersWrapper>
        <div className="button-wrapper">
          <Button
            small
            danger
            onClick={() => {
              this.setState(
                {
                  filters: this.state.filters.map(f =>
                    Object.assign(f, { value: '' }),
                  ),
                },
                () => this.applyFilter(),
              );
            }}
          >
            {__('Zrušiť filter')}
          </Button>
          <div className="space" />
          <Button small primary onClick={() => this.applyFilter()}>
            {__('Aplikovať filter')}
          </Button>
        </div>
      </FiltersMainWrapper>
    );
  }
  render() {
    const { showPager, small } = this.props;
    const {
      loading,
      limit,
      total,
      offset,
      content,
      sorter,
      error,
      activateError,
      defaultScrollPosition,
      lastOpenedId,
      success,
    } = this.state;

    return (
      <Fragment>
        <AnimatedFormMessageWrapper display={activateError}>
          <Message error message={activateError} />
        </AnimatedFormMessageWrapper>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>
        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <AnimatedFormMessageWrapper display={this.state.showFilter}>
          {this.renderFilters()}
        </AnimatedFormMessageWrapper>
        <TableWrapper small={small} showPager={showPager}>
          <TableV2
            defaultScrollPosition={defaultScrollPosition}
            saveScrollPositions={(position, rowInfo) =>
              this.saveScrollPositions(position, rowInfo)
            }
            lastOpenedId={lastOpenedId}
            columns={this.createColumns(sorter)}
            minWidth={10}
            loading={loading}
            data={content}
            getTdProps={() => {
              return { style: { padding: 0, margin: 'auto' } };
            }}
            getTheadThProps={(state, rowInfo, column) => {
              if (column && column.sortable) {
                return {
                  onClick: e => {
                    this.sortByV2(e.target.innerHTML);
                  },
                };
              }
              return {};
            }}
            className="-highlight -striped"
          />
        </TableWrapper>
        {showPager ? this.renderPageNavigation(limit, offset, total) : ''}
      </Fragment>
    );
  }
}

BaseTable.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
  firm: PropTypes.string.isRequired,
  customNameOnClick: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  sorter: PropTypes.string,
  showPager: PropTypes.bool,
  small: PropTypes.bool,
};

BaseTable.defaultProps = {
  customNameOnClick: null,
  onClose: null,
  loading: true,
  sorter: null,
  showPager: true,
  small: false,
};

export default BaseTable;
