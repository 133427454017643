/* eslint-disable react/no-unused-state */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Input, Loader, Label, Button } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import './style.css';
import {
  AnimatedFormMessageWrapper,
  ButtonRelativeWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import { __ } from '../../Utils';
import AdminAPI from '../../AdminAPI';
import SearchableSelect from '../../Components/SearchableSelect';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import LanguageSwitch from '../../Components/LanguageSwitch';
import API from '../../API';
import { ErrorText } from '../CategoriesSettings';
import axios from 'axios';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-left: ${rem(20)};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(32)};
  margin-left: ${rem(4)};
  margin-top: ${rem(8)};
  font-weight: 700;
`;

const StyledInput = styled(Input)`
  width: ${rem(580)};
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(600)};
`;

const Space = styled.div`
  width: ${rem(20)};
`;

class CashRegisterSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: '',
      settings: {},
      activeLanguage: 'SK',
      loading: false,
      saveLoading: false,
      csvUploadLoading: false,
      notValidPrice: false,
      notValidProduct: false,
      price: '',
      showOverrideDialog: false,
      cashRegisterPrice: null,
      overrideLoading: false,
    };

    this.inputReference = React.createRef();
  }

  async fetchProducts(query) {
    try {
      return API.getProductsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: item.Description,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchCashRegisterProduct(productId, activeLanguage) {
    const params = { firm: activeLanguage };
    let cashRegisterProduct = null;
    let cashRegisterPrice = '';

    this.setState({
      cashRegisterPrice,
    });

    try {
      cashRegisterProduct = await AdminAPI.getCashRegisterPricesAction(
        productId,
        params,
      );

      cashRegisterPrice =
        cashRegisterProduct && cashRegisterProduct.unit_price
          ? cashRegisterProduct.unit_price
          : '';
    } catch (e) {
      switch (e.response.status) {
        // case 404:
        //   alert('Cena pre tento produkt zatiaľ nebola nastavená');
        //   break;
        default:
          console.log(e);
          break;
      }
    }

    return cashRegisterPrice;
  }

  async handleOnChange(field, value) {
    let {
      product,
      cashRegisterPrice,
      activeLanguage,
      notValidPrice,
      notValidProduct,
    } = this.state;

    notValidPrice = false;
    notValidProduct = false;

    if (field === 'lang') {
      activeLanguage = value;

      if (product && product.product && product.product._id) {
        cashRegisterPrice = await this.fetchCashRegisterProduct(
          product.product._id,
          value,
        );
      }
    } else if (field === 'product') {
      product = value;

      if (value && value.product) {
        cashRegisterPrice = await this.fetchCashRegisterProduct(
          value.product._id,
          activeLanguage,
        );
      } else {
        cashRegisterPrice = '';
      }
    } else if (field === 'cashRegisterPrice') {
      cashRegisterPrice = value;
    }

    this.setState({
      product,
      cashRegisterPrice,
      activeLanguage,
      notValidPrice,
      notValidProduct,
    });
  }

  checkData(cashRegisterPrice) {
    const { product } = this.state;
    let notValidPrice = false;
    let notValidProduct = false;
    let isValid = true;

    if (!product) {
      notValidProduct = true;
      isValid = false;
    }

    if (parseFloat(cashRegisterPrice) < 0) {
      notValidPrice = true;
      isValid = false;
    }

    this.setState({
      notValidPrice,
      notValidProduct,
    });

    return isValid;
  }

  async saveData(override = false) {
    const {
      product,
      cashRegisterPrice,
      activeLanguage,
      overrideLoading,
    } = this.state;

    this.setState({
      saveLoading: true,
    });

    if (this.checkData(cashRegisterPrice)) {
      if (cashRegisterPrice === '' || parseFloat(cashRegisterPrice) === 0) {
        try {
          await AdminAPI.deleteCashRegisterPricesAction(product.product._id, {
            firm: activeLanguage,
          });

          this.setState({
            success: __('Cena bola úspešne zmazaná'),
            cashRegisterPrice: '',
            saveLoading: false,
          });

          return;
        } catch (e) {
          console.error(e);

          this.setState({
            saveLoading: false,
            error: __('Cenu sa nepodarilo zmazať'),
          });
        }
      }

      const parameters = {
        override: false,
      };

      parameters[''] = {
        product_id: product.product._id,
        unit_price: parseFloat(cashRegisterPrice),
        firm: activeLanguage,
      };

      if (override) {
        try {
          this.setState({
            overrideLoading: true,
          });
          parameters['override'] = true;

          const response = await AdminAPI.postCashRegisterPricesAction(
            parameters,
          );

          this.setState({
            success: __('Cena bola úspešne uložená'),
            saveLoading: false,
            showOverrideDialog: false,
            overrideLoading: false,
            cashRegisterPrice: response.unit_price,
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          const response = await AdminAPI.postCashRegisterPricesAction(
            parameters,
          );

          this.setState({
            overrideLoading,
            success: __('Cena bola úspešne uložená'),
            saveLoading: false,
            cashRegisterPrice: response.unit_price,
          });
        } catch (e) {
          switch (e.response.status) {
            case 409:
              this.setState({
                showOverrideDialog: true,
              });
              break;
            default:
              console.error(e);
              this.setState({
                saveLoading: false,
                error: __('Cenu sa nepodarilo uložiť'),
              });
              break;
          }
        }
      }
    } else {
      this.setState({
        saveLoading: false,
        showOverrideDialog: false,
      });
    }
  }

  fileUploadAction = () => this.inputReference.current.click();

  async uploadCSV(file) {
    this.setState({
      csvUploadLoading: true,
      error: false,
    });

    const fileUpload = `${
      process.env.REACT_APP_ADMIN_DOMAIN
    }/admin/cash-registers/import/prices`;

    try {
      const formData = new FormData();
      formData.append('file', file);
      const headers = AdminAPI.appendAuthHeaders();

      axios
        .post(fileUpload, formData, {
          headers: {
            Authorization: headers.get('Authorization'),
          },
        })
        .then(() => {
          this.setState({
            success: __('CSV súbor bol úspešne naimportovaný'),
            csvUploadLoading: false,
          });
        })
        .catch(() => {
          this.setState({
            error: __('Pri nahrávaní CSV súboru nastala chyba'),
            csvUploadLoading: false,
          });
        });
    } catch (e) {
      this.setState({
        error: __('Nesprávny typ súboru'),
        csvUploadLoading: false,
      });
      console.log(e);
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Registračné pokladne')} />;
  }

  render() {
    const {
      error,
      success,
      activeLanguage,
      loading,
      product,
      notValidPrice,
      notValidProduct,
      saveLoading,
      csvUploadLoading,
      showOverrideDialog,
      cashRegisterPrice,
      overrideLoading,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <PopUpWrapper
          display={showOverrideDialog}
          small
          onClose={() =>
            this.setState({
              showOverrideDialog: false,
            })
          }
        >
          <ConfirmDialog
            message={__(`Cena pre produkt už existuje. Chcete ju nahradiť?`)}
            onDismiss={() =>
              this.setState({
                showOverrideDialog: false,
                saveLoading: false,
                overrideLoading: false,
              })
            }
            loading={overrideLoading}
            onConfirm={() => this.saveData(true)}
          />
        </PopUpWrapper>

        {this.renderControlBar()}

        <Wrapper>
          <LanguageSwitch
            onChange={id => {
              return this.handleOnChange('lang', id);
            }}
            activeId={activeLanguage}
          />

          <Title>{__('Ceny produktov:')}</Title>

          <SearchableSelectWrapper>
            <SearchableSelect
              value={product}
              loadOptions={query => {
                return this.fetchProducts(query);
              }}
              placeholder={__('Vyhľadať produkt')}
              name={product}
              error={notValidProduct}
              handleOnChange={e => this.handleOnChange('product', e)}
            />
          </SearchableSelectWrapper>

          {product && product.product && product.product._id && (
            <div>
              <Label>{__('Cena bez dph: ')}</Label>

              <StyledInput
                placeholder={__('Cena bez dph')}
                onChange={e =>
                  this.handleOnChange('cashRegisterPrice', e.target.value)
                }
                value={cashRegisterPrice}
                error={notValidPrice}
                disabled={!product}
                type="number"
                min="0"
              />
            </div>
          )}
          {(notValidPrice || notValidProduct) && (
            <ErrorText>
              {notValidProduct
                ? __('Nie je zvolený produkt')
                : __('Cena nie je vyplenená správne')}
            </ErrorText>
          )}

          <ButtonRelativeWrapper>
            {!showOverrideDialog && (
              <div>
                <input
                  type="file"
                  hidden
                  ref={this.inputReference}
                  onChange={e => this.uploadCSV(e.target.files[0])}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <Button
                  primary
                  loading={csvUploadLoading}
                  onClick={this.fileUploadAction}
                >
                  {__('Import CSV')}
                </Button>
              </div>
            )}
            <Space />
            <Button
              onClick={() => this.saveData()}
              primary
              loading={saveLoading}
            >
              {__('Uložiť')}
            </Button>
          </ButtonRelativeWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

CashRegisterSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CashRegisterSettings);
