import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Loader, Button, Textarea } from 'oxyrion-ui/lib';
import ControllBar from '../../../Components/ControllBar';
import {
  ButtonRelativeWrapper,
  Close,
  H2,
  LoaderWrapper,
  StyledInput,
  StyledLabel,
} from '../../../Components/ReusableComponents';
import { getIdFromProps, __ } from '../../../Utils';
import { connect } from '../../../Store';
import AdminAPI from '../../../AdminAPI';
import SearchableSelect from '../../../Components/SearchableSelect';
import API from '../../../API';
import { fetchGraphics } from '../../../Utils/ApiSearchFunctions';
import LanguageSwitch from '../../../Components/LanguageSwitch';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const InputWrapper = styled.div`
  width: ${rem(352)};
`;

const Row = styled.div`
  margin-bottom: ${rem(15)};
  display: flex;
`;

const TitleWrapper = styled.div`
  padding-top: ${rem(12)};
  padding-bottom: ${rem(6)};
  display: flex;
  justify-content: space-between;
`;

const PublishedCountriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: ${rem(12)};
  width: ${rem(460)};
  margin-bottom: ${rem(20)};
  flex-direction: column;
`;

const SectionWrapper = styled.div`
  background-color: #f7f7f7;
  padding: ${rem(10)};
  border-radius: ${rem(4)};
  margin-bottom: ${rem(20)};
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  margin-top: ${rem(12)};
`;

class BrandPortfolioDetail extends React.Component {
  constructor(props) {
    super(props);
    const translations = [{ lang: 'SK', value: '' }];

    this.state = {
      loading: false,
      success: false,
      activeLang: 'SK',
      activeSectionLangs: [],
      brand: {
        brand: null,
        multimedium_logo_id: null,
        banner_id: null,
        portfolio_section_codelist_id: null,
        short_description: translations,
        long_description: translations,
        products_title: translations,
        url: translations,
        sections: [
          {
            banner_id: '',
            title: translations,
            description: translations,
            catalog_products_ids: [''],
          },
        ],
      },
    };
  }

  componentWillMount() {
    this.loadData();
    const { brand } = this.state;
    const activeSectionLangs = brand.sections.map(() => 'SK');

    this.setState({ activeSectionLangs });
  }

  async loadData() {
    this.setState({
      loading: true,
    });

    const id = getIdFromProps(this.props);

    if (id !== 'new') {
      await this.fetchPortfolio(id);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async getProductInfo(productId) {
    const res = await AdminAPI.getProductAction(productId);
    return res;
  }

  async fetchPortfolio(id) {
    try {
      const params = {
        firm: this.props.firm,
      };

      const portfolio = await AdminAPI.getPortfolioBrandAction(id, params);

      let portfolioObj = null;

      if (portfolio.brand) {
        const brands = await this.fetchBrands();
        const matchedBrand = brands.find((i) => i._id === portfolio.brand);
        if (matchedBrand) {
          portfolioObj = {
            ...portfolioObj,
            brand: matchedBrand,
          };
        }
      }

      const banners = await this.fetchBanners();
      if (portfolio.banner_id) {
        const matchedBanner = banners.find(
          (i) => i._id === portfolio.banner_id,
        );
        if (matchedBanner) {
          portfolioObj = {
            ...portfolioObj,
            banner_id: matchedBanner,
          };
        }
      }

      if (
        portfolio.portfolio_section_codelist_id &&
        portfolio.portfolio_section_codelist_id.length > 0
      ) {
        const sectionCodelist = await this.fetchSectionCodelist();

        const matchedPortfolio = sectionCodelist.filter((sc) =>
          portfolio.portfolio_section_codelist_id.includes(sc.value),
        );

        if (matchedPortfolio) {
          portfolioObj = {
            ...portfolioObj,
            portfolio_section_codelist_id: matchedPortfolio,
          };
        }
      }

      if (portfolio.sections.length > 0) {
        const sectionArrPromises = portfolio.sections.map(async (section) => {
          const matchedProducts = await Promise.all(
            section.catalog_products_ids.map(async (productId) => {
              try {
                const foundProduct = await this.getProductInfo(productId);

                return (
                  {
                    label: foundProduct.name,
                    value: foundProduct._id,
                    product: foundProduct,
                  } || null
                );
              } catch (error) {
                return null;
              }
            }),
          );

          const matchedBanner = banners.find(
            (i) => i._id === section.banner_id,
          );
          return {
            ...section,
            catalog_products_ids: matchedProducts || [''],
            banner_id: matchedBanner || '',
          };
        });

        const sectionArr = await Promise.all(sectionArrPromises);

        if (sectionArr) {
          portfolioObj = {
            ...portfolioObj,
            sections: sectionArr,
          };
        }
      }

      const newPortfolio = Object.assign({}, portfolio, {
        ...portfolioObj,
      });

      this.setState({
        brand: newPortfolio,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchSectionCodelist() {
    try {
      return AdminAPI.getCodelist('section_product_portfolio').then((t) => {
        return t.codelist.map((m) => ({
          label: m.label,
          value: m._id,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  handleChange(e, field) {
    const { brand } = this.state;

    const newData = Object.assign({}, brand);

    newData[field] = e;

    this.setState({
      brand: newData,
    });
  }

  handleLocalChange(e, lang, field) {
    const { brand } = this.state;

    const newTranslations = brand[field].map((item) =>
      item.lang === lang ? { lang, value: e } : item,
    );
    if (!newTranslations.find((item) => item.lang === lang)) {
      newTranslations.push({ lang, value: e });
    }

    const newBrand = {
      ...brand,
      [field]: newTranslations,
    };

    this.setState({
      brand: newBrand,
    });
  }

  handleSectionLocalChange(e, lang, index, field) {
    const { brand } = this.state;

    const newSections = [...brand.sections];

    newSections[index] = {
      ...newSections[index],
      [field]: [
        ...newSections[index][field].filter((item) => item.lang !== lang),
        { lang, value: e },
      ],
    };

    const newTranslations = {
      ...brand,
      sections: newSections,
    };

    this.setState({
      brand: newTranslations,
    });
  }

  handleSectionChange(e, field, index) {
    const { brand } = this.state;
    const newSections = [...brand.sections];

    newSections[index] = {
      ...newSections[index],
      [field]: e,
    };

    const newBrand = {
      ...brand,
      sections: newSections,
    };

    this.setState({
      brand: newBrand,
    });
  }

  handleAddSection = () => {
    const translations = [{ lang: 'SK', value: '' }];
    this.setState((prevState) => ({
      brand: {
        ...prevState.brand,
        sections: [
          ...prevState.brand.sections,
          {
            banner_id: '',
            title: translations,
            description: translations,
            catalog_products_ids: [''],
          },
        ],
      },
      activeSectionLangs: [...prevState.activeSectionLangs, 'SK'],
    }));
  };

  handleRemoveSection = (indexToRemove) => {
    this.setState((prevState) => ({
      brand: {
        ...prevState.brand,
        sections: prevState.brand.sections.filter(
          (_, index) => index !== indexToRemove,
        ),
      },
      activeSectionLangs: prevState.activeSectionLangs.filter(
        (_, i) => i !== indexToRemove,
      ),
    }));
  };

  normalizeBrands(data, oldData) {
    return data.map((d) => {
      return {
        _id: d._id,
        label: d.brand,
        value: d.brand,
        show_in_filter:
          oldData && oldData.find((o) => o.value === d)
            ? oldData.find((o) => o.value === d).show_in_filter
            : true,
        delete_from_catalog: false,
        default_value: false,
        translations: d.translations || [],
      };
    });
  }

  normalizeBanners(data, oldData) {
    return data.map((d) => {
      return {
        _id: d._id,
        label: d.name,
        value: d.name,
        show_in_filter:
          oldData && oldData.find((o) => o.value === d)
            ? oldData.find((o) => o.value === d).show_in_filter
            : true,
        delete_from_catalog: false,
        default_value: false,
        translations: d.translations || [],
      };
    });
  }

  normalizeDataForApi(brand) {
    return {
      ...brand,
      banner_id: brand.banner_id._id || '',
      brand: brand.brand._id || '',
      brand_name: brand.brand.label,
      multimedium_logo_id: brand.multimedium_logo_id.value || '',
      portfolio_section_codelist_id: Array.isArray(
        brand.portfolio_section_codelist_id,
      )
        ? brand.portfolio_section_codelist_id.map((v) =>
            v && v.value ? v.value : '',
          )
        : [],
      sections: brand.sections.map((section) => ({
        ...section,
        banner_id: section.banner_id._id ? section.banner_id._id : '',
        catalog_products_ids: section.catalog_products_ids.map((product) =>
          product && product.value ? product.value : [''],
        ),
      })),
    };
  }

  async fetchBrands(query, parameters = {}) {
    try {
      const params = Object.assign({}, parameters);
      if (!params.q && params.q !== '') {
        params.q = query;
      }
      const res = await API.getBrandsAction(this.props.firm, params);
      const normalizedResult = this.normalizeBrands(res.fullBrands || []);
      return normalizedResult;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async fetchBanners(query, parameters = {}) {
    try {
      const params = Object.assign({}, parameters);
      const pageId = 'xPaint';
      if (!params.q && params.q !== '') {
        params.q = query;
      }
      params.country = [];

      const bannerData = await AdminAPI.getBannersAction(pageId, params);
      const normalizedData = this.normalizeBanners(bannerData.items);

      return normalizedData;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  fetchProducts(query) {
    try {
      return AdminAPI.getCatalogProductsAction({
        lang: this.props.firm,
        q: query,
      }).then((res) => {
        return res.items.map((item) => ({
          value: item._id,
          label: item.name,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  normalizeMultiselectValue(values) {
    return (Array.isArray(values) ? values : [values]).map((value) => {
      if (value && value.label && value.value) {
        return value;
      }

      if (value && value.value && value._id) {
        return {
          value: value._id,
          label: value.value,
        };
      }

      return value;
    });
  }

  validateTextInputs(portfolio) {
    let filled = true;
    const requiredFields = ['brand', 'multimedium_logo_id', 'banner_id'];
    Object.keys(portfolio)
      .filter((k) => requiredFields.includes(k))
      .map((key) => {
        if (!portfolio[key] || portfolio[key] === '') {
          filled = false;
        }
        return true;
      });
    return filled;
  }

  async saveBrand() {
    const { brand } = this.state;
    const { history, firm } = this.props;

    this.setState({ loading: true });
    const normalizedBrand = this.normalizeDataForApi(brand);
    const params = {};
    params.body = {
      portfolio: normalizedBrand,
    };

    if (!this.validateTextInputs(normalizedBrand)) {
      this.setState({
        error: __('Musíte vyplniť všetky polia'),
        loading: false,
      });
      return;
    }

    params.firm = this.props.firm;

    let createdPortfolio;
    try {
      if (brand._id) {
        createdPortfolio = await AdminAPI.putPortfolioBrandAction(
          brand._id,
          params,
        );
      } else {
        createdPortfolio = await AdminAPI.postPortfolioBrandAction(params);
      }

      const successMessage = brand._id
        ? __('Portfolio značky úspešne upravené')
        : __('Portfolio značky úspešne vytvorené');

      this.setState({
        loading: false,
        error: false,
        success: successMessage,
      });

      history.push(`/${firm}/brand-portfolio/${createdPortfolio._id}`);

      this.fetchPortfolio(createdPortfolio._id);
    } catch (e) {
      if (e.response && e.response.status && e.response.status === 409) {
        this.setState({
          error: __('Táto značka už má existujúce porfólio'),
          success: false,
          loading: false,
        });
        return;
      }
      this.setState({
        error: __('Portfolio sa nepodarilo upraviť alebo vytvoriť'),
        success: false,
        loading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Značka')} />;
  }

  render() {
    const {
      loading,
      notValidData,
      error,
      success,
      activeLang,
      brand,
      activeSectionLangs,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
        </Wrapper>

        <ContentWrapper>
          <SectionWrapper>
            <InputWrapper>
              <StyledLabel> {__('Značka*')}</StyledLabel>
              <SearchableSelect
                loadOptions={(query) => {
                  return this.fetchBrands(query);
                }}
                value={brand.brand}
                placeholder={__('Značka')}
                handleOnChange={(e) => this.handleChange(e, 'brand')}
              />
            </InputWrapper>

            <InputWrapper>
              <StyledLabel> {__('Logo*')}</StyledLabel>
              <SearchableSelect
                loadOptions={(query) => {
                  return fetchGraphics(query);
                }}
                value={brand.multimedium_logo_id}
                placeholder={__('Logo')}
                handleOnChange={(e) =>
                  this.handleChange(e, 'multimedium_logo_id')
                }
              />
            </InputWrapper>

            <InputWrapper>
              <StyledLabel> {__('Banner*')}</StyledLabel>
              <SearchableSelect
                loadOptions={(query) => this.fetchBanners(query)}
                value={brand.banner_id}
                placeholder={__('Banner')}
                handleOnChange={(e) => this.handleChange(e, 'banner_id')}
              />
            </InputWrapper>

            <InputWrapper>
              <StyledLabel>{__('Sekcia značky*')}</StyledLabel>
              <SearchableSelect
                isMulti
                value={brand.portfolio_section_codelist_id}
                loadOptions={() => {
                  return this.fetchSectionCodelist();
                }}
                placeholder={__('Sekcia značky')}
                handleOnChange={(e) =>
                  this.handleChange(e, 'portfolio_section_codelist_id')
                }
              />
            </InputWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <Row>
              <LanguageSwitch
                onChange={(lang) => {
                  this.setState({
                    activeLang: lang,
                  });
                  return true;
                }}
                activeId={activeLang}
              />
            </Row>
            <InputWrapper>
              <StyledLabel> {__('Krátky popis')}</StyledLabel>
              <Textarea
                style={{
                  width: rem(330),
                  height: rem(60),
                  resize: 'vertical',
                  lineHeight: rem(18),
                }}
                placeholder={__('Krátky popis')}
                onChange={(e) =>
                  this.handleLocalChange(
                    e.target.value,
                    activeLang,
                    'short_description',
                  )
                }
                value={
                  brand.short_description.filter(
                    (item) => item.lang === activeLang,
                  ).length > 0
                    ? brand.short_description.filter(
                        (item) => item.lang === activeLang,
                      )[0].value
                    : ''
                }
              />
            </InputWrapper>

            <InputWrapper>
              <StyledLabel> {__('Dlhý popis')}</StyledLabel>
              <Textarea
                style={{
                  width: rem(330),
                  height: rem(80),
                  resize: 'vertical',
                  lineHeight: rem(18),
                }}
                placeholder={__('Dlhý popis')}
                onChange={(e) =>
                  this.handleLocalChange(
                    e.target.value,
                    activeLang,
                    'long_description',
                  )
                }
                value={
                  brand.long_description.filter(
                    (item) => item.lang === activeLang,
                  ).length > 0
                    ? brand.long_description.filter(
                        (item) => item.lang === activeLang,
                      )[0].value
                    : ''
                }
              />
            </InputWrapper>

            <InputWrapper>
              <StyledLabel> {__('Titulok k produktom')}</StyledLabel>
              <StyledInput
                placeholder={__('Titulok k produktom')}
                onChange={(e) =>
                  this.handleLocalChange(
                    e.target.value,
                    activeLang,
                    'products_title',
                  )
                }
                value={
                  brand.products_title.filter(
                    (item) => item.lang === activeLang,
                  ).length > 0
                    ? brand.products_title.filter(
                        (item) => item.lang === activeLang,
                      )[0].value
                    : ''
                }
              />
            </InputWrapper>

            <InputWrapper>
              <StyledLabel> {__('URL')}</StyledLabel>
              <StyledInput
                placeholder={__('URL')}
                onChange={(e) =>
                  this.handleLocalChange(e.target.value, activeLang, 'url')
                }
                value={
                  brand.url.filter((item) => item.lang === activeLang).length >
                  0
                    ? brand.url.filter((item) => item.lang === activeLang)[0]
                        .value
                    : ''
                }
              />
            </InputWrapper>
          </SectionWrapper>

          <PublishedCountriesWrapper>
            <InputWrapper>
              <StyledLabel>{__('Sekcie')}</StyledLabel>
            </InputWrapper>
          </PublishedCountriesWrapper>

          {brand.sections.map((section, index) => (
            <SectionWrapper key={section}>
              <TitleWrapper>
                <H2>
                  {__('Sekcia')} {index + 1}.
                </H2>
                <Close
                  onClick={() => this.handleRemoveSection(index)}
                  src="/images/icons/close_transparent.svg"
                  height="20px"
                />
              </TitleWrapper>

              <Row>
                <LanguageSwitch
                  onChange={(lang) => {
                    const activeLangs = [...activeSectionLangs];
                    activeLangs[index] = lang;
                    this.setState({ activeSectionLangs: activeLangs });
                    return true;
                  }}
                  activeId={activeSectionLangs[index]}
                />
              </Row>

              <InputWrapper>
                <StyledLabel> {__('Banner')}</StyledLabel>
                <SearchableSelect
                  loadOptions={(query) => this.fetchBanners(query)}
                  value={brand.sections[index].banner_id}
                  placeholder={__('Banner')}
                  handleOnChange={(e) =>
                    this.handleSectionChange(e, 'banner_id', index)
                  }
                />
              </InputWrapper>

              <InputWrapper>
                <StyledLabel> {__('Názov')}</StyledLabel>
                <StyledInput
                  placeholder={__('Názov')}
                  onChange={(e) => {
                    this.handleSectionLocalChange(
                      e.target.value,
                      activeSectionLangs[index],
                      index,
                      `title`,
                    );
                  }}
                  value={
                    brand.sections[index].title.filter(
                      (item) => item.lang === activeSectionLangs[index],
                    ).length > 0
                      ? brand.sections[index].title.filter(
                          (item) => item.lang === activeSectionLangs[index],
                        )[0].value
                      : ''
                  }
                />
              </InputWrapper>

              <InputWrapper>
                <StyledLabel> {__('Popis')}</StyledLabel>
                <Textarea
                  style={{
                    width: rem(330),
                    height: rem(60),
                    resize: 'vertical',
                    lineHeight: rem(18),
                  }}
                  placeholder={__('Popis')}
                  onChange={(e) => {
                    this.handleSectionLocalChange(
                      e.target.value,
                      activeSectionLangs[index],
                      index,
                      `description`,
                    );
                  }}
                  value={
                    brand.sections[index].description.filter(
                      (item) => item.lang === activeSectionLangs[index],
                    ).length > 0
                      ? brand.sections[index].description.filter(
                          (item) => item.lang === activeSectionLangs[index],
                        )[0].value
                      : ''
                  }
                />
              </InputWrapper>

              <InputWrapper>
                <StyledLabel> {__('Produkty')}</StyledLabel>
                <SearchableSelect
                  loadOptions={(query) => this.fetchProducts(query)}
                  placeholder={__('Produkty')}
                  handleOnChange={(e) =>
                    this.handleSectionChange(e, 'catalog_products_ids', index)
                  }
                  value={brand.sections[index].catalog_products_ids}
                  isMulti
                />
              </InputWrapper>
            </SectionWrapper>
          ))}

          <ButtonWrapper>
            <Button
              loading={loading}
              onClick={() => {
                this.handleAddSection();
              }}
              primary
            >
              {__('Pridať sekciu')}
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
        <ButtonRelativeWrapper>
          <Button
            loading={loading}
            onClick={() => {
              this.saveBrand();
            }}
            primary
          >
            {getIdFromProps(this.props) === 'new'
              ? __('Uložiť')
              : __('Upraviť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

BrandPortfolioDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BrandPortfolioDetail);
