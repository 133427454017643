import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';
import { Input, Button, Select, Message } from 'oxyrion-ui/lib';
import Icon from 'oxyrion-ui/lib/Icon';
import { connect } from '../../Store';
import { getIdFromProps, __, formatPrice } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import SearchableSelect from '../../Components/SearchableSelect';
import PopUpWrapper from '../../Components/PopUpWrapper';
import AddNewToning from '../../Components/AddNewToning';
import toningSystemBases from '../../ContentConfig/toningSystemsBases';
import ConfirmDialog from '../../Components/ConfirmDialog';
import {
  AnimatedFormMessageWrapper,
  DHeader,
  P,
} from '../../Components/ReusableComponents';
import TableV3 from '../../Components/TableV3';
import editIcon from './edit.svg';

import API from '../../API';

const CustomSelect = styled(Select)`
  min-width: ${rem(200)};
  margin-top: 10px;
`;

const ButtonsArea = styled.div`
  padding: ${rem(20)}
  justify-content: start;
  display: flex;
`;

const DeleteWrapper = styled.div`
  g {
    fill: ${({ theme }) => theme.color.danger};
  }
  text-align: right;
`;

const Span = styled.span`
  cursor: pointer;
`;

const CustomInput = styled(Input)`
  max-width: ${rem(300)};
  margin: ${rem(10)} 0;
  padding: 0;
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  width: 90%;
  padding-left: ${rem(20)};
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(300)};
  padding-top: ${rem(11)};
  margin-right: ${rem(7)};
  margin-left: ${rem(30)};
`;

const ColorPicker = styled.div`
  border-radius: ${rem(4)};
  border: #eee 1px solid;
  height: ${rem(36)};
  width: ${rem(36)};
`;

const CustomSelectWrapper = styled.div`
  height: ${rem(40)};
`;

const EditIcon = styled.img`
  margin-right: ${rem(30)};
  width: ${rem(15)};
  :hover {
    background: #eee;
    border-radius: ${rem(2)};
  }
`;

class ToningSystemDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteLoading: false,
      showConfirmDialog: false,
      showSaveDialog: false,
      saveLoading: false,
      saveDisabled: true,
      brandError: false,
      nameError: false,
      toningSystem: {
        colourants: [],
      },
      systemToEdit: undefined,
    };
  }

  async componentWillMount() {
    this.setState({ loading: true });
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      await this.fetchToningSystemData(id);
    }
  }

  fetchBrand(query) {
    try {
      return API.getBrandsAction(this.props.firm, { q: query }).then(res => {
        return res.brands.map(item => ({
          value: item,
          label: item,
          brand: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchToningSystemData(id) {
    const { firm } = this.props;
    try {
      const toningSystem = await API.getToningSystemAction(firm, id);
      toningSystem.brand = toningSystem.nav_brand_id;
      this.setState({
        toningSystem,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __(
              'Na zobrazenie tónovacieho systému nemáte potrebné práva.',
            ),
          });
          break;
        case 404:
          this.setState({
            error: __('Tónovací systém sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  checkData() {
    const { toningSystem } = this.state;
    let { nameError, brandError } = this.state;
    let valid = true;

    if (toningSystem.name.length < 1) {
      valid = false;
      nameError = true;
    }
    if (!toningSystem.brand) {
      valid = false;
      brandError = true;
    }
    if (!valid) {
      this.setState({
        brandError,
        nameError,
      });
    }

    return valid;
  }

  async saveToningSystem(forced = false) {
    let { toningSystem } = this.state;
    const { history, firm } = this.props;
    this.setState({
      saveLoading: true,
      brandError: false,
      nameError: false,
      success: undefined,
    });
    if (this.checkData()) {
      try {
        const data = {
          name: toningSystem.name,
          base: toningSystem.base,
          nav_brand_id: toningSystem.brand.brand,
          colourants: toningSystem.colourants,
        };
        if (toningSystem._id) {
          await API.putToningSystemAction(firm, toningSystem._id, {
            data,
            forced,
          });
        } else {
          const result = await API.postToningSystemAction(firm, { data });
          if (result && getIdFromProps(this.props) === 'new') {
            history.push(`/${firm}/toningSystems/${result._id}`);
            toningSystem = result;
          }
        }

        this.setState({
          saveDisabled: true,
          toningSystem,
          systemToEdit: undefined,
          success: __('Tónovací systém bol úspešne uložený'),
        });
      } catch (e) {
        if (e.response && e.response.status === 409) {
          this.setState({
            showForcedSaveDialog: true,
          });
        } else {
          this.setState({
            error: __('Pri ukladaní dát sa vyskytla chyba'),
          });
        }
      }
    }
    this.setState({
      saveLoading: false,
    });
  }

  async deleteToningSystem(forced = false) {
    const { toningSystem } = this.state;
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await API.deleteToningSystemAction(firm, toningSystem._id, { forced });
      history.push(`/${firm}/toningSystems/`);
    } catch (e) {
      if (e.response && e.response.status === 409) {
        this.setState({
          showForcedDeleteDialog: true,
          showConfirmDialog: false,
        });
      } else {
        this.setState({
          error: __('Pri mazávaní dát sa vyskytla chyba'),
        });
      }
    }
    this.setState({
      deleteLoading: false,
    });
  }

  addNew(newSystem) {
    const { toningSystem, systemToEdit, editSystemIndex } = this.state;
    const system = {
      name: newSystem.coloring,
      name_short: newSystem.shortcut,
      nav_product_id: newSystem.product._id,
      product_name: newSystem.product.Description,
      product_price: newSystem.product.Unit_Price,
      product_volume: newSystem.product.Unit_Volume,
      color: {
        R: Number(newSystem.RGB_R),
        G: Number(newSystem.RGB_G),
        B: Number(newSystem.RGB_B),
      },
    };

    if (systemToEdit && editSystemIndex > -1) {
      toningSystem.colourants.splice(editSystemIndex, 0, system);
    } else {
      toningSystem.colourants.push(system);
    }
    this.setState({
      toningSystem,
      editSystemIndex: -1,
      saveDisabled: false,
      systemToEdit: undefined,
    });
  }

  editField(index) {
    const { toningSystem } = this.state;
    const systemToEdit = toningSystem.colourants[index];
    toningSystem.colourants.splice(index, 0);
    this.setState({ toningSystem, systemToEdit, editSystemIndex: index });
  }

  toggleDeleteSystem(index) {
    const { toningSystem } = this.state;
    toningSystem.colourants.splice(index, 1);

    this.setState({ toningSystem, saveDisabled: false });
  }

  showConfirmDialog() {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
    });
  }

  showSaveDialog() {
    this.setState({
      showSaveDialog: !this.state.showSaveDialog,
    });
  }

  changeValue(value, field) {
    const { toningSystem } = this.state;
    if (field === 'name') {
      toningSystem.name = value;
    } else if (field === 'brand') {
      toningSystem.brand = value;
    } else if (field === 'base') {
      toningSystem.base = value;
    }

    this.setState({
      toningSystem,
      saveDisabled: false,
    });
  }

  discardEdit() {
    const { toningSystem, systemToEdit } = this.state;
    toningSystem.colourants.push(systemToEdit);

    this.setState({
      toningSystem,
      systemToEdit: undefined,
    });
  }
  createColumns() {
    return [
      {
        accessor: 'name',
        Header: () => <DHeader>{__('Farbivo')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'name_short',
        Header: () => <DHeader>{__('Skratka')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },
      {
        accessor: 'product_name',
        Header: () => <DHeader>{__('Produkt NAV')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 300,
      },
      {
        accessor: 'product_price',
        Header: () => <DHeader>{__('Cena')}</DHeader>,
        Cell: props => {
          return <P>{formatPrice(props.value)}</P>;
        },
        maxWidth: 75,
      },
      {
        accessor: 'product_volume',
        Header: () => <DHeader>{__('Objem (l)')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },

      {
        accessor: 'color',
        Header: () => <DHeader>{__('R')}</DHeader>,
        Cell: props => {
          return <P>{props.value.R}</P>;
        },
        maxWidth: 50,
      },
      {
        accessor: 'color',
        Header: () => <DHeader>{__('G')}</DHeader>,
        Cell: props => {
          return <P>{props.value.G}</P>;
        },
        maxWidth: 50,
      },
      {
        accessor: 'color',
        Header: () => <DHeader>{__('B')}</DHeader>,
        Cell: props => {
          return <P>{props.value.B}</P>;
        },
        maxWidth: 50,
      },
      {
        accessor: 'color',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <ColorPicker
                style={{
                  backgroundColor: `rgba(${props.value.R},${props.value.G},${
                    props.value.B
                  },1)`,
                }}
              />
            </P>
          );
        },
        maxWidth: 58,
      },
      {
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <DeleteWrapper>
                <Span onClick={() => this.toggleDeleteSystem(props.row._index)}>
                  <Span onClick={() => this.editField(props.row._index)}>
                    <EditIcon src={editIcon} />
                  </Span>

                  <Icon name="close" color={baseTheme.color.danger} size="m" />
                </Span>
              </DeleteWrapper>
            </P>
          );
        },
        maxWidth: 100,
      },
    ];
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Tónovací systém')}>
          <ControlBarButton
            small
            danger
            onClick={() => this.showConfirmDialog()}
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      deleteLoading,
      showConfirmDialog,
      loading,
      error,
      success,
      toningSystem,
      systemToEdit,
      showSaveDialog,
      saveLoading,
      saveDisabled,
      brandError,
      nameError,
      showForcedSaveDialog,
      showForcedDeleteDialog,
    } = this.state;
    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.showConfirmDialog()}
        >
          <ConfirmDialog
            message={__('Prajete si odstániť vzorkovník ?')}
            onDismiss={() => this.showConfirmDialog()}
            onConfirm={() => this.deleteToningSystem()}
            error={error}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showForcedSaveDialog}
          small
          onClose={() => this.setState({ showForcedSaveDialog: false })}
        >
          <ConfirmDialog
            message={__(
              'Tonovací systém sa používa v receptúre. Chcete ho upraviť ?',
            )}
            onDismiss={() => this.setState({ showForcedSaveDialog: false })}
            onConfirm={() => {
              this.setState({ showForcedSaveDialog: false });
              this.saveToningSystem(true);
            }}
            error={error}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showForcedDeleteDialog}
          small
          onClose={() => this.setState({ showForcedDeleteDialog: false })}
        >
          <ConfirmDialog
            message={__(
              'Tonovací systém sa používa v receptúre. Prajete si odstániť vzorkovník ?',
            )}
            onDismiss={() => this.setState({ showForcedDeleteDialog: false })}
            onConfirm={() => this.deleteToningSystem(true)}
            error={error}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showSaveDialog}
          small
          onClose={() => this.showSaveDialog()}
        >
          <ConfirmDialog
            message={__('Prajete si uložiť zmeny ?')}
            onDismiss={() => this.showSaveDialog()}
            onConfirm={() => this.saveToningSystem()}
            error={error}
            loading={saveLoading}
          />
        </PopUpWrapper>

        <BasicInfoWrapper>
          <CustomInput
            placeholder={__('Názov')}
            right
            value={toningSystem.name}
            error={nameError}
            onChange={e => this.changeValue(e.target.value, 'name')}
          />
          <SearchableSelectWrapper>
            <SearchableSelect
              value={toningSystem.brand}
              loadOptions={query => this.fetchBrand(query)}
              placeholder={__('Vyhľadať značku')}
              name={toningSystem.brand}
              error={brandError}
              handleOnChange={e => this.changeValue(e, 'brand')}
            />
          </SearchableSelectWrapper>
          <CustomSelectWrapper>
            <CustomSelect
              name={__('Báza')}
              size="s"
              value={toningSystem.base}
              onChange={e => this.changeValue(e.target.value, 'base')}
            >
              {toningSystemBases.map(t => {
                return (
                  <option
                    key={t.key}
                    value={t.value}
                    selected={t.value === toningSystem.base}
                  >
                    {t.display_name}
                  </option>
                );
              })}
            </CustomSelect>
          </CustomSelectWrapper>
        </BasicInfoWrapper>
        <AnimatedFormMessageWrapper display={!systemToEdit}>
          <TableV3
            columns={this.createColumns()}
            minWidth={10}
            loading={loading}
            data={toningSystem.colourants}
            getTdProps={() => {
              return { style: { padding: 0, margin: 'auto' } };
            }}
            getTheadThProps={(state, rowInfo, column) => {
              if (column && column.sortable) {
                return {
                  onClick: e => {
                    this.sortByV2(e.target.innerHTML);
                  },
                };
              }
              return {};
            }}
            className="-highlight -striped"
            offset={300}
          />
        </AnimatedFormMessageWrapper>

        <AddNewToning
          onSubmit={o => this.addNew(o)}
          discard={() => this.discardEdit()}
          systemToEdit={systemToEdit}
        />
        {/* </Tbody>
          </Table>
        </TableWrapper> */}

        <ButtonsArea>
          <Button
            primary
            loading={saveLoading}
            disabled={saveDisabled || systemToEdit}
            onClick={() => this.saveToningSystem()}
          >
            {__('Uložiť')}
          </Button>
        </ButtonsArea>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ToningSystemDetail);
