import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  Button,
  Icon,
  ThemeAdmin,
  Label,
  Input,
  Checkbox,
} from 'oxyrion-ui/lib';
import TableV3 from '../../Components/TableV3';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import {
  ButtonRelativeWrapper,
  DHeader,
  P,
  ClickableIconWrapper,
  NumberInput,
} from '../../Components/ReusableComponents';
import LanguageSwitch from '../../Components/LanguageSwitch';
import AdminGroupForms from '../../Components/AdminGroupForm';
import SearchableSelect from '../../Components/SearchableSelect';
import API from '../../API';
import MultimediaImagesWidget from '../../Components/MultimediaImagesWidget';
import { CAHNNELS } from '../../ContentConfig/languagesArray';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${rem(10)};
  flex-direction: column;
  padding-bottom: ${rem(50)};
  ${({ theme }) => theme.media.m`
  flex-direction: row;
  `};
`;

const InputWrapper = styled.div`
  width: ${rem(50)};
  margin: ${rem(2)};
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(500)};
`;

const SubWrapper = styled.div`
  width: 100%;
  padding: ${rem(3)};

  ${({ theme }) => theme.media.m`
  width: 50%;
  `};
`;

const LangWrapper = styled.div`
  background: #eeeeee;
  padding: ${rem(8)};
`;

const ImagesHeader = styled.div`
  height: ${rem(50)};
  padding-left: ${rem(20)};
  justify-content: start;
  display: flex;
  align-items: center;
  background: #eee;
  margin-top: ${rem(20)};
  font-weight: 300;
  font-size: ${rem(16)};
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => (props.noMargin ? rem(0) : rem(5))};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${rem(20)};
`;

const GroupsWrapper = styled.div`
  margin-bottom: ${rem(20)};
`;

const AddNewRow = styled.div`
  margin-top: ${rem(20)};
  display: flex;
  flex-direction: row;
`;

const PlusWrapper = styled.div`
  height: ${rem(34)};
  width: ${rem(34)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PriceWrapper = styled.div`
  display: flex;
`;

const Price = styled.div`
  margin-left: ${rem(90)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLabel = styled(Label)`
  padding-right: ${rem(5)};
  font-weight: 500;
`;

const MultimediaImagesWrapper = styled.div``;

class Variants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      productToAddVariant: undefined,
      productErrorVariant: false,
      productToAddComplementar: undefined,
      productToAddCover: undefined,
    };
  }

  fetchProducts(query) {
    try {
      return API.getProductsAction(this.props.firm, {
        q: query,
        limit: 100,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: item.Description,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  handleOnChange(value, type) {
    let {
      productToAddVariant,
      productToAddComplementar,
      productToAddCover,
    } = this.state;
    if (type === 'variants') {
      productToAddVariant = value;

      this.setState({ productToAddVariant, productErrorVariant: false });
    } else if (type === 'complementars') {
      productToAddComplementar = value;

      this.setState({
        productToAddComplementar,
        productErrorComplementar: false,
      });
    } else if (type === 'covers') {
      productToAddCover = value;

      this.setState({
        productToAddCover,
        productErrorCover: false,
      });
    }
  }

  createColumns() {
    return [
      {
        accessor: 'No',
        Header: () => <DHeader>{__('Číslo výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },
      {
        accessor: 'name',
        Header: () => <DHeader>{__('Názov výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: '',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <ClickableIconWrapper
              onClick={() => this.deleteVariant(props.row._index)}
            >
              <Icon
                name="close"
                size="l"
                color={
                  props.row._index === this.state.selected ? 'white' : 'black'
                }
              />
            </ClickableIconWrapper>
          );
        },
        width: 70,
      },
    ];
  }

  createColumnsComplementars() {
    return [
      {
        accessor: 'No',
        Header: () => <DHeader>{__('Číslo výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },
      {
        accessor: 'name',
        Header: () => <DHeader>{__('Názov výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'primary_hardener',
        Header: () => <DHeader>{__('Primárne tužidlo')}</DHeader>,
        Cell: props => {
          return (
            <P>
              <Checkbox
                checked={props.value}
                onChange={() =>
                  this.props.handleComplemetaryProductCountChange(
                    props.original.No,
                    !props.value,
                    true,
                  )
                }
              />
            </P>
          );
        },
      },
      {
        accessor: 'count',
        Header: () => <DHeader>{__('Množstvo')}</DHeader>,
        maxWidth: 100,
        Cell: props => {
          return (
            <P>
              <InputWrapper>
                <Input
                  type="number"
                  value={props.value}
                  placeholder="1"
                  onChange={e =>
                    this.props.handleComplemetaryProductCountChange(
                      props.original.No,
                      Number(e.target.value),
                    )
                  }
                />
              </InputWrapper>
            </P>
          );
        },
      },
      {
        accessor: '',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <ClickableIconWrapper
              onClick={() => this.deleteComplementar(props.row._index)}
            >
              <Icon name="close" size="l" color="black" />
            </ClickableIconWrapper>
          );
        },
        width: 70,
      },
    ];
  }
  createColumnsCovers() {
    return [
      {
        accessor: 'No',
        Header: () => <DHeader>{__('Číslo výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },
      {
        accessor: 'name',
        Header: () => <DHeader>{__('Názov výrobku')}</DHeader>,
        Cell: props => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: '',
        Header: () => <DHeader>{__('')}</DHeader>,
        Cell: props => {
          return (
            <ClickableIconWrapper
              onClick={() => this.props.deleteCover(props.row._index)}
            >
              <Icon name="close" size="l" color="black" />
            </ClickableIconWrapper>
          );
        },
        width: 70,
      },
    ];
  }

  deleteVariant(index) {
    const { deleteVariant } = this.props;

    deleteVariant(index);
  }

  deleteComplementar(index) {
    const { deleteComplementar } = this.props;

    deleteComplementar(index);
  }

  addProduct(type) {
    const { addVariant, addComplementar, addCover, variants } = this.props;
    const {
      productToAddVariant,
      productToAddComplementar,
      productToAddCover,
    } = this.state;
    if (type === 'variant') {
      if (!productToAddVariant) {
        this.setState({
          productErrorVariant: __('Nevyplnená hodnota'),
        });
        return;
      }

      addVariant(
        {
          name: productToAddVariant.product.Description,
          No: productToAddVariant.product.No,
          _id: productToAddVariant.product.No,
        },
        variants.length === 0,
        productToAddVariant.product.Unit_Price,
      );

      this.setState({
        productToAddVariant: undefined,
      });
    }
    if (type === 'complementars') {
      if (!productToAddComplementar) {
        this.setState({
          productErrorComplementar: __('Nevyplnená hodnota'),
        });

        return;
      }
      addComplementar({
        name: productToAddComplementar.product.Description,
        No: productToAddComplementar.product.No,
        _id: productToAddComplementar.product.No,
        count: 1,
      });

      this.setState({
        productToAddComplementar: undefined,
      });
    }
    if (type === 'covers') {
      if (!productToAddCover) {
        this.setState({
          productErrorCover: __('Nevyplnená hodnota'),
        });
        return;
      }

      addCover({
        name: productToAddCover.product.Description,
        No: productToAddCover.product.No,
        _id: productToAddCover.product.No,
      });

      this.setState({
        productToAddCover: undefined,
      });
    }
  }

  normalizeVariantParams(params) {
    const newParams = params;

    if (!newParams) {
      return [];
    }
    const baseValue =
      params.find(p => p.system_name === 'base') &&
      params.find(p => p.system_name === 'base').value;
    const colorValue =
      params.find(p => p.system_name === 'shade') &&
      params.find(p => p.system_name === 'shade').value;

    if (
      baseValue &&
      baseValue.length > 0 &&
      baseValue !== '-' &&
      newParams.find(p => p.system_name === 'shade')
    ) {
      newParams.find(p => p.system_name === 'shade').disabled = true;
      newParams.find(p => p.system_name === 'shade').value = '';
    } else if (newParams.find(p => p.system_name === 'shade')) {
      newParams.find(p => p.system_name === 'shade').disabled = false;
    }

    if (
      colorValue &&
      colorValue.length > 0 &&
      colorValue !== '-' &&
      newParams.find(p => p.system_name === 'base')
    ) {
      newParams.find(p => p.system_name === 'base').disabled = true;
      newParams.find(p => p.system_name === 'base').value = '';
      newParams.find(p => p.system_name === 'base').label = '-';
    } else if (newParams.find(p => p.system_name === 'base')) {
      newParams.find(p => p.system_name === 'base').disabled = false;
    }

    if (
      !this.props.colocardsIds.length &&
      newParams.find(p => p.system_name === 'shade')
    ) {
      newParams.find(p => p.system_name === 'shade').disabled = true;
      newParams.find(p => p.system_name === 'shade').value = '';
    }

    return params;
  }

  tableBackgroundColor(index, selected) {
    if (index === selected) {
      return ThemeAdmin.color.primary;
    }
    if (index % 2 === 0) {
      return 'white';
    }
    return '#eee';
  }

  render() {
    const {
      loading,
      variants,
      changeSelectedVariant,
      changeLanguageForVariants,
      activeLanguage,
      variantOnChange,
      parentPublished,
      variantParamsOnChange,
      saveCard,
      handleUnitChange,
      colocardsIds,
    } = this.props;

    const {
      selected,
      productToAddVariant,
      productErrorVariant,
      productToAddComplementar,
      productErrorComplementar,
      productToAddCover,
      productErrorCover,
    } = this.state;

    const variantParams = this.normalizeVariantParams(
      variants[selected] && variants[selected].variantParams,
    );

    return (
      <React.Fragment>
        <TableV3
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => {
                  this.setState(
                    {
                      selected: rowInfo.index,
                    },
                    () => changeSelectedVariant(rowInfo.index),
                  );
                },
                style: {
                  background: this.tableBackgroundColor(
                    rowInfo.index,
                    selected,
                  ),
                  color: rowInfo.index === selected ? 'white' : 'black',
                  cursor: 'pointer',
                },
              };
            }
            return {};
          }}
          columns={this.createColumns()}
          minWidth={10}
          loading={loading}
          noDataText={__('Žiadne varianty')}
          data={variants}
          getTdProps={() => {
            return { style: { padding: 0, margin: 'auto' } };
          }}
          getTheadThProps={(state, rowInfo, column) => {
            if (column && column.sortable) {
              return {
                onClick: () => {},
              };
            }
            return {};
          }}
          className="-highlight -striped"
        />
        <AddNewRow>
          <SearchableSelectWrapper>
            <SearchableSelect
              value={productToAddVariant}
              loadOptions={query => this.fetchProducts(query)}
              placeholder={__('Zadajte kód alebo názov tovaru')}
              name={productToAddVariant}
              error={productErrorVariant}
              handleOnChange={e => this.handleOnChange(e, 'variants')}
            />
          </SearchableSelectWrapper>
          <PlusWrapper onClick={() => this.addProduct('variant')}>
            <Icon name="plus" size="l" />
          </PlusWrapper>
        </AddNewRow>
        {variants[selected] && (
          <React.Fragment>
            <ImagesHeader>
              {__(`Detail variantu ${variants[selected].name}`)}
            </ImagesHeader>
            <LanguageSwitch
              onChange={id => {
                return changeLanguageForVariants(id);
              }}
              activeId={activeLanguage}
            />
            <Wrapper key={variants[selected].name}>
              <SubWrapper>
                <LangWrapper>
                  <Label> {__('Názov')}</Label>
                  <Input
                    style={{
                      width: '90%',
                    }}
                    placeholder={__('Zadajte názov')}
                    onChange={e =>
                      variantOnChange(e, 'variantName', activeLanguage)
                    }
                    value={
                      variants[selected].translations.find(
                        t => t.lang === activeLanguage,
                      )
                        ? variants[selected].translations.find(
                            t => t.lang === activeLanguage,
                          ).variantName
                        : ''
                    }
                  />
                  <Label> {__('Názov pre eshop')}</Label>
                  <Input
                    style={{
                      width: '90%',
                    }}
                    placeholder={__('Zadajte názov pre eshop')}
                    onChange={e =>
                      variantOnChange(e, 'variantEshopName', activeLanguage)
                    }
                    value={
                      variants[selected].translations.find(
                        t => t.lang === activeLanguage,
                      )
                        ? variants[selected].translations.find(
                            t => t.lang === activeLanguage,
                          ).variantEshopName
                        : ''
                    }
                  />
                  <Label> {__('Validná skladba názvu pre heureku:')}</Label>
                  <StyledLabel>
                    {variants[selected].translations.find(
                      t => t.lang === activeLanguage,
                    )
                      ? variants[selected].translations.find(
                          t => t.lang === activeLanguage,
                        ).heurekaNameTooltip
                      : ''}
                  </StyledLabel>
                  <Label> {__('Doporučená cena')}</Label>
                  <PriceWrapper>
                    <NumberInput
                      style={{ width: rem(100) }}
                      placeholder={__('0')}
                      onChange={e =>
                        variantOnChange(e, 'variantPrice', activeLanguage)
                      }
                      value={
                        variants[selected].translations.find(
                          t => t.lang === activeLanguage,
                        )
                          ? variants[selected].translations.find(
                              t => t.lang === activeLanguage,
                            ).variantPrice.amount
                          : 0
                      }
                    />

                    <Price>
                      {variants[selected].translations.find(
                        t => t.lang === activeLanguage,
                      )
                        ? variants[selected].translations.find(
                            t => t.lang === activeLanguage,
                          ).variantPrice.currency
                        : ''}
                    </Price>
                  </PriceWrapper>
                  <Label> {__('Retired variant')}</Label>
                  <Checkbox
                    checked={
                      variants[selected] &&
                      variants[selected].Retired &&
                      variants[selected].Retired.find(
                        p => p.firm === activeLanguage,
                      ) &&
                      variants[selected].Retired.find(
                        p => p.firm === activeLanguage,
                      ).value
                    }
                    disabled
                  />
                </LangWrapper>
                <Label> {__('Publikácia')}</Label>
                <InputRow>
                  <CheckBoxWrapper>
                    <Checkbox
                      disabled={!parentPublished.CRM}
                      checked={
                        variants[selected].publishing.find(
                          p => p.chanel === 'CRM',
                        ).published
                      }
                      onChange={e =>
                        variantOnChange(
                          e,
                          'CRM',
                          '',
                          variants[selected].publishing.find(
                            p => p.chanel === 'CRM',
                          ).published,
                        )
                      }
                    />
                    <Label> {__('CRM')}</Label>
                  </CheckBoxWrapper>
                  <CheckBoxWrapper>
                    <Checkbox
                      disabled={!parentPublished.B2B}
                      checked={
                        variants[selected].publishing.find(
                          p => p.chanel === 'B2B',
                        ).published
                      }
                      onChange={e =>
                        variantOnChange(
                          e,
                          'B2B',
                          '',
                          variants[selected].publishing.find(
                            p => p.chanel === 'B2B',
                          ).published,
                        )
                      }
                    />
                    <Label> {__('B2B')}</Label>
                  </CheckBoxWrapper>

                  {CAHNNELS.map(chanel => {
                    return (
                      <CheckBoxWrapper>
                        <Checkbox
                          // disabled={!parentPublished.xFarby}
                          checked={
                            variants[selected].publishing.find(
                              p => p.chanel === chanel,
                            ) &&
                            variants[selected].publishing.find(
                              p => p.chanel === chanel,
                            ).published
                          }
                          onChange={e => {
                            if (
                              !variants[selected].publishing.find(
                                p => p.chanel === chanel,
                              )
                            ) {
                              variants[selected].publishing.push({
                                chanel,
                                published: false,
                              });
                            }

                            variantOnChange(
                              e,
                              chanel,
                              '',
                              variants[selected].publishing.find(
                                p => p.chanel === chanel,
                              ).published,
                            );
                          }}
                        />
                        <Label> {__(chanel)}</Label>
                      </CheckBoxWrapper>
                    );
                  })}
                </InputRow>
                <InputRow>
                  <CheckBoxWrapper>
                    <Checkbox
                      checked={variants[selected].exportable}
                      onChange={e =>
                        variantOnChange(
                          e,
                          'exportable',
                          '',
                          variants[selected].exportable,
                        )
                      }
                    />
                    <Label> {__('Exportovať pre Farlesk')}</Label>
                  </CheckBoxWrapper>
                </InputRow>

                <InputRow>
                  <CheckBoxWrapper>
                    <Checkbox
                      checked={variants[selected].obnova_exportable}
                      onChange={e =>
                        variantOnChange(
                          e,
                          'obnova_exportable',
                          '',
                          variants[selected].obnova_exportable,
                        )
                      }
                    />
                    <Label> {__('Exportovať pre Obnova s.r.o')}</Label>
                  </CheckBoxWrapper>
                </InputRow>

                <InputRow>
                  <CheckBoxWrapper>
                    <Checkbox
                      checked={variants[selected].exportable_for_kopelent}
                      onChange={e =>
                        variantOnChange(
                          e,
                          'obnova_exportable',
                          '',
                          variants[selected].exportable_for_kopelent,
                        )
                      }
                    />
                    <Label> {__('Exportovať pre Kopelent')}</Label>
                  </CheckBoxWrapper>
                </InputRow>
                <GroupsWrapper>
                  <AdminGroupForms
                    additionalProps={{
                      colocardsIds,
                    }}
                    groups={[
                      {
                        groupName: '',
                        params: variantParams,
                      },
                    ]}
                    onChange={(
                      value,
                      groupIndex,
                      fieldIndex,
                      rowIndex,
                      columnIndex,
                    ) =>
                      variantParamsOnChange(
                        value,
                        -1,
                        fieldIndex,
                        rowIndex,
                        columnIndex,
                        selected,
                      )
                    }
                    handleUnitChange={(
                      groupIndex,
                      fieldIndex,
                      rowIndex,
                      columnIndex,
                      value,
                    ) =>
                      handleUnitChange(
                        -1,
                        fieldIndex,
                        rowIndex,
                        columnIndex,
                        value,
                      )
                    }
                  />
                </GroupsWrapper>
                <Label> {__('Komplementárne produkty')}</Label>
                <TableV3
                  columns={this.createColumnsComplementars()}
                  minWidth={10}
                  loading={loading}
                  data={variants[selected].complementarsProducts}
                  noDataText={__('Žiadne komplementárne produkty')}
                  getTdProps={() => {
                    return { style: { padding: 0, margin: 'auto' } };
                  }}
                  getTheadThProps={(state, rowInfo, column) => {
                    if (column && column.sortable) {
                      return {
                        onClick: () => {},
                      };
                    }
                    return {};
                  }}
                  className="-highlight -striped"
                />
                <AddNewRow>
                  <SearchableSelectWrapper>
                    <SearchableSelect
                      value={productToAddComplementar}
                      loadOptions={query => this.fetchProducts(query)}
                      placeholder={__('Zadajte kód alebo názov tovaru')}
                      name={productToAddComplementar}
                      error={productErrorComplementar}
                      handleOnChange={e =>
                        this.handleOnChange(e, 'complementars')
                      }
                    />
                  </SearchableSelectWrapper>
                  <PlusWrapper onClick={() => this.addProduct('complementars')}>
                    <Icon name="plus" size="l" />
                  </PlusWrapper>
                </AddNewRow>

                <Label> {__('Obal pre plnenie do spreja')}</Label>
                <TableV3
                  columns={this.createColumnsCovers()}
                  minWidth={10}
                  loading={loading}
                  data={
                    variants[selected].covers_products
                      ? variants[selected].covers_products
                      : []
                  }
                  noDataText={__('Žiadne obaly')}
                  getTdProps={() => {
                    return { style: { padding: 0, margin: 'auto' } };
                  }}
                  getTheadThProps={(state, rowInfo, column) => {
                    if (column && column.sortable) {
                      return {
                        onClick: () => {},
                      };
                    }
                    return {};
                  }}
                  className="-highlight -striped"
                />
                <AddNewRow>
                  <SearchableSelectWrapper>
                    <SearchableSelect
                      value={productToAddCover}
                      loadOptions={query => this.fetchProducts(query)}
                      placeholder={__('Zadajte kód alebo názov tovaru')}
                      name={productToAddCover}
                      error={productErrorCover}
                      handleOnChange={e => this.handleOnChange(e, 'covers')}
                    />
                  </SearchableSelectWrapper>
                  <PlusWrapper onClick={() => this.addProduct('covers')}>
                    <Icon name="plus" size="l" />
                  </PlusWrapper>
                </AddNewRow>
              </SubWrapper>
            </Wrapper>

            <MultimediaImagesWrapper>
              <ImagesHeader>{__('Obrázky variantu - fotobanka')}</ImagesHeader>
              <MultimediaImagesWidget
                // titleImage={titleImage}
                // changeMainImage={id => changeMainImage(id)}
                multimedia={variants[selected].multimedia_images}
                variant={variants[selected]}
                hideMultimedia={(id, hide) =>
                  this.props.hideMultimedia(id, hide)
                }
                onMultimediumAdd={m =>
                  this.props.addMultimediumToVariant(variants[selected]._id, m)
                }
                firm={this.props.firm}
                removeMultimedium={id =>
                  this.props.removeMultimediumFromVariant(
                    variants[selected]._id,
                    id,
                  )
                }
              />
            </MultimediaImagesWrapper>
          </React.Fragment>
        )}

        <ButtonRelativeWrapper>
          <Button onClick={() => saveCard()} primary>
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

Variants.propTypes = {
  firm: PropTypes.string,
  deleteVariant: PropTypes.func.isRequired,
  deleteComplementar: PropTypes.func.isRequired,
  deleteCover: PropTypes.func.isRequired,
  changeSelectedVariant: PropTypes.func.isRequired,
  changeLanguageForVariants: PropTypes.func.isRequired,
  variantParamsOnChange: PropTypes.func.isRequired,
  deleteVariantPhoto: PropTypes.func.isRequired,
  addVariantPhoto: PropTypes.func.isRequired,
  hideMultimedia: PropTypes.func.isRequired,
  saveCard: PropTypes.func.isRequired,
  addVariant: PropTypes.func,
  addComplementar: PropTypes.func,
  addCover: PropTypes.func,
  handleComplemetaryProductCountChange: PropTypes.func,
  variantOnChange: PropTypes.func,
  loading: PropTypes.bool,
  activeLanguage: PropTypes.string,
  variants: PropTypes.arrayOf({
    name: PropTypes.string,
    No: PropTypes.string,
    published: PropTypes.shape({
      CRM: PropTypes.bool,
      B2B: PropTypes.bool,
      xFarby: PropTypes.bool,
      xBarvy: PropTypes.bool,
      xFarben: PropTypes.bool,
      xFestek: PropTypes.bool,
    }),
  }),
  parentPublished: PropTypes.shape({
    CRM: PropTypes.bool,
    B2B: PropTypes.bool,
    xFarby: PropTypes.bool,
    xBarvy: PropTypes.bool,
    xFarben: PropTypes.bool,
    xFestek: PropTypes.bool,
  }).isRequired,

  handleUnitChange: PropTypes.func,
  colocardsIds: PropTypes.arrayOf(PropTypes.string),
  addMultimediumToVariant: PropTypes.func,
  removeMultimediumFromVariant: PropTypes.func,
};

Variants.defaultProps = {
  loading: false,
  variants: [],
  colocardsIds: [],
  activeLanguage: 'SK',
  variantOnChange: () => {},
  addVariant: () => {},
  addComplementar: () => {},
  addCover: () => {},
  handleComplemetaryProductCountChange: () => {},
  handleUnitChange: () => {},
  firm: 'SK',
  addMultimediumToVariant: () => {},
  removeMultimediumFromVariant: () => {},
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Variants);
