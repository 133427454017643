import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import Select from 'oxyrion-ui/lib/Select';
import { Button, Message, Input, Label, Loader } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import LanguageSwitch from '../../Components/LanguageSwitch';
import API from '../../API';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SearchableSelect from '../../Components/SearchableSelect';
import GreyBox from '../../Components/GreyBox';

const Wrapper = styled.div`
  padding: ${rem(15)};
  max-width: ${rem(1400)};
  /* overflow: auto; */
`;

const InputWrapper = styled.div`
  width: ${rem(600)};
`;

const Row = styled.div`
  margin-bottom: ${rem(20)};
  display: flex;
  /* max-width: $)}; */
  /* overflow: auto; */
`;

const NameWrapper = styled.div`
  max-width: ${rem(600)};
`;

const StyledInput = styled(Input)`
  width: ${rem(300)};
`;

const StyledSelect = styled(Select)`
  width: ${rem(322)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const Space = styled.div`
  height: ${rem(12)};
  width: ${rem(12)};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${rem(10)};
`;

const CheckBoxesWrapper = styled.div`
  display: flex;
`;

const Hint = styled.div`
  font-size: ${rem(10)};
  color: #808080;
`;

const TableWrapper = styled.div``;

const TableHeader = styled.div`
  width: ${rem(250)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TableRow = styled.div`
  display: flex;
  padding-bottom: ${rem(10)};
  padding-top: ${rem(10)};
  border-bottom: ${rem(1)} solid black;
`;

const Cell = styled.div`
  display: flex;
  width: ${rem(250)};
`;

const CenterCell = styled(Cell)`
  justify-content: center;
  align-items: center;
`;

const filterTypes = ['checkbox', 'select'];

const baseParams = [
  {
    name: 'Značka',
    _id: 'brand',
  },

  {
    name: 'Kategória povrchu',
    _id: 'category_of_surface',
  },
  {
    name: 'Typ',
    _id: 'type',
  },
  {
    name: 'Kampaň',
    _id: 'campaign',
  },
];

class FilterDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      productTypes: [],
      codelists: [],
      // productValueCodelist: [],
      activeLang: 'SK',
      activeLangCategories: 'SK',
      categories: [],
      // customEntities: [
      //   {
      //     name: 'Značky',
      //     func: oldData => this.fetchBrands(oldData),
      //   },
      //   {
      //     name: 'Typy produktov',
      //     func: oldData => this.fetchProductTypes(false, oldData),
      //   },
      //   {
      //     name: 'Aktuálne kampane - dynamicky',
      //     func: () => {
      //       return [{}];
      //     },
      //   },
      // ],
      data: {
        technical_name: '',
        name: '',
        url_name: '',
        url_name_translations: [],
        logic: 'OR',
        product_types: [],
        order_number: 1,
        type: 'checkbox',
        values: [],
        publication: [
          {
            channel: 'CRM',
            value: false,
          },
          {
            channel: 'B2B',
            value: false,
          },
          {
            channel: 'xFarby',
            value: false,
          },
          // {
          //   channel: 'xBarvy',
          //   value: false,
          // },
        ],
      },
    };
  }

  async componentWillMount() {
    const id = getIdFromProps(this.props);
    await this.fetchCodelists();
    await this.fetchProductTypes(true);
    await this.fetchCategories(this.state.activeLangCategories);
    if (id !== 'new') {
      await this.fetchFilterData(id);
    } else {
      this.setState({ loading: false });
    }
  }

  async fetchFilterData(id) {
    try {
      const { codelists } = this.state;

      const data = await AdminAPI.getFilterAction(id);

      await this.fetchProductParams(data.product_types);

      if (data.categories && data.categories.length > 0) {
        const categoriesResult = await AdminAPI.getCategoriesAction('xFarby');

        const allCategories = this.normalizeCategories(
          categoriesResult.categories || [],
        );

        const newCategories = [];

        data.categories.forEach(dataCategoryId => {
          const fullCategory = allCategories.find(
            all => all._id === dataCategoryId,
          );

          if (!newCategories.find(n => n.lang === fullCategory.lang)) {
            newCategories.push({
              lang: fullCategory.lang,
              categories: [],
            });
          }
          newCategories
            .find(n => n.lang === fullCategory.lang)
            .categories.push({
              value: fullCategory._id,
              lang: fullCategory.lang,
              label: `${fullCategory.name} ${
                fullCategory.parent ? ` - ${fullCategory.parent.name}` : ''
              }`,
            });
        });
        data.categories = newCategories;
      }

      // try {
      //   return AdminAPI.getProductTypesAllParamsAction({
      //     onlyProductTypes: false,
      //   }).then(res => {
      //     return res.params
      //       .filter(
      //         p =>
      //           p.codelist &&
      //           usedTypesIds.indexOf(p.product_type._id) === -1 &&
      //           p.name.toLowerCase().includes(query.toLowerCase()),
      //       )
      //       .map(item => ({
      //         value: item._id,
      //         label: `${item.product_type.name} - ${item.name}`,
      //         full_item: item,
      //       }));
      //   });

      if (!data.params && data.product_value && data.product_value._id) {
        data.params = [data.product_value._id];
      }

      if (data.params && data.params.length > 0) {
        const paramsResult = await AdminAPI.getProductTypesAllParamsAction();

        const allParams = paramsResult.params || [];

        data.params = data.params
          .filter(p => allParams.find(a => a._id === p))
          .map(p => {
            const fullParam = allParams.find(a => a._id === p);
            return {
              value: fullParam._id,
              label: `${fullParam.product_type.name} - ${fullParam.name}`,
              full_item: fullParam,
            };
          });

        const codelistsIds = data.params.map(v => v.full_item.codelist);

        const filteredCodelist = codelists.filter(
          c => codelistsIds.indexOf(c._id) > -1,
        );

        const results = await Promise.all(
          filteredCodelist.map(f => AdminAPI.getCodelist(f._id)),
        );

        data.codelists = results.map(codelist => ({
          label: codelist.name,
          value: codelist._id,
        }));

        // data.values = results
        //   .map(
        //     result => this.normalizeValues(result.codelist, data.values) || [],
        //   )
        //   .flat();
      }

      this.setState({
        data,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie fitra nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Filter systém sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchBrands() {
    const { data } = this.state;

    this.setState({
      valuesLoading: true,
    });

    try {
      const result = await API.getBrandsAction(this.props.firm);
      data.values = this.normalizeBrands(result.brands || []);

      this.setState({
        data,
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        valuesLoading: false,
      });
    }
  }

  async fetchProductTypes(isForChecboxes, oldData) {
    this.setState({
      valuesLoading: true,
    });

    const { data } = this.state;

    try {
      const result = await AdminAPI.getProductTypesAction({
        onlyProductTypes: false,
      });

      if (isForChecboxes) {
        this.setState({
          productTypes: result.product_types || [],
        });
      } else {
        data.values = this.normalizeProductTypes(
          result.product_types || [],
          oldData || [],
        );
        this.setState({
          data,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        valuesLoading: false,
      });
    }
  }

  searchCodelists(query = '') {
    const { codelists } = this.state;

    try {
      return codelists
        .filter(p => p.name.toLowerCase().includes(query.toLowerCase()))
        .map(item => ({
          value: item._id,
          label: ` ${item.name}`,
        }));
    } catch (e) {
      return [];
    }
  }

  searchProductTypesParams(query = '') {
    const { data } = this.state;

    let usedTypesIds = [];

    if (data.params && data.params.length) {
      usedTypesIds = data.params.map(p => p.full_item.product_type._id);
    }

    try {
      return AdminAPI.getProductTypesAllParamsAction({
        onlyProductTypes: false,
      }).then(res => {
        return res.params
          .filter(
            p =>
              p.codelist &&
              usedTypesIds.indexOf(p.product_type._id) === -1 &&
              p.name.toLowerCase().includes(query.toLowerCase()),
          )
          .map(item => ({
            value: item._id,
            label: `${item.product_type.name} - ${item.name}`,
            full_item: item,
          }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchCodelist(id) {
    const { data } = this.state;

    this.setState({
      valuesLoading: true,
    });

    try {
      const result = await AdminAPI.getCodelist(id);

      data.values = this.normalizeValues(result.codelist, data.values) || [];
      this.setState({
        data,
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        valuesLoading: false,
      });
    }
  }

  async fetchCategories(lang) {
    try {
      const result = await AdminAPI.getCategoriesAction('xFarby', {
        lang,
      });

      const normalizedCategories = this.normalizeCategories(
        result.categories || [],
      );

      this.setState({
        categories: normalizedCategories,
      });
    } catch (e) {
      console.log(e);
    }
  }

  normalizeCategories(categories) {
    return categories.map(c =>
      Object.assign({}, c, {
        parent: c.parent && categories.find(c1 => c1._id === c.parent),
      }),
    );
  }

  searchCategories(query) {
    const { categories } = this.state;

    return categories
      .filter(
        c =>
          c.name.toLowerCase().includes(query.toLowerCase()) ||
          query.length === 0,
      )
      .map(c => ({
        value: c._id,
        lang: c.lang,
        label: `${c.name} ${c.parent ? ` - ${c.parent.name}` : ''}`,
      }));
  }

  async fetchCodelists() {
    this.setState({
      codelistLoading: true,
    });

    try {
      const result = await AdminAPI.getCodelistsAction();

      this.setState({
        codelists: result.codelists || [],
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        codelistLoading: false,
      });
    }
  }

  async fetchProductParams(types) {
    this.setState({
      paramsLoading: true,
    });

    try {
      const result = await Promise.all(
        types.map(t => {
          return AdminAPI.getProductTypeParamsAction(t);
        }),
      );
      const normalized = result.flat().map(p => {
        if (p.group) {
          return p.params.map(p1 => {
            return {
              name: p1.name,
              _id: p1._id,
            };
          });
        }
        return [
          {
            name: p.name,
            _id: p._id,
          },
        ];
      });

      this.setState({
        productValueCodelist: baseParams.concat(normalized.flat() || []),
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        paramsLoading: false,
      });
    }
  }

  async deleteFilter() {
    const { history, firm } = this.props;
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteFilterAction(getIdFromProps(this.props));
      history.push(`/${firm}/filters`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri vymazávaní dát sa vyskytla chyba'),
      });
    }
    this.setState({
      deleteLoading: false,
    });
  }

  normalizeValues(data, oldData) {
    if (!data) {
      return [];
    }

    return data
      .filter(d => d.label !== '-')
      .map(d => {
        return {
          label: d.label,
          value: d.value || d._id,
          show_in_filter: oldData.find(o => o.value === (d.value || d._id))
            ? oldData.find(o => o.value === (d.value || d._id)).show_in_filter
            : true,
          delete_from_catalog: false,
          default_value: false,
          translations: d.translations || [],
        };
      });
  }

  normalizeBrands(data, oldData) {
    return data.map(d => {
      return {
        label: d,
        value: d,
        show_in_filter:
          oldData && oldData.find(o => o.value === d)
            ? oldData.find(o => o.value === d).show_in_filter
            : true,
        delete_from_catalog: false,
        default_value: false,
        translations: d.translations || [],
      };
    });
  }

  normalizeProductTypes(data, oldData) {
    return data.map(d => {
      return {
        label: d.name,
        value: d._id,
        show_in_filter: oldData.find(o => o.value === d._id)
          ? oldData.find(o => o.value === d._id).show_in_filter
          : true,
        delete_from_catalog: false,
        default_value: false,
        translations: d.translations || [],
      };
    });
  }

  handleValuesChange(param, value) {
    const { data } = this.state;

    data.values.find(v => v.label === value.label)[param] = !data.values.find(
      v => v.label === value.label,
    )[param];

    this.setState({
      data,
    });
  }

  handleCategoriesChange(value, lang) {
    const { data } = this.state;

    if (!data.categories) {
      data.categories = [];
    }

    if (!data.categories.find(c => c.lang === lang)) {
      data.categories.push({
        lang,
        categories: [],
      });
    }

    data.categories.find(c => c.lang === lang).categories = value;

    this.setState({ data });
  }

  async updateCodelists(value) {
    const { codelists, data } = this.state;

    if (!value || value.length === 0) {
      data.values = [];
      data.codelists = [];
      this.setState({
        data,
        valuesLoading: false,
      });

      return;
    }

    const codelistsIds = value.map(v => v.full_item.codelist);

    const filteredCodelist = codelists.filter(
      c => codelistsIds.indexOf(c._id) > -1,
    );

    try {
      this.setState({
        valuesLoading: true,
      });

      const results = await Promise.all(
        filteredCodelist.map(f => AdminAPI.getCodelist(f._id)),
      );

      data.codelists = results.map(codelist => ({
        label: codelist.name,
        value: codelist._id,
      }));

      data.values = results
        .map(result => this.normalizeValues(result.codelist, data.values) || [])
        .flat();

      this.setState({
        data,
        valuesLoading: false,
      });
    } catch (e) {
      this.setState({
        valuesLoading: false,
      });
    }
  }

  handleDataChange(variable, value) {
    const {
      data,
      activeLang,
      // customEntities,
      // productTypes,
      // productValueCodelist,
    } = this.state;

    if (variable === 'technical_name') {
      data.technical_name = value;
    } else if (variable === 'params') {
      data.params = value;
      this.updateCodelists(value);
    } else if (variable === 'codelists') {
      data.codelists = value;
    } else if (variable === 'type') {
      data.type = value;
    } else if (variable === 'auto_values') {
      data.auto_values = value;
    } else if (variable === 'order_number') {
      data.order_number = Number(value);
    }
    // else if (variable === 'product_value') {
    //   if (value && value !== '-') {
    //     data.product_value = productValueCodelist.find(p => p.name === value);
    //   } else {
    //     data.product_value = undefined;
    //   }
    // }
    else if (variable === 'publication') {
      data.publication.find(
        p => p.channel === value.channel,
      ).value = !value.value;
    }
    // else if (variable === 'product_types') {
    //   if (!data.product_types) {
    //     data.product_types = [];
    //   }

    //   const index = data.product_types.indexOf(value);
    //   if (value === 'all') {
    //     data.product_types = [];
    //     productTypes.forEach(p => {
    //       data.product_types.push(p._id);
    //     });
    //   } else if (index > -1) {
    //     data.product_types.splice(index, 1);
    //   } else {
    //     data.product_types.push(value);
    //   }

    //   const indexOfPaintSystem = data.product_types.indexOf('NATEROVY_SYSTEM');

    //   if (indexOfPaintSystem > -1) {
    //     data.product_types.splice(indexOfPaintSystem, 1);
    //   }

    //   this.fetchProductParams(data.product_types);
    // }
    else if (variable === 'product_types_paint_system') {
      const index = data.product_types.indexOf(value);
      data.product_types = [];
      if (index > -1) {
        data.product_types.splice(index, 1);
      } else {
        data.product_types.push(value);
      }

      this.fetchProductParams(data.product_types);
    } else if (variable === 'name') {
      if (activeLang === 'SK') {
        data.name = value;
      }
      if (!data.translations) {
        data.translations = [];
      }
      if (!data.translations.find(t => t.lang === activeLang)) {
        data.translations.push({
          lang: activeLang,
          value,
        });
      } else {
        data.translations.find(t => t.lang === activeLang).value = value;
      }
    } else if (variable === 'url_name') {
      if (activeLang === 'SK') {
        data.url_name = value;
      }
      if (!data.url_name_translations) {
        data.url_name_translations = [];
      }
      if (!data.url_name_translations.find(t => t.lang === activeLang)) {
        data.url_name_translations.push({
          lang: activeLang,
          value,
        });
      } else {
        data.url_name_translations.find(
          t => t.lang === activeLang,
        ).value = value;
      }
    }
    // else if (variable === 'codelist') {
    //   if (value && value !== '-') {
    //     data.codelist = value;
    //     this.fetchCodelist(value);
    //   } else {
    //     data.codelist = undefined;
    //     data.values = [];
    //   }
    // }
    // else if (variable === 'custom_entity') {
    //   if (value && value !== '-') {
    //     data.custom_entity = value;
    //     customEntities.find(c => c.name === value).func();
    //   } else {
    //     data.custom_entity = undefined;
    //     data.values = [];
    //   }
    // }

    this.setState({
      data,
      notValidData: false,
    });
  }

  checkData(data) {
    // return data.technical_name.length && data.name.length && data.product_value;
    return data.name.length > 0;
  }

  async updateValues() {
    const { data } = this.state;

    if (data.params && data.params.length > 0) {
      this.updateCodelists(data.params);
    } else if (data._id === '5d81efeb2453cbbde7a052c0') {
      // uz iba tento filter takto funguje
      const result = await API.getBrandsAction(this.props.firm);
      data.values = result.brands.map(b => ({
        default_value: false,
        delete_from_catalog: false,
        label: b,
        show_in_filter: true,
        translations: [],
        value: b,
      }));

      this.setState({ data });
    }

    // else if (data.custom_entity) {
    //   this.state.customEntities
    //     .find(c => c.name === data.custom_entity)
    //     .func(data.values);
    // }
  }

  async saveData() {
    const { data } = this.state;

    this.setState({
      saveLoading: true,
    });

    if (this.checkData(data)) {
      try {
        const serverData = Object.assign({}, data);

        if (serverData.categories) {
          serverData.categories = serverData.categories
            .map(c => c.categories.map(c1 => c1.value))
            .flat();
        }

        if (serverData.params) {
          serverData.params = serverData.params.map(p => p.value);
        }
        if (serverData.codelists) {
          serverData.codelists = serverData.codelists.map(p => p.value);
        }

        const params = {};
        params.body = serverData;

        if (getIdFromProps(this.props) === 'new') {
          const result = await AdminAPI.postFilterAction(params);

          this.props.history.push(`/${this.props.firm}/filters/${result._id}`);
          this.setState({
            data: Object.assign({}, data, { id: result._id }),
          });
        } else {
          await AdminAPI.putFilterAction(getIdFromProps(this.props), params);
        }
        this.setState({
          success: __('Dáta úspešne uložené'),
          saveLoading: false,
        });
      } catch (e) {
        console.log(e);

        this.setState({
          saveLoading: false,
        });
      }
    } else {
      this.setState({
        notValidData: true,
        saveLoading: false,
      });
    }
  }

  logicChange(to) {
    const { data } = this.state;

    data.logic = to;

    this.setState({
      data,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Filter')}>
        {getIdFromProps(this.props) !== 'new' && (
          <ControlBarButton
            small
            danger
            onClick={() =>
              this.setState({
                showConfirmDialog: true,
              })
            }
            icon="delete"
          >
            {__('Odstrániť')}
          </ControlBarButton>
        )}
      </ControllBar>
    );
  }

  render() {
    const {
      loading,
      data,
      activeLang,
      // codelists,
      // customEntities,
      // codelistLoading,
      productTypes,
      // productValueCodelist,
      paramsLoading,
      valuesLoading,
      notValidData,
      saveLoading,
      deleteError,
      deleteLoading,
      showConfirmDialog,
      error,
      success,
      activeLangCategories,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__('Naozaj si prajete odstániť filter ?')}
            onDismiss={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.deleteFilter()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát nastala chyba')
              }
            />
          )}
          {deleteError && <Message error message={deleteError} />}
          {success && <Message success message={success} />}

          <GreyBox title={__('Názov filtra')}>
            <Row>
              <NameWrapper>
                <LanguageSwitch
                  onChange={lang => {
                    this.setState({ activeLang: lang });
                    return true;
                  }}
                  activeId={activeLang}
                />

                <Space />
                <StyledLabel>{__('Vo filtry')} *</StyledLabel>
                <StyledInput
                  value={
                    data.translations &&
                    data.translations.find(t => t.lang === activeLang) &&
                    data.translations.find(t => t.lang === activeLang).value
                      ? data.translations.find(t => t.lang === activeLang).value
                      : data.name
                  }
                  placeholder={`${__('Názov filtra pre')} ${activeLang}`}
                  onChange={e => this.handleDataChange('name', e.target.value)}
                />
                <InputWrapper>
                  <StyledLabel>
                    {__('Technický - pre jednoduchšie dohľadávanie v admine')}
                  </StyledLabel>
                  <StyledInput
                    value={data.technical_name}
                    placeholder={__('Názov filtra v systéme')}
                    onChange={e =>
                      this.handleDataChange('technical_name', e.target.value)
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <StyledLabel>{__('V url adrese')} *</StyledLabel>
                  <StyledInput
                    value={
                      data.url_name_translations &&
                      data.url_name_translations.find(
                        t => t.lang === activeLang,
                      ) &&
                      data.url_name_translations.find(
                        t => t.lang === activeLang,
                      ).value
                        ? data.url_name_translations.find(
                            t => t.lang === activeLang,
                          ).value
                        : data.url_name
                    }
                    placeholder={`${__(
                      'Názov zobrazený v url pre',
                    )} ${activeLang}`}
                    onChange={e =>
                      this.handleDataChange('url_name', e.target.value)
                    }
                  />
                </InputWrapper>
              </NameWrapper>
            </Row>
          </GreyBox>

          <Space />

          <GreyBox title={__('Publikácia')}>
            <Row>
              <InputWrapper>
                <CheckBoxesWrapper>
                  {data.publication.map(c => {
                    return (
                      <CheckBoxWrapper>
                        <Checkbox
                          checked={c.value}
                          onChange={() =>
                            this.handleDataChange('publication', c)
                          }
                        />
                        <Label>{c.channel}</Label>
                      </CheckBoxWrapper>
                    );
                  })}
                </CheckBoxesWrapper>
              </InputWrapper>
            </Row>
            <Space />
            <Row>
              <InputWrapper>
                <LanguageSwitch
                  onChange={lang => {
                    this.fetchCategories(lang);
                    this.setState({ activeLangCategories: lang });
                    return true;
                  }}
                  activeId={activeLangCategories}
                />

                <Space />

                <SearchableSelect
                  value={
                    data.categories &&
                    data.categories.find(
                      c => c.lang === activeLangCategories,
                    ) &&
                    data.categories.find(c => c.lang === activeLangCategories)
                      .categories
                  }
                  loadOptions={query => this.searchCategories(query)}
                  key={JSON.stringify({
                    categories: this.state.categories,
                    activeLangCategories,
                  })}
                  placeholder={`${__(
                    'Kategórie zobrazenia',
                  )} - ${activeLangCategories}`}
                  handleOnChange={e =>
                    this.handleCategoriesChange(e, activeLangCategories)
                  }
                  isMulti
                />
              </InputWrapper>
              <Space />
              <Space />
              <Space />
              <InputWrapper>
                <Space />
                <Space />
                <StyledLabel>{__('Filter pre')}</StyledLabel>
                <CheckBoxesWrapper>
                  <CheckBoxWrapper>
                    {productTypes
                      .filter(p => !p.type_of_product)
                      .map(p => {
                        return (
                          <CheckBoxWrapper>
                            <Checkbox
                              checked={data.product_types.indexOf(p._id) > -1}
                              onChange={() =>
                                this.handleDataChange(
                                  'product_types_paint_system',
                                  p._id,
                                )
                              }
                            />
                            <Label>{p.name}</Label>
                          </CheckBoxWrapper>
                        );
                      })}
                  </CheckBoxWrapper>
                </CheckBoxesWrapper>
              </InputWrapper>
            </Row>
          </GreyBox>
          <Space />
          <GreyBox title={__('Nastavenia filtra')}>
            <Row>
              <InputWrapper>
                <StyledLabel>{__('Logika')}</StyledLabel>
                <CheckBoxesWrapper>
                  <CheckBoxWrapper>
                    <Checkbox
                      checked={data.logic === 'AND'}
                      onChange={() => this.logicChange('AND')}
                    />
                    <Label>{__('AND')}</Label>
                  </CheckBoxWrapper>
                  <CheckBoxWrapper>
                    <Checkbox
                      checked={data.logic === 'OR'}
                      onChange={() => this.logicChange('OR')}
                    />
                    <Label>{__('OR')}</Label>
                  </CheckBoxWrapper>
                </CheckBoxesWrapper>
              </InputWrapper>
              <Space />
              <InputWrapper>
                <StyledLabel>{__('Typ')}</StyledLabel>

                <StyledSelect
                  size="s"
                  disabled
                  onChange={e => this.handleDataChange('type', e.target.value)}
                >
                  {filterTypes.map(item => {
                    return (
                      <option selected={data.type === item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </StyledSelect>
              </InputWrapper>

              <InputWrapper>
                <StyledLabel>{__('Poradie')}</StyledLabel>
                <StyledInput
                  value={data.order_number}
                  placeholder={__('1')}
                  onChange={e =>
                    this.handleDataChange('order_number', e.target.value)
                  }
                />
                <Hint>{__('Poradie filtra v katalógu')}</Hint>
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <StyledLabel>
                  {__('Viaže sa na paremetre produktov')}
                  {paramsLoading && <Loader style={{ marginLeft: rem(4) }} />}
                </StyledLabel>

                <SearchableSelect
                  value={data.params}
                  keyProps={JSON.stringify(data.params)}
                  loadOptions={query => this.searchProductTypesParams(query)}
                  placeholder={__('Vyhľadať a priradiť parametre')}
                  isMulti
                  handleOnChange={e => {
                    this.handleDataChange('params', e);
                  }}
                />

                {/* <Hint>{__('Hodnota na ktorú sa filter aplikuje')}</Hint> */}
              </InputWrapper>
              <Space />
              <InputWrapper>
                <StyledLabel>{__('Použité číselníky')}</StyledLabel>

                <SearchableSelect
                  value={data.codelists}
                  keyProps={JSON.stringify(data.params)}
                  loadOptions={query => this.searchCodelists(query)}
                  placeholder={__('Vyhľadať a priradiť číselníky')}
                  isMulti
                  disabled
                  handleOnChange={e => {
                    this.handleDataChange('codelists', e);
                  }}
                />

                {/* <StyledSelect
                  size="s"
                  disabled={data.custom_entity}
                  loading={codelistLoading}
                  onChange={e => {
                    this.handleDataChange('codelist', e.target.value);
                  }}
                >
                  <option selected={!data.codelist} value={undefined}>
                    {'-'}
                  </option>

                  {codelists.map(item => {
                    return (
                      <option
                        selected={data.codelist === item._id}
                        value={item._id}
                      >
                        {translateCodelist(
                          this.props.firm,
                          item.translations,
                          item.name,
                        )}
                      </option>
                    );
                  })}
                </StyledSelect> */}
              </InputWrapper>

              {/* <InputWrapper>
                <StyledLabel>{__('Použiť iné definované hodnoty')}</StyledLabel>

                <StyledSelect
                  size="s"
                  disabled={data.codelist}
                  loading={codelistLoading}
                  onChange={e => {
                    this.handleDataChange('custom_entity', e.target.value);
                  }}
                >
                  <option selected={!data.custom_entity} value={undefined}>
                    {'-'}
                  </option>

                  {customEntities.map(item => {
                    return (
                      <option
                        selected={data.custom_entity === item.name}
                        value={item.name}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </StyledSelect>
                <Hint>
                  {__('Hodnoty, ktoré sú naviazané na iné entity v systéme')}
                </Hint>
              </InputWrapper> */}
            </Row>
          </GreyBox>

          <Space />

          {/* --------------------------------------------------------------------------------------------------------------------------------------------------- */}

          {/* --------------------------------------------------------------------------------------------------------------------------------------------------- */}

          {/* --------------------------------------------------------------------------------------------------------------------------------------------------- */}

          <Row>
            <StyledLabel>{__('Hodnoty filtra')}</StyledLabel>
            <Button small primary onClick={() => this.updateValues()}>
              {__('Aktualizovať')}
            </Button>

            <CheckBoxWrapper style={{ marginLeft: rem(50) }}>
              <Checkbox
                checked={data.auto_values}
                onChange={() =>
                  this.handleDataChange('auto_values', !data.auto_values)
                }
              />
              <div>
                <Label>{__('Automaticke načítanie hodnôt')}</Label>
                <Hint>
                  {__('Hodnoty sa vo filtry zobrazia podľa výskytu v entite')}
                </Hint>
              </div>
            </CheckBoxWrapper>
          </Row>
          <Row>
            <TableWrapper>
              <TableRow>
                <TableHeader style={{ justifyContent: 'start' }}>
                  {__('Názov ')}
                </TableHeader>
                <TableHeader>
                  {__('Zobraziť vo filtry')}
                  <Hint>
                    {__('Hodnota filtra sa nezobrazí no produktu ostanú')}
                  </Hint>
                </TableHeader>
                <TableHeader>
                  {__('Odstrániť hodnoty z katalógu')}
                  <Hint>{__('Hodnota filtra a produkty sa nezobrazia')}</Hint>
                </TableHeader>{' '}
                <TableHeader>{__('Predvolená hodnota')}</TableHeader>
              </TableRow>

              {valuesLoading ? (
                <LoaderWrapper>
                  <Loader size="l" />
                </LoaderWrapper>
              ) : (
                data.values.map(d => {
                  return (
                    <TableRow>
                      <Cell>{d.label}</Cell>
                      <CenterCell>
                        <Checkbox
                          checked={d.show_in_filter}
                          disabled={data.auto_values}
                          onChange={() =>
                            this.handleValuesChange('show_in_filter', d)
                          }
                        />
                      </CenterCell>

                      <CenterCell>
                        <Checkbox
                          checked={d.delete_from_catalog}
                          disabled={data.auto_values}
                          onChange={() =>
                            this.handleValuesChange('delete_from_catalog', d)
                          }
                        />
                      </CenterCell>

                      <CenterCell>
                        <Checkbox
                          checked={d.default_value}
                          onChange={() =>
                            this.handleValuesChange('default_value', d)
                          }
                        />
                      </CenterCell>
                    </TableRow>
                  );
                })
              )}
            </TableWrapper>
          </Row>

          <ButtonRelativeWrapper>
            <Button
              loading={saveLoading}
              onClick={() => this.saveData()}
              primary
            >
              {__('Uložiť')}
            </Button>
          </ButtonRelativeWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

FilterDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FilterDetail);
