import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import {
  Loader,
  Message,
  Input,
  Label,
  Button,
  Icon,
  Textarea,
  Checkbox,
} from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import EmailsSelector from '../../Components/EmailsSelector';
import GreyBox from '../../Components/GreyBox';
import SearchableSelect from '../../Components/SearchableSelect';
import API from '../../API';

const StyledInput = styled(Input)`
  width: ${rem(250)};
`;

const Wrapper = styled.div`
  padding: ${rem(8)};
`;

const InputWrapper = styled.div`
  margin-right: 30px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${rem(8)};
`;

const TaskWrapper = styled.div`
  margin: ${rem(12)};
  max-width: ${rem(800)};
`;
const TaskTitleWrapper = styled.div`
  display: flex;
  width: ${rem(300)};
  justify-content: space-between;
  margin-bottom: ${rem(16)};
  font-size: ${rem(18)};
  font-weight: 800;
  cursor: pointer;
`;
const TaskTitle = styled.div``;
const TaskIconWrapper = styled.div``;
const TaskContentWrapper = styled.div`
  padding: ${rem(12)};
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  right: ${rem(24)};
  bottom: 0px;
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(250)};
`;

const StyledTextarea = styled(Textarea)`
  width: 90%;
`;

const AddNew = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${rem(18)};
  font-weight: 800;
  cursor: pointer;
`;

class ReportTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tasks: [],
      openedTasks: [],
      newTask: undefined,
      deleteLoading: false,
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  fetchUsers(query) {
    try {
      return API.getAllUsersAction({
        q: query,
        limit: 10,
      }).then(res => {
        return res.users.map(item => ({
          value: item._id,
          label: item.display_name,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchData() {
    try {
      const result = await AdminAPI.getReportTasksAction({});
      this.setState({
        tasks: result.items,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Bonusy sa nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async deleteTask() {
    const { tasktToDelete, tasks } = this.state;

    if (!tasktToDelete) {
      return;
    }

    if (tasktToDelete === 'new') {
      const newTasks = tasks.filter(t => t._id !== tasktToDelete);

      this.setState({
        deleteLoading: false,
        tasktToDelete: undefined,
        newTask: undefined,
        error: false,
        tasks: newTasks,
      });

      return;
    }

    this.setState({
      deleteLoading: true,
    });

    try {
      await AdminAPI.deleteReportTaskAction(tasktToDelete);
      const newTasks = tasks.filter(t => t._id !== tasktToDelete);
      this.setState({
        success: __('Úloha odstránená'),
        deleteLoading: false,
        tasktToDelete: undefined,
        error: false,
        tasks: newTasks,
      });
    } catch (e) {
      this.setState({
        error: __('Úlohu sa nepodarilo odstrániť'),
        success: false,
        deleteLoading: false,
      });
    }
  }

  async saveTask(id) {
    const { tasks, newTask } = this.state;

    if (!id) {
      return;
    }

    this.setState({
      saveLoading: id,
    });

    try {
      const data = id === 'new' ? newTask : tasks.find(t => t._id === id);

      const params = {};

      params.body = {
        name: data.name,
        message_text: data.name,
        finishable: data && data.finishable,
        finish_text: data && data.finish_text,
        emails: data.emails,
        firm: data.firm,
        solver_id: data.solver && data.solver.value,
      };

      let result;

      if (id === 'new') {
        result = await AdminAPI.postReportTaskAction(params);
        tasks.push(result);
      } else {
        result = await AdminAPI.putReportTaskAction(id, params);
      }

      this.setState({
        success: __('Úloha vytvorená'),
        tasks,
        newTask: undefined,
        saveLoading: undefined,
        error: false,
      });
    } catch (e) {
      this.setState({
        error: __('Úlohu sa nepodarilo odstrániť'),
        saveLoading: undefined,
        success: false,
      });
    }
  }

  handleEmailChange(email, id, operation) {
    const { tasks, newTask } = this.state;

    const task = id === 'new' ? newTask : tasks.find(t => t._id === id);

    if (!task.email) {
      task.email = [];
    }

    if (operation === 'add') {
      task.emails.push(email);
    } else {
      task.emails.splice(task.emails.indexOf(email), 1);
    }

    this.handleDataChange(id, 'email', task.emails);
  }

  handleDataChange(id, field, value) {
    const { tasks, newTask } = this.state;

    if (id === 'new' && newTask) {
      newTask[field] = value;
    } else {
      tasks.find(t => t._id === id)[field] = value;
    }

    this.setState({
      tasks,
      newTask,
    });
  }

  handleSettingsChange(field, value) {
    this.setState({
      [field]: value,
    });
  }

  toggleTask(id) {
    const { openedTasks } = this.state;

    if (openedTasks.indexOf(id) > -1) {
      openedTasks.splice(openedTasks.indexOf(id), 1);
    } else {
      openedTasks.push(id);
    }

    this.setState({ openedTasks });
  }

  addNewTask() {
    this.setState({
      newTask: {
        _id: 'new',
        name: '',
        message_text: '',
        finishable: false,
        finish_text: '',
        emails: [],
        solver: null,
      },
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { tasktToDelete, deleteLoading } = this.state;

    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Reporty')} />

        <PopUpWrapper
          small
          display={tasktToDelete}
          onClose={() =>
            this.setState({
              tasktToDelete: undefined,
            })
          }
          onEscClose={() =>
            this.setState({
              tasktToDelete: undefined,
            })
          }
        >
          <ConfirmDialog
            error={false}
            message={__('Prajete si vymazať úlohu?')}
            onConfirm={() => this.deleteTask()}
            onDismiss={() =>
              this.setState({
                tasktToDelete: undefined,
              })
            }
            loading={deleteLoading}
          />
        </PopUpWrapper>
      </React.Fragment>
    );
  }
  renderTaskContent(task) {
    const { saveLoading } = this.state;

    if (!task) {
      return <div />;
    }

    return (
      <GreyBox sidePadding={0}>
        <TaskContentWrapper>
          <Row>
            <InputWrapper>
              <Label>{__('Názov úlohy')}</Label>
              <StyledInput
                placeholder={__('Názov')}
                onChange={e =>
                  this.handleDataChange(task._id, 'name', e.target.value)
                }
                value={task.name}
              />
            </InputWrapper>

            <InputWrapper>
              <Label>{__('Zodpovedný')}</Label>
              <SearchableSelectWrapper>
                <SearchableSelect
                  value={task.solver}
                  loadOptions={query => this.fetchUsers(query)}
                  placeholder={__('Vyhľadať zamestnanca')}
                  handleOnChange={e =>
                    this.handleDataChange(task._id, 'solver', e)
                  }
                />
              </SearchableSelectWrapper>
            </InputWrapper>

            <InputWrapper>
              <Label>{__('Krajina')}</Label>
              <Label>
                <Checkbox
                  onChange={e => this.handleDataChange(task._id, 'firm', 'SK')}
                  checked={task.firm === 'SK'}
                />
                {__('SK')}
              </Label>
              <Label>
                <Checkbox
                  onChange={e => this.handleDataChange(task._id, 'firm', 'CZ')}
                  checked={task.firm === 'CZ'}
                />
                {__('CZ')}
              </Label>
            </InputWrapper>
          </Row>

          <Row>
            <InputWrapper>
              <Label
                style={{
                  marginBottom: rem(14),
                }}
              >
                {__('Text zaslanej správy')}
              </Label>
              <SearchableSelectWrapper>
                <StyledTextarea
                  placeholder={__('Text správy')}
                  onChange={e =>
                    this.handleDataChange(
                      task._id,
                      'message_text',
                      e.target.value,
                    )
                  }
                  value={task.message_text}
                />
              </SearchableSelectWrapper>
            </InputWrapper>

            <InputWrapper>
              <Label>
                <Checkbox
                  onChange={e =>
                    this.handleDataChange(
                      task._id,
                      'finishable',
                      e.target.checked,
                    )
                  }
                  checked={task.finishable}
                />
                {__('Ukončenie úlohy')}
              </Label>
              {task.finishable && (
                <SearchableSelectWrapper>
                  <StyledTextarea
                    placeholder={__('Text správy pre ukočenie úlohy')}
                    value={task.finish_text}
                    onChange={e =>
                      this.handleDataChange(
                        task._id,
                        'finish_text',
                        e.target.value,
                      )
                    }
                  />
                </SearchableSelectWrapper>
              )}
            </InputWrapper>
          </Row>
          <Row>
            <SearchableSelectWrapper style={{ width: rem(500) }}>
              <EmailsSelector
                defaultEmails={[]}
                emails={task.emails}
                onDelete={email =>
                  this.handleEmailChange(email, task._id, 'sub')
                }
                onAdd={email => this.handleEmailChange(email, task._id, 'add')}
              />
            </SearchableSelectWrapper>
          </Row>
          <ButtonWrapper>
            <Button
              danger
              small
              onClick={() => this.setState({ tasktToDelete: task._id })}
            >
              {task._id === 'new' ? __('Zrušiť') : __('Odstrániť')}
            </Button>
            <Space />
            <Button
              primary
              small
              onClick={() => this.saveTask(task._id)}
              loading={saveLoading === task._id}
            >
              {__('Uložiť')}
            </Button>
          </ButtonWrapper>
        </TaskContentWrapper>
      </GreyBox>
    );
  }

  render() {
    const { loading, error, success, tasks, openedTasks, newTask } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <Wrapper>
          {tasks.map(t => (
            <TaskWrapper>
              <TaskTitleWrapper onClick={() => this.toggleTask(t._id)}>
                <TaskTitle>{t.name}</TaskTitle>
                <TaskIconWrapper>
                  <Icon
                    name={
                      openedTasks.indexOf(t._id) > -1
                        ? 'arrow-top'
                        : 'arrow-down'
                    }
                  />
                </TaskIconWrapper>
              </TaskTitleWrapper>
              <AnimatedFormMessageWrapper
                display={openedTasks.indexOf(t._id) > -1}
              >
                {this.renderTaskContent(t)}
              </AnimatedFormMessageWrapper>
            </TaskWrapper>
          ))}
          <TaskWrapper>
            {!newTask && (
              <AddNew onClick={() => this.addNewTask()}>
                {__('Pridať úlohu')}
              </AddNew>
            )}
            <AnimatedFormMessageWrapper display={newTask}>
              {this.renderTaskContent(newTask)}
            </AnimatedFormMessageWrapper>
          </TaskWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

ReportTasks.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ReportTasks);
